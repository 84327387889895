.section {
  position: relative;
  overflow: hidden;
}

.carousel {
  gap: 16px;
  padding: 16px 0;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29.05px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-3);
}

.subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text);
  margin-top: 8px;
}

.servicesName {
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0px;
  color: var(--secondary-contrast-text-3);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.servicesDescription {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  color: var(--secondary-contrast-text-2);
  
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: normal; 
  word-break: break-word; 
}

.servicesImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.servicesImageContainer {
  height: 254px;
  border-radius: 6px;
}

.item {
  width: 216px;
}

.item:nth-child(2) {
  margin-left: 4px;
}

.scroller {
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
}

.activeInvillaServicesContainer {
  display: grid;
}

.contentContainer {
  display: grid;
  grid-template: auto / 1fr auto;
  gap: 2px;
  margin: 0 2px;
  border: 1px solid #0000001A;
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 6px;
  padding: 8px 6px;
  margin-top: -43px;
  background: #FFFFFF;
  cursor:pointer;
}

.inVillaArrowIcon{
  width: 16px;
  height: 16px;
  grid-row: 1 / span 2;
  grid-column: 2;
  align-self: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  .carousel {
    margin: -4px -16px -16px -16px;
  }

  .scroller {
    display: none;
  }

  .title {
    font-size: 16px;
    line-height: 19.36px;
  }

  .subtitle {
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
  }

  .servicesName {
    font-size: 12px;
    line-height: 14px;
    color: var(--secondary-contrast-text);
  }

  .servicesDescription {
    font-size: 10px;
    line-height: 14px;
  }

  .servicesImageContainer {
    height: 216px;
  }

  .item {
    width: 184px;
  }

  .item:nth-child(2) {
    margin-left: 2px;
  }

}

@media (min-width: 768px) {
  .carousel {
    margin: 0px -18px -12px -16px;
    gap: 12px;
    padding: 24px 0 16px 0;
  }

}
