.container {
  position: relative;
  display: grid;
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.background :global .image {
  object-fit: cover;
}

.sendRequestTitle {
  color: #474e66;
  font-weight: 700;
  grid-column: span 2;
  display: flex;
  align-items: center;
}

.title {
  font-weight: bold;
  color: white;
  align-self: center;
}

.emphasis {
  color: #fdb1b5;
}

.needHelp {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 6px 1px #0000001F;
  border-radius: 4px;
  padding: 32px 24px;
  width: 380px;
  gap: 16px 8px;
}

.needHelp :global .needHelp {
  background: white !important;
  display: contents !important;
  min-height: unset;
  color: var(--secondary-contrast-text-3);
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

.submit {
  grid-column: span 2;
  height: 54px;
  font-size: 16px;
  margin-top: 16px;
  border-radius: 6px;
}

.contactUs {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid white;
  color: white;
  width: 100%;
  height: 40px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr;
    border-radius: 4px;
    padding: 20px;
    gap: 24px;
    margin-bottom: 48px;
  }

  .background {
    border-radius: 4px;
  }

  .title {
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 20px;
    text-align: center;
  }

  .sendRequestTitle {
    font-size: 16px;
    height: 32px;
  }

  .needHelp {
    display: none;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: auto/1fr auto;
    gap: 52px;
    border-radius: 6px;
    padding: 40px 80px;
    margin-bottom: 48px;
  }

  .background {
    border-radius: 6px;
  }

  .title {
    font-size: 32px;
    letter-spacing: 0.5px;
    line-height: 40px;
  }

  .sendRequestTitle {
    font-size: 24px;
    height: 48px;
  }

  .contactUs {
    display: none;
  }
}
