.container {
  display: grid;
}

.spacer {
  grid-column: span 2;
  margin-bottom: 16px;
}

.titleContainer {
  display: grid;
  grid-template: auto/1fr auto;
  align-self: flex-start;
}

.title {
  margin: 0;
  align-self: center;
  font-weight: 700;
}

.sendRequestContainer {
  position: sticky;
  display: grid;
  top: 100px;
  align-self: flex-start;
  grid-row: span 3;
  gap:12px;
}

.sendRequest {
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}

.offersAndDiscounts{
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  position: relative;
}

.sendRequest :global .need-help {
  font-weight: 700;
  background: transparent;
  color: var(--secondary-contrast-text);
  font-size: 14px;
  min-height: unset;
  justify-content: flex-start;
}

.lohonoExperience {
  grid-column: 1/span 2;
}

.properties {
  display: grid;
  grid-template: auto/1fr;
  gap: 24px;
  align-items: flex-start;
}

.discount {
  grid-column: span 2;
}

.securePayment {
  margin-top: 24px;
}

.propertyCount{
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  color: var(--secondary-contrast-text-2);
}

.subtitle{
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  color: var(--secondary-contrast-text);
}

.banners{
  display: grid;
    gap: 16px;
    color: white;
    transition: all ease-in-out 0.3s;
    grid-template: 1fr auto/1fr;
}

.floatingVisible {
  opacity: 1; 
  transform: translate(0px, 0px); 
  width: 100%; 
  margin:0px auto; 
  translate: none; 
  rotate: none; 
  scale: none;
  /* transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out; */
}

.floatingInvisible {
  opacity: 0; 
  transform: translate(0px, -20px); 
  width: 100%; 
  margin: 0px auto; 
  translate: none; 
  rotate: none; 
  scale: none;
  pointer-events: none;
  cursor: default;
  /* transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out; */
}

.searchVisible {
  opacity: 1; 
  transform: translate(0px, 0px); 
  width: 100%; 
  margin:0px auto; 
  translate: none; 
  rotate: none; 
  scale: none;
  /* transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out; */
}

.searchInVisible {
  opacity: 0; 
  transform: translate(0px, -20px); 
  width: 100%; 
  margin: 0px auto; 
  translate: none; 
  rotate: none; 
  scale: none;
  pointer-events: none;
  cursor: default;
  /* transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out; */
}

.searchDisplayNon{
  display: none;
  /* transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out; */
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr auto;
    gap: 12px 16px;
    padding-bottom: 64px;
  }

  .search{
    grid-column: 1 /span 2;
    grid-row: 2;
    margin-top: -36px;
    z-index: 1;
  }

  .sortButton{
    grid-column: 2;
    z-index: 1;
    grid-row: 2; border: 1px solid #0000002E !important;
    border-radius: 6px !important; 
    box-shadow: none !important;
    padding: 0 16px !important;
    margin-top: -40px !important;
  }

  .discount {
    margin: 4px 0 0 0;
  }

  .titleContainer {
    grid-column: span 2;
    padding: 4px 0;
  }

  .sendRequestContainer {
    grid-column: span 2;
    position: unset;
    grid-row: span 1;
    margin-top: 4px;
  }

  .sendRequest{
    display: none;
  }

  .offersAndDiscounts{
    display: grid;
    box-shadow: none;
    border-radius: none;
    position: unset;
  }

  .securePayment{
    display: none;
  }

  .properties {
    gap: 16px;
    grid-column: span 2;
  }

  button.sortDesktop {
    display: none;
  }

  .llpTicker {
    grid-column: span 2;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: auto/1fr 25%;
    gap: 16px 32px;
    padding-bottom: 100px;
  }

  .titleContainer {
    row-gap: 12px;
  }

  .search {
    grid-column: span 2;
    margin-top: -55px;
    margin-bottom: 4px;
  }

  .searchMobile{
    border: none !important;
    background: rgba(0, 0, 0, 0.04) !important;
  }

  .sortButton{
    display: none;
  }

  .title {
    font-size: 16px;
  }

  .sendRequest {
    padding: 16px;
  }

  .nearbyLocations {
    margin: 16px 0 24px;
  }
}