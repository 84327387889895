.container {
  display: inline-grid;
  grid-template: auto/1fr;
  gap: 4px;
  align-content: flex-start;
  font-weight: 400;
  position: relative;
}

.container[data-disabled='true'] .input {
  background: rgba(31, 44, 56, 0.04);
  cursor: not-allowed;
  border: none;
  color: #bcbcbc;
}

.container[data-focus='true'][data-highlight='true'] {
  background: #F5F5F7 !important;
}

.label {
  text-transform: capitalize;
  grid-row: 1;
  color: #626366;
  opacity: 0.8;
  font-weight: 500;
  letter-spacing: 0.36px;
  font-size: 14px;
}

.label ~ .expandIcon {
  top: 46px;
}

.input {
  background: white;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 12px;
  color: var(--secondary-contrast-text);
  font-size: 16px;
  font-weight: 600;
  outline: none;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--font-family);
}

.homeInput{
  max-height: unset !important;
}
.input::placeholder {
  color: var(--secondary-contrast-text-2);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input[data-error='true']:hover, .input[data-error='true']:focus {
  border-color: #CC385A;
}

.input[data-error='false']:hover, .input[data-error='false']:focus {
  border-color: var(--border-color);
}

.error {
  color: #CC385A;
  font-size: 12px;
}

.expandIcon {
  position: absolute;
  top: 22px;
  transform: translateY(-50%);
  right: 12px;
  fill: #cccccc;
  pointer-events: none;
}

.expandIcon:hover {
  fill: #474e66;
}

/* .showOptions {
  display: block;
}

.hideOptions {
  display: none;
} */

.options {
  position: absolute;
  left: 0;
  width: 100%;
  top: calc(100% + 1px);
  max-height: 300px;
  background: white;
  color: #474e66;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.57px;
  user-select: none;
  overflow-y: auto;
  padding: 4px 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 11px;
  border-radius: 4px;
  z-index: 1;
}

.options::-webkit-scrollbar {
  width: 4px; /* Scrollbar width */
}

.options::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* Track background color */
  border-radius: 10px;
}

.options::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* Scrollbar thumb color */
  border-radius: 10px;
}

.options::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3); /* Thumb color on hover */
}


.option::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.option::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

/* .option[data-hover='false'] .option[data-focused='true'] {
  background: rgba(71, 78, 102, 0.1);
} */

.option[data-hover='true'] .option[data-focused='false'] {
  display: none;
}

.option[data-focused="true"] {
  background: linear-gradient(90deg, rgba(170, 78, 49, 0.1) 2.97%, rgba(170, 78, 49, 0.02) 97.96%);
}

.option {
  padding: 8px 12px 8px 24px;
  display: flex;
  cursor: pointer;
}

.option .iconDesktop{
  margin-right: 8px;
  margin-left: -12px;
  width: 18px;
  height: 18px;
}

.option .iconMobile{
  margin-right: 8px;
  margin-left: -12px;
  width: 24px;
  height: 24px;
}


.option:hover {
  background: rgba(71, 78, 102, 0.1);
}

.noOption {
  padding: 8px 12px 8px 24px;
  opacity: 0.75;
}

@media (max-width: 768px) {
  .input{
    max-height: 36px;
  }

  .label ~ .expandIcon {
    top: 40px;
  }

  .label {
    font-size: 12px;
  }

  .input {
    font-size: 14px ;
  }

  .expandIcon{
    top: 18px;
  }


/* home destination select css */
  .homeInput{
    min-height: 58px;
  }

  .label ~ .homeExpandicon {
    top: 52px !important;
  }

  .homeLableIcon {
   font-size: 14px !important;
  }

  .homeInput {
    font-size: 16px !important;
  }

  .homeExpandicon{
    top: 18px;
  }
}