.container {
  display: grid;
  grid-template: auto/1fr;
}

.carousel {
  scroll-snap-type: x mandatory;
  grid-column: 1;
  grid-row: 1;
}

.item {
  scroll-snap-align: start;
  overflow: hidden;
  width: 100vw;
}

.img {
  width: 100%;
  height: 100%;
  object-position: center;
}

.img > img {
  object-fit: cover;
}

.jump {
  grid-column: 1;
  grid-row: 1;
  justify-self: flex-end;
  background: rgba(0, 0, 0, 0.4);
  display: grid;
  grid-template: auto/1fr;
  gap: 4px 16px;
  border-radius: 6px 0 0 6px;
  padding: 16px 12px 16px 24px;
  margin-bottom: 24px;
  z-index: 0;
  align-self: flex-end;
}

a.jump {
  color: white;
}

.title {
  font-weight: 700;
}

.scrollCrumb {
  grid-column: 1;
  grid-row: 1;
  align-self: flex-end;
  justify-self: center;
}

.imgSelect {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
  cursor: pointer;
}

.imgSelect img {
  object-fit: cover;
}

@media (max-width: 768px) {
  .carousel {
    height: calc(80vh - var(--navbar-height));
  }

  .jump {
    margin-bottom: 72px;
  }

  .title {
    font-size: 16px;
  }

  .subtitle {
    font-size: 14px;
  }

  .scrollCrumb {
    margin-bottom: 16px;
  }
}

@media (min-width: 768px) {
  .container {
    height: calc(100vh - var(--navbar-height));
  }

  .carousel {
    height: 100%;
  }

  .jump {
    margin-bottom: 52px;
    min-width: 250px;
  }

  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 16px;
  }

  .scrollCrumb {
    margin-bottom: 32px;
  }
}
