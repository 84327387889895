.container {
  display: grid;
  grid-template: 1fr auto/1fr;
  gap: 16px;
  align-items: flex-start;
  min-height: 100vh;
}

.form {
  flex: 1 1 0;
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
}

.logo {
  justify-content: center;
}

.description {
  color: #626366;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 16px 0 32px;
  white-space: pre-line;
  text-align: center;
}

.question {
  justify-self: center;
  width: 100%;
}

.ratingContainer {
  display: grid;
}

.rating {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ratingLargeContainer {
  display: grid;
  border: 1px solid lightgray;
  border-radius: 4px;
}

.ratingLarge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid lightgray;
}

.ratingLarge:nth-child(2) {
  border-radius: 4px 0 0 4px;
}

.ratingLarge:last-child {
  border-radius: 0 4px 4px 0;
  border-right: none;
}

.ratingLargeLegend {
  display: flex;
  justify-content: space-between;
  color: #535353;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.2px;
  margin: 8px 0;
}

@media (max-width: 768px) {
  .form {
    padding: 32px 0 0;
  }

  .logo {
    width: 300px;
  }

  .ratingContainer {
    grid-template: auto/repeat(5, 1fr);
    border: 1px solid lightgray;
    border-radius: 4px;
  }

  .rating {
    height: 40px;
    border-right: 1px solid lightgray;
  }

  .rating:nth-child(2) {
    border-radius: 4px 0 0 4px;
  }

  .rating:last-child {
    border-radius: 0 4px 4px 0;
    border-right: none;
  }

  .ratingLargeContainer {
    grid-template: auto/repeat(10, 1fr);
  }

  .ratingLarge {
    height: 40px;
  }

  .submit {
    border-radius: 0;
    position: sticky;
    bottom: 0;
    margin: 0 -16px;
    width: calc(100% + 32px);
    height: 60px;
  }

  #feedback-submit-modal {
    align-items: center;
  }

  .backdrop {
    align-items: center;
  }
}

@media (min-width: 768px) {
  .form {
    padding: 64px 0;
  }

  .logo {
    width: 400px;
  }

  .question {
    max-width: 600px;
  }

  .ratingContainer {
    grid-template: auto/repeat(5, auto);
    justify-content: space-between;
  }

  .rating {
    border: 1px solid lightgray;
    border-radius: 4px;
    width: 100px;
    height: 50px;
  }

  .ratingLargeContainer {
    grid-template: auto/repeat(10, auto);
  }

  .ratingLarge {
    width: 100%;
    height: 50px;
  }

  .submit {
    justify-self: center;
    height: 60px;
    width: 100%;
    max-width: 600px;
    margin-bottom: 100px;
  }
}
