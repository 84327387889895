.container {
  position: relative;
  display: inline-grid;
  grid-template: auto/1fr;
  gap: 4px;
  align-content: flex-start;
  font-weight: 400;
  border: 1px solid #D7D7D7;
  border-radius: 8px;
  padding: 8px 16px;
}

.container[data-focus='true']{
  background: #F5F5F7 ;
}

.optionsContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 1px);
  padding: 16px 16px 0;
  background: white;
  border-radius:8px;
  box-shadow: 0px 4px 8px 0px #00000014;
  border: 1px solid #E6E6E6;
  z-index: 1;
  max-height: 350px;
  overflow: auto;
}

/* .optionsContainer::-webkit-scrollbar {
  -webkit-appearance: unset;
  width: 5px;
}

.optionsContainer::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
  height: 20px;
} */

.label {
  text-transform: capitalize;
  grid-row: 1;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-2);
  opacity: 0.8;
}

.label ~ .expandIcon {
  top: 50%;
}

.input {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-3);
  outline: none;
  width: 100%;
}

.inputPlaceholder {
  color: var(--secondary-contrast-text-2);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input[data-error='true']:hover {
  border-color: #CC385A;
}

.input[data-error='true']:focus {
  border-color: #CC385A;
}

.input[data-error='false']:hover {
  border-color: #474e66;
}

.input[data-error='false']:focus {
  border-color: #474e66;
}

.error {
  color: #CC385A;
  font-size: 12px;
}

.expandIcon {
  position: absolute;
  top: 22px;
  transform: translateY(-50%);
  right: 12px;
  fill: #cccccc;
  pointer-events: none;
}

.expandIcon:hover {
  fill: #474e66;
}

.doneContainer {
  position: sticky;
  bottom: 0;
  padding: 12px 0;
  margin-top: -16px;
  margin-right: -16px;
  margin-left: -16px;
  background: white;
  border-top: 1px solid #0000001A;
}

.done {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  justify-self: end;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  padding: 16px;
  cursor: pointer;
  color: #AA3131;
  border: 1px solid #AA3131;
  background: white;
  width: 136px;
  height: 48px;
  border-radius: 4px;
}

.container[data-disabled='true'] .input {
  /* background: rgba(31,44,56,.04); */
  cursor: not-allowed;
  border: none;
  color: #BCBCBC;
}