.header {
  display: grid;
  align-items: center;
  gap: 16px;
  font-weight: 500;
  color: #000000;
}

.backIcon {
  align-self: center;
  margin-top: 8px;
}

.edit {
  border: 1px solid rgba(31, 44, 56, 0.6);
  border-radius: 4px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  letter-spacing: 0.005em;
  color: rgba(31, 44, 56, 0.6);
  cursor: pointer;
  padding: 8px 16px;
  min-width: 100px;
}

.editForm {
  display: grid;
  gap: 16px;
  align-items: flex-start;
}

.twoColumn {
  grid-column: span 2;
}

.save {
  grid-column: span 2;
  background: #1f2c38;
  cursor: pointer;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.005em;
  color: #ffffff;
  padding: 12px 32px;
  border: none;
}

@media (max-width: 768px) {
  .header {
    height: var(--navbar-height);
    grid-template: auto/auto 1fr auto;
    background: #FFFFFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    font-size: 16px;
    padding-right: 16px;
    padding-left: 16px;
    margin-left: -16px;
    margin-right: -16px;
  }

  .headerBorder {
    display: none;
  }

  .edit {
    font-size: 12px;
  }

  .editForm {
    margin-top: 16px;
  }

  .save {
    grid-template: auto/1fr 1fr;
    justify-self: flex-end;
    font-size: 14px;
    padding: 16px 24px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .header {
    font-size: 24px;
    padding: 12px 8px;
    grid-template: auto/auto 1fr auto;
  }

  .backIcon {
    display: none;
  }

  .header::before {
    content: '';
    height: 100%;
    border-right: 4px solid #dddddd;
    margin: 0 4px 0 -8px;
  }

  .headerBorder {
    margin: 0 16px 24px;
    height: 1px;
    background: #dddddd;
  }

  .edit {
    font-size: 16px;
  }

  .editForm {
    grid-template: auto/180px 1fr;
    padding: 0 16px 16px;
    max-width: 480px;
  }

  .save {
    justify-self: flex-end;
    font-size: 16px;
  }
}