.ctaContainer {
  position: sticky;
  bottom: 0;
  background: white;
  box-shadow: 0 -1px 7px 0 rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template: auto/1fr 40%;
}

.ctaDescription {
  color: var(--secondary-contrast-text-3);
  display: flex;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}

.ctaAction {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-main);
  color: var(--primary-contrast-text);
  font-weight: 500;
}

.ctaAction:visited {
  background: var(--primary-main);
  color: var(--primary-contrast-text);
}

@media (max-width: 768px) {
  .banner {
    height: 40vh;
  }

  .section {
    margin-top: 24px;
  }

  .ctaContainer {
    height: 52px;
  }

  .ctaDescription {
    font-size: 12px;
  }

  .ctaAction {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .banner {
    height: 80vh;
  }

  .section {
    margin-top: 56px;
  }

  .ctaContainer {
    height: 80px;
  }

  .ctaAction {
    font-size: 14px;
    min-width: 200px;
    min-height: 48px;
    border-radius: 4px;
    justify-self: center;
    align-self: center;
  }
}
