.couponCard {
  background: white;
  padding: 16px;
  border-radius: 8px;
  display: grid;
  grid-template: auto / 1fr;
  width: 500px;
  align-self: center;
}

.couponHeader {
  display: grid;
  grid-template: auto / 1fr auto;
  align-items: center;
  gap: 2px;
  border-bottom: 1px solid #0000001a;
  padding: 0 12px 12px 12px;
  margin-left: -16px;
  margin-right: -16px;
}

.couponCode {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-3);
}

.close {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: #1f2c381a;
  padding: 4px;
  border-radius: 50%;
}

.termsListConatiner {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0px;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 55vh;
}

.contentContainer {
  display: grid;
  grid-template: auto / 50px auto;
  gap: 4px 10px;
  height: fit-content;
  margin-top: 16px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: var(--secondary-contrast-text);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.description {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--secondary-contrast-text-2);
  /* Multi-line ellipsis */
  display: -webkit-box;
  /* Limit text to 2 lines */
  -webkit-line-clamp: 2;
  line-clamp: 2;
  /* Ensures the text is treated as a vertical box */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.offerImg {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.tncLink {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  font-variation-settings: "slnt" -10;
  text-decoration-line: underline;
  color: var(--secondary-contrast-text-2)99;
  grid-column: 2;
  justify-self: end;
  /* font-style: italic; */
  cursor: pointer;
  align-self: center;
}

.content {
  display: grid;
  height: fit-content;
}

.hideDivider {
  display: none;
}

.divider {
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
  border-image: repeating-linear-gradient(to right,
      rgba(0, 0, 0, 0.1) 0px,
      rgba(0, 0, 0, 0.1) 5px,
      transparent 5px,
      transparent 10px) 1;
  margin: 8px 0 0 0;
  grid-column: 1 / span 2;
}

.termsContainer {
  display: flex;
  justify-content: space-between;
  grid-column: 2;
}

.discountBox {
  color: #33AA31;
  /* Green text color */
  background: linear-gradient(to right, #E3F2E3 50%, rgba(255, 255, 255, 0) 100%);
  border-radius: 2px;
  padding: 6px;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .couponCard {
    border-radius: 4px 4px 0 0;
    align-self: flex-end;
    overflow-y: hidden;
  }

  .couponCode {
    font-size: 14px;
    line-height: 20px;
  }

  .close {
    width: 20px;
    height: 20px;
  }

  .carousel {
    scroll-snap-type: x mandatory;
    grid-column: 1;
    gap: 16px;
  }

  .item {
    display: grid;
    scroll-snap-align: start;
    overflow: hidden;
    width: calc(100vw - 32px);
  }

  .contentContainer {
    border: none;
    padding: 0px;
    border-radius: 0px;
    grid-template: auto / auto 1fr;
    gap: 10px;
  }

  .scrollCrumb {
    grid-column: 1;
    /* grid-row: 2; */
    align-self: flex-end;
    justify-self: center;
    margin-bottom: -4px;
  }

  .offerImg {
    width: 52px;
    height: 52px;
    align-self: center;
  }

  .title {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
  }

  .termsContainer {
    grid-column: 1/ span 2;
  }

  .tncLink {
    grid-column: 3;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-decoration-style: solid;
    align-content: flex-start;
    text-decoration-skip-ink: auto;
    font-variation-settings: "slnt" -10;
    margin: -4px 0 0 0;
  }

  .content {
    gap: 2px;
  }

  .divider {
    margin: 6px 0 0 0;
  }

  .termsListConatiner {
    max-height: 60vh;
    padding-bottom: 100px;
  }
}