.container {
  display: grid;
}

.title {
  color: var(--secondary-contrast-text-3);
  font-weight: 600;
}

.description {
  font-weight: 400;
}

.subtitle {
  font-weight: 700;
  margin-top: 20px;
}

.icon {
  width: 40px;
  object-fit: contain;
}

.illustration {
  object-fit: contain;
}

.workout {
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 16px;
  align-items: center;
  font-weight: 500;
  min-height: 40px;
  white-space: pre;
  font-weight: 500;
  color: var(--secondary-contrast-text);
}

.workoutContainer {
  gap: 20px;
  display: grid;
  margin: 32px 0 24px;
}

.wellnessContainer {
  gap: 20px;
  display: grid;
  margin: 24px 0 24px;
}

.packageContainer {
  background: #D870360F;
  display: grid;
  border-radius: 4px;
}

.textContainer {
  display: grid;
}

.titleContainer {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.packageTitle {
  color: #D87036;
  font-weight: 700;
  letter-spacing: 0.05px;
}

.packageSubtitle {
  color: var(--secondary-contrast-text);
  font-weight: 400;
}

@media (max-width: 768px) {
  .title {
    font-size: 16px;
    line-height: 19px;
    margin-top: 0px;
  }

  .description {
    font-size: 14px;
    line-height: 24px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 17px;
  }

  .workoutContainer {
    grid-template: auto/ repeat(2, 1fr);
    gap: 20px;
    margin: 8px 0;
  }

  .wellnessContainer {
    grid-template: auto/ 1fr;
    gap: 10px;
    margin: 10px 0;
  }

  .workout {
    font-size: 14px;
    line-height: 17px;
  }

  .icon {
    width: 32px;
  }

  .packageContainer {
    display: grid;
    grid-template: 1fr/auto;
    align-items: center;
    padding: 12px 8px;
    margin: 16px 0;
  }

  .textContainer {
    grid-column: 1;
    grid-row: 1;
    gap: 24px;
  }

  .packageTitle {
    font-size: 12px;
    line-height: 15px;
  }

  .packageSubtitle {
    font-size: 12px;
    line-height: 15px;
  }

  .illustration {
    grid-column: 1;
    grid-row: 1/span 3;
    height: 80px;
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 32px;
    line-height: 35px;
    margin-top: 0px;
  }

  .description {
    font-size: 16px;
    line-height: 26px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 19px;
  }

  .workoutContainer {
    grid-template: auto/ repeat(3, 1fr);
    gap: 32px;
  }

  .windsongContainer {
    grid-template: auto/ repeat(2,1fr);
    margin: 24px 0 12px 0;
  }

  .wellnessContainer {
    grid-template: auto/ repeat(2,1fr);
    gap: 32px;
  }

  .workout {
    font-size: 16px;
    line-height: 19px;
  }

  .packageContainer {
    grid-template: auto/1fr auto;
    gap: 16px;
    padding: 12px;
    margin-top: 24px;
  }

  .textContainer {
    grid-template: 1fr 1fr/auto;
  }

  .packageTitle {
    font-size: 16px;
    line-height: 26px;
  }

  .packageSubtitle {
    font-size: 14px;
    line-height: 26px;
  }

}