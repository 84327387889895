.container {
  overflow: hidden;
  --content-peek: 0px;
}

.content {
  transition: margin-bottom 0.3s 0s ease-out, opacity 0.3s ease-out;
  margin-bottom: calc(-1 * var(--content-height) + var(--content-peek));
  opacity: 0;
}

.content[data-expanded="true"] {
  margin-bottom: 0;
  opacity: 1;
}
