.container {
  display: grid;
  background: white;
  border: 1px solid rgba(151, 151, 151, 0.25);
  grid-template: auto/1fr auto;
}

.couponCode {
  grid-column: span 2;
}

.discountAlert {
  grid-column: span 2;
}

.earningsDesktop {
  background: #ffffff;
  border: 1px solid rgba(151, 151, 151, 0.119045);
  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #1f2c38;
  padding: 16px;
  margin-bottom: 16px;
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 16px;
  align-items: center;
}

.earningsMobile {
  background: #ffffff;
  border: 1px solid rgba(151, 151, 151, 0.119045);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #1f2c38;
  padding: 12px 16px 12px 8px;
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 12px;
  align-items: center;
}

.lightLabel {
  color: #626366;
  display: flex;
  gap: 4px;
  font-weight: 500;
}

.lightValue {
  color: #626366;
  font-weight: 500;
  text-align: right;
}

.heavyLabel {
  color: var(--secondary-contrast-text);
  font-weight: bold;
}

.heavyValue {
  color: var(--secondary-contrast-text);
  font-weight: bold;
  text-align: right;
}

.llpLabel {
  color: #d87036;
  font-weight: bold;
  text-transform: capitalize;
}

.llpValue {
  color: #d87036;
  font-weight: bold;
  text-align: right;
}

.heavyDistLabel {
  color: var(--secondary-contrast-text);
  font-weight: bold;
  border-top: 1px solid rgba(121, 121, 121, 0.2);
}

.heavyDistValue {
  color: var(--secondary-contrast-text);
  font-weight: bold;
  text-align: right;
  border-top: 1px solid rgba(121, 121, 121, 0.2);
}

.subtitle {
  color: rgba(98, 99, 102, 0.8);
  font-size: 10px;
  letter-spacing: 0.4px;
  font-weight: 500;
}

.submitDesktop {
  grid-column: span 2;
  height: 48px;
  margin: 0 -24px -16px -24px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.submitMobilePortal {
  position: sticky;
  bottom: 0;
  margin: 0 -16px;
  width: calc(100% + 32px);
}

.submitMobile {
  margin: 0 -16px;
  border-radius: 0;
  width: calc(100% + 32px);
  height: 54px;
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: bold;
  text-transform: uppercase;
}

.llpSwitch {
  grid-column: span 2;
  margin-left: -24px;
  margin-right: -24px;
  padding: 0px 24px;
  margin-bottom: -4px;
}

.llpSwitchContent {
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 8px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #1f2c38;
}

.ineligiblePointsContent {
  border-radius: 0px 0px 4px 4px;
  background: rgba(181, 41, 74, 0.09);
  padding: 12px 24px;
  align-items: center;
  grid-column: span 2;
  display: grid;
  grid-template: auto/auto auto 1fr;
  gap: 8px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1px;
  color: var(--secondary-contrast-text);
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -10px;
}

.pointsSuccess {
  border-radius: 0px 0px 4px 4px;
  background: #dcf2e6;
  padding: 12px 24px;
  align-items: center;
  grid-column: span 2;
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 8px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1px;
  color: var(--secondary-contrast-text);
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -10px;
}

.pointsSuccess img {
  height: 26px;
  width: 26px;
}

.ineligiblePointsContent .knowMoreBtn {
  background: none!important;
  border: none;
  padding: 0!important;
  /* font-family: arial, sans-serif; */
  font-family: var(--font-family);
  color: var(--secondary-contrast-text-2);
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
  font-size: 12px;
  margin-right: 4px;
}

/* this css is for skeleton loader */
.breakdownSkeletonContainer{
  display: grid;
  background: white;
  border: 1px solid rgba(151, 151, 151, 0.25);
}

.blackBorder{
  border-top: 1px solid rgba(121, 121, 121, 0.2);
  padding-top: 28px;
}

.submitSkeleton{
  margin: 0 -24px -16px -24px;
}

.checkboxSkeleton{
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px;
  align-items: center;
}
/*  */

@media (max-width: 768px) {
  .container {
    gap: 24px 0;
    padding: 16px;
  }

  /* this css is for skeleton loader */
  .breakdownSkeletonContainer{
    gap: 24px 0;
    padding: 16px;
  }

  .blackBorder{
    padding-top: 24px;
  }

  .submitSkeleton{
    display: none;
  }

  .couponSkeleton{
    display: none;
  }
/* */


  .lightLabel {
    font-size: 12px;
    letter-spacing: 0.4px;
  }

  .lightValue {
    font-size: 12px;
    letter-spacing: 0.75px;
  }

  .heavyLabel,
  .llpLabel {
    font-size: 12px;
    letter-spacing: 0.4px;
  }

  .heavyValue,
  .llpValue {
    font-size: 12px;
    letter-spacing: 0.75px;
  }

  .heavyDistLabel {
    font-size: 12px;
    letter-spacing: 0.4px;
    padding-top: 24px;
  }

  .heavyDistValue {
    font-size: 12px;
    letter-spacing: 0.75px;
    padding-top: 24px;
  }

  .submitDesktop {
    display: none;
  }

  .couponCode {
    grid-row: 1;
  }

  .llpSwitch {
    padding: 8px 12px;
    font-size: 12px;
    border-top: 1px solid rgba(121, 121, 121, 0.2);
    border-bottom: 1px solid rgba(121, 121, 121, 0.2);
  }

  .llpSwitchContent {
    font-size: 12px;
  }

  .pointsSuccess,
  .ineligiblePointsContent {
    font-size: 12px;
    padding: 8px 12px;
    margin-top: -20px;
  }

  .pointsSuccess img,
  .ineligiblePointsContent img {
    height: 18px;
    width: 18px;
  }

  .earningsDesktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .container {
    gap: 28px 0;
    padding: 16px 24px;
    border-radius: 4px;
  }

  /* this css is for skeleton loader */
  .breakdownSkeletonContainer{
    gap: 28px 0;
    padding: 16px 24px;
    border-radius: 4px;
  }
  /* */

  .lightLabel {
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  .lightValue {
    font-size: 14px;
    letter-spacing: 0.9px;
  }

  .heavyLabel,
  .llpLabel {
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  .heavyValue,
  .llpValue {
    font-size: 14px;
    letter-spacing: 0.9px;
  }

  .heavyDistLabel {
    font-size: 14px;
    letter-spacing: 0.5px;
    padding-top: 24px;
  }

  .heavyDistValue {
    font-size: 14px;
    letter-spacing: 0.9px;
    padding-top: 24px;
  }

  .submitMobilePortal {
    display: none;
  }

  .earningsMobile {
    display: none;
  }
}
