.container {
  background: white;
  box-shadow: 0 2px 10px 0 rgba(48, 48, 48, 0.15);
  border-radius: 6px;
  padding: 16px 24px;
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
  align-content: flex-start;
}

.title {
  color: var(--secondary-contrast-text);
  font-weight: 700;
  margin-bottom: 4px;
}

.link {
  font-weight: 500;
}

a.link {
  color: var(--primary-main);
}

@media (max-width: 768px) {
  .title {
    font-size: 16px;
  }

  .link {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 20px;
  }

  .link {
    font-size: 16px;
    line-height: 30px;
  }
}
