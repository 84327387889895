.container {
  display: grid;
  grid-template: auto/1fr;
  background: rgba(210,36,44,0.1);
}

.section{
  display: grid;
  grid-template: auto/1fr;
  margin-top: 0 !important;
}

.title {
  color: #350d0c;
  font-weight: bold;
}

.subtitle {
  color: #371a19;
  font-weight: 500;
}

.occasions {
  display: grid;
  gap: 16px;
  overflow: hidden;
}

.backdrop {
  border-radius: 4px;
  z-index: 0;
  grid-column: 1;
  grid-row: 1 / span 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.7) 85%);
}

.scroller{
  grid-column: 2;
  padding-bottom: 19px;
}

.occasionContainer {
  display: grid;
  grid-template: auto/1fr;
  border-radius: 8px;
}

.partial1 {
  display: grid;
  grid-template: auto/1fr;
  transition: all 0.5s;
}

.partial1Thumbnail {
  object-fit: cover;
  grid-column: 1;
  grid-row: 1;
  border-radius: 8px;
}


.partial1Title {
  z-index: 1;
  grid-column: 1;
  grid-row: 1;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 52px;
  align-self: flex-end;
  justify-self: center;
}

.viewAction{
  z-index: 1;
  width: 100%;
  grid-row: 1;
  grid-column: 1;
  text-align: center;
  align-self: flex-end;
  color: #FFFFFF;
  line-height: 26px;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 24px;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 768px) {
  .container {
    gap: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: var(--secondary-contrast-text-3);
  }

  .subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: -14px;
    color: var(--secondary-contrast-text);
  }

  .section{
    grid-column: span 2;
  }

  .scroller{
    display: none;
  }

  .occasionContainer {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px 0;
    margin-right: 16px;
  }

  .partial1Title {
    display: none;
  }
}

@media (min-width: 768px) {
  .container {
    gap: 10px;
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .occasions{
    gap: 40px;
    margin-top: -68px;
  }

  .title {  
   font-size: 32px;
   font-weight: 700;
   line-height: 38.73px;
   text-align: left;
   letter-spacing: 0.5px;
  }

  .subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    padding-right: 127px;
  }

  .occasionContainer {
    width: 270px;
    height: 362px;
    background: #d8d8d8;
    margin-right: 24px;
  }

  .partial1 {
    height: inherit;
    grid-column: 1;
    grid-row: 1;
    opacity: 1;
  }

}
