.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--secondary-contrast-text);
  margin-bottom: -12px;
}

.subTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: var(--secondary-contrast-text-2);
  margin-top: 16px;
}

.formContainer {
  display: grid;
  gap: 20px;
}

.phoneContainer {
  display: grid;
  gap: 10px;
  grid-template: auto/1fr 1fr;
}

.sendOtpButton {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 16px 28px;
  margin-top: -4px;
}

.phoneNo input{
  font-family: var(--font-family) !important;
  color: var(--secondary-contrast-text) !important;
  font-weight: 420;
}

@media (max-width: 768px) {

.phoneNo input{
  font-size: 14px;
}

}
