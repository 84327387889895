.container {
  display: grid;
}

.title {
  grid-column: span 2;
}

.elevatedContainer {
  grid-column: span 2;
  display: flex;
  flex-wrap: wrap;
}

.elevatedPartial {
  white-space: nowrap;
  width: fit-content;
}

.elevatedPartial:not(:last-child) {
  margin-right: 16px;
}

.first {
  width: 100%;
  object-fit: cover;
}

.rest {
  width: 100%;
  height: 250px;
}

.rest :global .image {
  object-fit: cover;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr 1fr;
    gap: 16px;
    padding: 32px 0;
  }

  .first {
    grid-column: span 2;
    height: 200px;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: auto/65% 1fr;
    gap: 24px;
    padding: 64px 0;
  }

  .first {
    grid-row: span 2;
    height: 524px;
  }
}
