
.leftChevron {
  height: 24px;
  align-self: center;
}

.leftChevronContainer{
  display: flex;
  grid-row: 1;
  grid-column: 1;
  justify-self: flex-start;
}

.locationIcon,
.caretIcon,
.addGuestsIcon,
.eventIcon {
  margin: 0 6px;
}

.container {
  display: grid;
  gap: 16px;
  color: white;
  transition: all ease-in-out 0.3s;
  grid-template: 1fr auto/1fr;
}

.background {
  object-fit: cover;
  grid-column: 1;
  grid-row: 1/span 3;
  height: 100%;
  width: 100%;
}

.overlay {
  grid-column: 1;
  grid-row: 1/span 3;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.breadcrumb {
  grid-column: 1;
  grid-row: 1;
  font-weight: 500;
  margin: 16px 0 0;
  color: white;
}

.title {
  grid-column: 1;
  grid-row: 1;
  font-weight: 700;
  margin: 0;
  white-space: pre-wrap;
  align-self: flex-end;
}

.header{
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.5em;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 16px;
}

.bannerTitle{  
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
}

.subtitle {
  grid-column: 1;
  grid-row: 2;
  margin: 0;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.015em;
  text-align: center;
}

.filters {
  width: 100%;
  background: white;
  align-self: flex-end;
  border-radius: 4px;
}

.filtersDesktop {
  top: 10px;
  display: grid;
  grid-template-columns: 16fr 15fr 12fr 6fr auto;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(170, 170, 170, 0.5);
  border-radius: 8px;
  gap: 8px;
}

.filtersMobile {
  top: 6px;
  font-size: 10px;
  display: grid;
  grid-template: auto/3fr 2fr;
  color: var(--secondary-contrast-text-3);
  transition: all 0.1s;
  cursor: pointer;
  border: 1px solid #F0F0F0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
}

.destination :global .input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top: none;
  border-left: none;
  border-bottom: none;
}

.destination{
  align-content: center;
  border: 1px solid #DADFE6;
  margin: 8px 0px 8px 8px;
  padding: 8px 16px;
  border-radius: 4px;
}

.destination :global .expand-icon {
  top: 50%;
}

.stay :global .value {
  min-height: auto;
}

.occupancy :global .input {
  border-radius: 0;
  border: none;
  padding: 0;
}

.occupancy :global .input[data-error='false']:hover,
.occupancy :global .input[data-error='false']:focus {
  border-color: #ebebeb;
}

.occupancy :global .expand {
  top: 50%;
}

.search {
  position: relative;
}

.search input {
  border: none;
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 16px;
  background: white;
  padding: 12px;
  color: #474e66;
}

.serachIcon{
  width: 16px;
  height: 16px;
}

.searchButton {
  border: 1px solid rgba(170, 49, 49, 1);
  background: none;
  letter-spacing: 0;
  color: rgba(170, 49, 49, 1);
  margin: 8px 8px 8px 0;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  padding: 6px 30px;
  box-shadow:none;
}

.friendly {
  padding: 12px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.caret {
  margin-left: auto;
  opacity: 0.6;
}

/* .friendly:not(:last-child) {
  border-right: 1px solid #ebebeb;
} */

.friendly[data-populated='false'] {
  font-weight: 400;
}

.friendly[data-populated='true'] {
  font-weight: 600;
  color: var(--secondary-contrast-text);
}

.destinationFriendlyConatiner[data-populated='false'] {
  font-weight: 400;
}

.destinationFriendlyConatiner[data-populated='true']{
  font-weight: 600;
  color: var(--secondary-contrast-text);
}

.webOffer {
  display: grid;
  grid-column: 1;
  grid-row: 2;
  grid-template: auto/auto 1fr;
  align-content: flex-start;
  align-self: flex-end;
  justify-self: center;
  gap: 0 12px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(14px);
  border-radius: 8px;
  width: 100%;
}

.webOfferTitle {
  color: #FFABAB;
  font-weight: 800;
  letter-spacing: 0.5px;
}

.webOfferSpan {
  color: #ffffff;
}

.webOfferSubtitle {
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #FFFFFFCC;
}

.clockIcon {
  grid-row: 1/span 2;
}

@media (max-width: 768px) {
  .container {
    height: 81px;
  }

  /* .container>*:not(img) {
    padding: 0 16px;
  */

  .container>*:not(img) {
    padding: 8px 16px;
  }

  .breadcrumb {
    font-size: 12px;
  }

  .webOffer {
    grid-template: auto/1fr;
    margin: 0 12px;
    letter-spacing: 0.25px;
  }

  .destination  {
    display: none;
  }

  .clockIcon {
    display: none;
  }

  .webOfferTitle {
    font-size: 16px;
  }

  .webOfferSpan {
    font-size: 8px;
  }

  .webOfferSubtitle {
    font-size: 8px;
    line-height: 10px;
  }

  .title {
   display: none;
  }

  .subtitle {
    display: none;
  }

  .filters {
    max-width: 100%;
  }

  .logo {
    min-height: 32px;
  }

  .mobileHeader{
    /* display: grid;
    grid-template: auto / 1fr auto;
    align-items: center;
    margin: 12px 0 0 0; */
    display: none;
  }

  .propertySearchMobileContainer{
    display: grid;
    grid-template: auto / 1fr auto ;
    gap:4px;
  }

  .propertySearchMobile{
    display: grid;
    gap: 2px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #0000002E;
    padding: 8px 16px;
  }

  .filtersDesktop{
    grid-template-columns: auto;
    display: grid;
    border-radius: 6px;
    background: none;
    box-shadow: none;
    border: none;
    display: grid;
  }

  .stay{
    display: none;
  }

  .occupancy{
    display: none;
  }

  .searchButton{
    display: none;
  }

  .filtersMobile {
    display: grid;
  }

  .sortButton{
    border: 1px solid #0000002E;
    border-radius: 6px;
    padding: 14.5px;
    /* grid-row: 1 / span 2;
    grid-column: 2; */
  }

  .addGuestDateConatiner{
    display: flex;
    gap: 3px;
  }

  .dot{
    font-size: 10px;
    opacity: 50%;
  }

  .serachBlackIcon{
    grid-column: 2;
    align-self: center;
    grid-row: 1 / span 2;
  }

  .destinationFriendlyConatiner{
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    color: var(--secondary-contrast-text-2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .destinationFriendlyConatiner[data-populated='true']{
    font-weight: 500;
  }

  .destinationFriendlyConatiner[data-populated='false']{
    opacity: 80%;
  }

  .friendly[data-populated='false']{
    opacity: 80%;
  }

  .friendly[data-populated='true']{
    font-weight: 500;
  }

  .friendly {
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: var(--secondary-contrast-text-2); 
    padding: 0;
    justify-content: flex-start;
  }

  .webOffer {
    max-width: calc(100% - 32px);
  }
}

@media (min-width: 768px) {

  .container {
    height: 192px;
  }

  .propertySearchMobile{
    display: none;
  }

  .sortButton{
    display: none;
  }

  .mobileHeader{
    display: none;
  }

  .propertySearchMobileContainer{
    display: none;
  }

  .breadcrumb {
    font-size: 16px;
  }

  .container>*:not(img) {
    padding: 18px 20px;
  }

  .webOffer {
    margin: 100px 130px;
  }

  .webOfferTitle {
    font-size: 28px;
  }

  .webOfferSubtitle {
    font-size: 12px;
    line-height: 19px;
  }

  .webOfferSpan {
    font-size: 16px;
  }

  .title {
    font-size: 26px;
    margin-bottom: -10px;
  }

  .subtitle {
    font-size: 16px;
    margin-bottom: 33px;
  }

  .filtersMobile {
    display: none;
  }

  .filtersDesktop {
    display: grid;
    grid-template-columns: 16fr 15fr 12fr 6fr;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(170, 170, 170, 0.5);
    border-radius: 8px;
    gap: 8px;
    z-index: 1;
  }

  .stay {
    min-height: unset;
    border: 1px solid #DADFE6;
    margin: 8px 0 8px 0;
    border-radius: 4px;
    padding: 8px 16px;
  }

  .destination :global .input {
    background: none;
    width: -webkit-fill-available;
    font-size: 14px;
    padding: 0;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
  }

  .occupancy :global .input {
    font-size: 14px;
    line-height: 20px;
  }

  .search {
    padding: 8px 12px;
  }

}

@media (min-width: 768px) and (max-width: 1024px) {
  .container>*:not(img):not(.webOffer) {
    padding: 0 calc((100% - 712px) / 2);
  }


  .filters {
    max-width: 712px;
    margin-left: auto;
    margin-right: auto;
  }

  .webOffer {
    max-width: 712px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .container>*:not(img):not(.webOffer) {
    padding: 0 calc((100% - 960px) / 2);
  }

  .filters {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

  .webOffer {
    max-width: 960px;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .container>*:not(img):not(.webOffer) {
    padding: 0 calc((100% - 1100px) / 2);
  }

  .filters {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  .webOffer {
    max-width: 1100px;
  }
}

@media (min-width: 1440px) {
  .container>*:not(img):not(.webOffer) {
    padding: 0 calc((100% - 1200px) / 2);
  }

  .filters {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }

  .webOffer {
    max-width: 1200px;
  }
}
