.container {
  display: grid;
  background: white;
  border-radius: 6px;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 10%);
}

.thumbnail {
  object-fit: cover;
  width: 100%;
}

.title {
  color: #121212;
  font-weight: bold;
}

.description {
  color: #707070;
  font-weight: 500;
}

.vpLink {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.5px;
  font-weight: 500;
  justify-self: flex-end;
}

a.vpLink {
  color: var(--primary-main);
}

.vpButton {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.1px;
  border: 1px solid var(--primary-main);
  color: var(--primary-main);
  border-radius: 4px;
}

.vpButton:visited {
  color: var(--primary-main);
}

.vpButton :global .MuiButton-label {
  color: var(--primary-main);
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr;
    gap: 16px;
    padding: 16px;
  }

  .thumbnail {
    margin: -16px -16px 0;
    width: calc(100% + 32px);
    height: 266px;
    border-radius: 6px 6px 0 0;
  }

  .title {
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  .description {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 22px;
  }

  .vpLink {
    display: none;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: auto 1fr auto/ 40% 1fr;
    gap: 24px;
    padding: 24px;
    height: 380px;
  }


  .thumbnail {
    grid-row: span 3;
    margin: -24px 0 -24px -24px;
    height: calc(100% + 48px);
    width: calc(100% + 24px);
  }

  .title {
    font-size: 24px;
    letter-spacing: 0.8px;
  }

  .description {
    font-size: 15px;
    letter-spacing: 0.4px;
    line-height: 28px;
  }

  .vpButton {
    display: none;
  }
}
