.container {
    margin-bottom: 64px;
}

.sectionTitle {
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    color: #464E68;
    margin-bottom: 32px;
}

.villaContainer {
    display: grid;
    grid-template: auto/1fr 1fr 1fr;
    gap: 24px;
}

.property {
    width: 384px;
    position: relative;
    padding: 0 16px 16px;
    border-radius: 6px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.10);
    background: white;
    display: grid;
    grid-template: 200px auto 1fr auto/1fr;
    gap: 6px;
    height: 100%;
  }
  
  a.property {
    color: #626366;
  }
  
  .thumbnail {
    margin: 0 -16px 0;
    height: 260px;
    width: calc(100% + 32px);
    border-radius: 6px 6px 0 0;
    object-position: center;
  }
  
  .thumbnail :global .image {
    object-fit: cover;
  }
  
  .title {
    font-size: 24px;
    font-weight: 700;
    margin-top: 70px;
    line-height: 29px;
    margin-left: -3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--secondary-contrast-text);
  }
  
  .subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--secondary-contrast-text-2);
    margin-top: -6px;
  }
  
  .list {
    font-size: 10px;
    margin-top: 2px;
    padding-top: 24px;
    border-top: 1px solid rgba(151,151,151,0.2);
    position: relative;
  }
  
  .rate {
    font-size: 18px;
    font-weight: 700;
  }

  .tax {
    font-size: 10px;
    font-weight: 400;
  }
  
  .ispravaIcon {
    position: absolute;
    width: 52px;
    right: 8px;
    top: 200px;
    transform: translateY(-50%);
  }

  .specContainer {
    display: flex;
    flex-direction: row;
    margin: 14px 0px;
  }
  
  .spec {
    padding: 0 8px;
    border-right: 1px solid rgba(53,58,60,0.3);
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--secondary-contrast-text);
  }
  
  .spec:first-child {
    padding-left: 0;
  }
  
  .spec:last-child {
    padding-right: 0;
    border-right: none;
  }

  .viewDetailsBtn {
    position: absolute;
    top: 40%;
    right: 0;
    color: var(--primary-main);
    background: white;
    border: 1px solid;
    box-shadow: none;
    text-transform: capitalize;
  }
  
  @media (max-width: 768px) {

    .sectionTitle {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 24px;
    }

    .villaContainer {
      grid-template: auto/1fr;
      gap: 16px;
    }

    .property {
      width: 100%;
      padding: 0 12px 12px;
    }

    .thumbnail {
      width: calc(100% + 24px);
      height: 200px;
      margin: 0 -12px 0;
    }

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      margin-top: 12px;
      margin-left: -2px;
      color: var(--secondary-contrast-text-3);
    }

    .subtitle {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      margin-top: -4px;
    }

    .specContainer {
      margin: 10px 0px;
    }

    .spec {
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
    }

    .list {
      margin-top: 0px;
      padding-top: 16px;
    }

    .rate {
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
    }

    .viewDetailsBtn {
      top: 30%;
    }

  }