.container {
  border-radius: 4px;
  overflow: hidden;
  display: grid;
  grid-template: auto/1fr;
}

.overlay {
  grid-row: 1;
  grid-column: 1;
  background: #F5F5F7;
  opacity: 0.8;
}

.content {
  grid-row: 1;
  grid-column: 1;
  color: var(--secondary-contrast-text);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  align-self: center;
  white-space: pre-line;
  padding: 32px;
  z-index: 0;
}

.contentCollapse{
  grid-row: 1;
  grid-column: 1;
  color: var(--secondary-contrast-text);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  align-self: center;
  white-space: pre-line;
  z-index: 0;
}

.readMore{
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  margin-top: 8px;
}

.primaryContent{
  margin: 0;
}

@media (max-width: 768px) {
  .container {
    margin-left: -16px;
    margin-right: -16px;
  }

  .readMore{
    margin-top: 4px;
  }
}