.container {
  display: grid;
  grid-template: auto/1fr;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  padding-left: 0;
  list-style-type: none;
}

.container li {
  padding-left: 14px;
  text-indent: -14px;
}

.container li::before {
  content: "-";
  margin-right: 8px;
}

.titleContainer,
.stickyContainer {
  font-weight: 600;
  color: var(--secondary-contrast-text-3);
}

.readMore {
  color: #1F2C38;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  margin: -8px 0 0;
  letter-spacing: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border: 1px solid #1F2C38;
  border-radius: 6px;
  padding: 10px 34px;
  height: 42px;
}

.readMoreIcon {
  justify-self: flex-end;
  grid-row: 1;
  align-self: center;
}

.collapse {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
}

@media (max-width: 768px) {
  .container {
    font-size: 12px;
    padding: 0px;
    grid-template: auto/1fr auto;
    margin: 0px;
  }

  .titleContainer,
  .stickyContainer {
    font-size: 16px;
    grid-column: span 2;
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .stickyContainer {
    position: sticky;
    top: calc(var(--navbar-height) - 4px);
    z-index: 0;
    background: var(--root-background);
    padding: 24px 16px;
    margin: 0 -16px;
  }

  .collapse {
    padding-bottom: 16px;
  }

  .readMore {
    display: none;
  }
}

@media (min-width: 768px) {
  .container {
    gap: 16px;
    margin: 8px 0 0;
  }

  .titleContainer {
    font-size: 24px;
    margin-bottom: 6px;
  }

  .collapse {
    padding-bottom: 16px;
  }

  .readMore {
    justify-self: flex-start;
  }

  .titleContainer .readMoreIcon {
    display: none;
  }
}