.container {
  position: relative;
  display: grid;
  grid-template: auto / 1fr auto 1fr;
  gap: 4px 0;
  cursor: pointer;
}

.container[data-focus='true'] {
  /* background: #F5F5F7; */
  border: 1px solid var(--border-color) !important;
}

.horizontalDivider[data-focus='true'] {
  border-right: 1px solid var(--border-color) !important;
}

.container[data-focus='true'][data-price-calculator='true'] {
  border-radius: 6px 6px 0 0; 
}

.container:hover .value {
  border-color: #474e66;
}

.container[data-error='true']:hover .value {
  border-color: #CC385A;
}

.container[data-disabled='true'] {
  cursor: not-allowed;
}

.label {
  align-content: flex-end;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(120, 120, 120, 1);
  text-transform: capitalize;
  grid-row: 1;
  grid-column: 1;
  opacity: 80%;
  cursor: pointer;
}

.labeltwo{
  justify-self: end;
  align-content: flex-end;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(120, 120, 120, 1);
  text-transform: capitalize;
  grid-row: 1;
  grid-column: 3;
  opacity: 80%;
}

.eventDateContainer{
  grid-column: 2;
  grid-row: 1 / span 2;
  display: grid;
}


.eventDate{
  width: 25px;
  height: 25px;
  padding: 5px;
  grid-column: 1;
  align-self: center;
  grid-row: 1 / span 2;
  background: white;
}

.horizontalDivider{
  border-right: 1px solid #0000001A;
  justify-self: center;
  grid-row: 1 / span 2;
  grid-column: 1;
  margin: -8px 0;
}

.value {
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  min-height: auto;
  color: var(--secondary-contrast-text);
  outline: none;
  width: 100%;
  min-height:auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.value[data-placeholder='true'] {
  color: var(--secondary-contrast-text-2);
  font-weight: 400;
}

.value :global .clear {
  font-size: 28px;
  color: #cccccc;
  cursor: pointer;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.value:nth-child(odd) {
  justify-content: end;
  grid-row: 2;
  grid-column: 3;
  padding: 0;
  font-weight: 600;
}

.value:nth-child(odd)[data-placeholder='true'] {
  color: var(--secondary-contrast-text-2);
  font-weight: 500;
}

.value:nth-child(even) {
  padding: 0;
  font-weight: 600;
  grid-row: 2;
  grid-column: 1;
}

.value:nth-child(even)[data-placeholder='true'] {
  color: var(--secondary-contrast-text-2);
  font-weight: 500;
}

.helperText {
  position: relative;
  grid-row: 1;
  grid-column: 1 / span 3;
  margin-top: -50px;
  margin-right: -12px;
  margin-left: -12px;
  z-index: 1000;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  height: fit-content;
  padding: 9px;
  width: 100%;
  border: 1px solid #AA31311A;
  background: linear-gradient(97.92deg,#AA313114 40.28%,#AA313114 97.78%),linear-gradient(0deg, white, white);
  border-radius: 4px;
  color: #751919;
  text-align: center;
}

/* Adding the arrow */
.helperText::after {
  content: "";
  position: absolute;
  bottom: -6px; 
  left: 8%;
  transform: translateX(-50%);
  width: 0;
  background: white;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #AA31311A; /* Match the background color */
}


.dialog {
  transition: all 0.2s;
}

.mobileDialog{
  transition: all 0.2s;
}

@media (max-width: 768px) {
  .horizontalDivider{
    margin: -12px 0;
  }

  .helperText{
    margin-top: -38px;
  }
}

@media (min-width: 768px) {
  .dialog, .dialog[data-position='left'], .dialog[data-position='right'] {
    --calendar-dialog-top: var(--cd-top);
    --calendar-dialog-left: var(--cd-left);
    --calendar-dialog-transform: var(--cd-transform);

    position: fixed;
  }
}

@media (max-width: 768px) {
  .mobileDialog, .mobileDialog[data-position='left'], .dialog[data-position='right'] {
    --calendar-dialog-top: var(--cd-top);
    --calendar-dialog-left: var(--cd-left);
    --calendar-dialog-transform: var(--cd-transform);
    --calendar-padding: 32px;
    position: fixed;
    width: calc(100vw - var(--calendar-padding));
    padding: 12px 10px;
    padding-bottom: calc(env(safe-area-inset-bottom) + 24px); /* Add safe area inset for iOS */
    border-radius: 0 0 4px 4px;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 0px 4px 0px #0000001A;
    border-top: none;
    /* Ensure content is fully visible */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 90vh;
    /* Fix for iOS bottom safe area */
    margin-bottom: env(safe-area-inset-bottom);
  }

  .monthClassname{
      --calendar-cell-size: calc((100vw - var(--calendar-padding) - 20px) / 7) !important;
      grid-template-columns: repeat(7, var(--calendar-cell-size)) !important;
      grid-template-rows: 40px repeat(4, var(--calendar-cell-size)) !important;
      gap: 0 !important;
      /* Ensure bottom content is visible */
      padding-bottom: 60px;
  }

  .dayNameContainer{
    --calendar-cell-size: calc((100vw - var(--calendar-padding) - 20px) / 7) !important;
  }

  .mobileSelectContainer[data-focus='true'] {
    border-radius: 8px 8px 0 0 !important;
  }
  
}

