.container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  overflow: auto;
  padding: 0 16px 16px;
  display: grid;
  grid-template: repeat(4, auto) 1fr / 1fr;
  gap: 16px;
  align-content: flex-start;
}

.title{
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-3); 
  align-self: center; 
}

.header {
  margin: 0 -24px;
  padding: 8px 24px 8px 16px;
  display: grid;
  grid-template: 1fr/auto 1fr;
  gap: 8px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.05);
  min-height: 54px;
}

.destinationContainer {
  position: relative;
}

.back {
  width: 28px;
  height: 28px;
  align-self: center;
}

.addGuest {
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-3); 
}

.addRooms{
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-3); 
  border-bottom: 1px solid #E6E6E6;
  margin-left: -12px;
  margin-right: -12px;
  padding: 0 12px 16px;
}

.separator{
  border-bottom: 1px solid #E6E6E6;
  margin-right: -12px;
  margin-left: -12px;
}

.done {
  height: 48px;
  text-transform: capitalize;
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0%;
}

.destination :global .expand-icon {
  top: 50% !important;
}

.destination{
  border: 1px solid #E6E6E6;
  box-shadow: 0px 0px 8px 0px #0000001A;
  padding: 12px ;
  border-radius: 8px;
}

.propertyConfigContainer{
  border: 1px solid #E6E6E6;
  box-shadow: 0px 0px 8px 0px #0000001A;
  padding: 16px 12px;
  border-radius: 8px;
}

.destination :global .input {
  background: none;
  width: -webkit-fill-available;
  min-height: 20px !important;
  padding: 0;
  border: none;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 20px;
  letter-spacing: 0px;
}

.destination :global .lable {
  color: #626366;
  font-weight: 500;
  font-size: 12px !important;
}

.stay{
  min-height: auto;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 0px 8px 0px #0000001A;
  padding: 12px ;
  border-radius: 8px;
}

.stay :global .label{
  color: #626366;
  font-weight: 500;
  font-size: 12px;
}

.stay :global .labeltwo{
  color: #626366;
  font-weight: 500;
  font-size: 12px;
}

.stay :global .value{
  font-size: 14px;
}

.occupancyContainer{
  border: 1px solid #E6E6E6;
  box-shadow: 0px 0px 8px 0px #0000001A;
  padding: 16px 12px ;
  border-radius: 8px;
  display: grid;
  gap: 16px;
}


.propertyConfig{
  max-height: 208px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -12px;
  padding-right: 12px;
}

.propertyConfig::-webkit-scrollbar {
  width: 4px; 
}

.propertyConfig::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 4px; 
}

.propertyConfig::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.propertyConfig::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}