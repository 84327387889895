.container {
  position: relative;
  overflow: hidden;
  color: white;
  display: grid;
  /* grid-template: 1fr 1fr/1fr 1fr 1fr; */
  grid-template: auto/ 560px 1fr;
  gap: 12px;
}

.largeImage {
  cursor: pointer;
}

.largeImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.smallImages {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.smallImage {
  cursor: pointer;
  position: relative;
}

.smallImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
}

.overlayText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: rgba(0, 0, 0, 0.6); */
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: 0%;
}

.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.actions {
  grid-row: 2;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.banner :global .image {
  object-fit: cover;
}

.button {
  border-radius: 4px;
  backdrop-filter: blur(50px);
  border: 1px solid #1f2c38;
  background-color: #ffffff;
  color: #1f2c38;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-transform: capitalize;
  justify-self: flex-end;
  outline: none;
  /* font-family: inherit; */
  font-family: var(--font-family);
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 6px;
  white-space: pre;
}

.button:visited {
  color: #1f2c38;
}

.ispravaBuilt {
  width: 80px;
  height: 80px;
  z-index: 0;
  grid-column: 1;
  grid-row: 2 / span 2;
}

.partnerHome {
  align-self: flex-end;
  justify-self: flex-start;
  grid-column: 1 / span 3;
  grid-row: 2 / span 2;
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 8px;
  padding: 6px 10px;
  border-radius: 35px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(27.1828px);
  color: white;
  align-items: center;
  font-size: 11px;
}

.partnerHomeIcon {
  background: white;
  padding: 8px;
  border-radius: 50%;
}

.title {
  font-size: 36px;
  font-weight: 700;
  grid-column: 2 / span 3;
  align-self: flex-end;
  justify-self: flex-end;
  z-index: 0;
  grid-row: 2;
}

.subtitle {
  font-size: 16px;
  font-weight: 500;
  z-index: 0;
  grid-column: 2 / span 3;
  grid-row: 3;
  align-self: flex-start;
  justify-self: flex-end;
  text-align: right;
}

.scroll {
  position: relative;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  grid-row: 3;
  grid-column: 1 / span 4;
  align-self: flex-end;
  margin-bottom: -50px;
}

.carouselContainer {
  display: grid;
  grid-template: auto/1fr;
  grid-row: span 5;
  position: relative;
}

.carousel {
  scroll-snap-type: x mandatory;
  grid-column: 1;
  grid-row: 1;
  height: 37vh;
}

.imageItem {
  scroll-snap-align: start;
  overflow: hidden;
}

.imgSelect {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
  cursor: pointer;
}

.imgSelect img {
  object-fit: cover;
}

.scrollCrumb {
  grid-column: 1;
  grid-row: 1;
  align-self: flex-end;
  justify-self: center;
}

@media (max-width: 768px) {
  .container {
    margin-left: -16px;
    margin-right: -16px;
    display: block;
  }

  .smallImage{
    position: relative;
    overflow: hidden;
    border: 1.18px solid rgba(204, 204, 204, 1);
    height: 40px;
    width: 38px;
    border-radius: 2px;
  }

  .smallImage:first-child {
    z-index: 2;
  }

  .smallImage:nth-child(2) {
    position: absolute;
    left: 5px;
    top: 0;
    transform: rotate(10deg);
    z-index: 1;
  }

  .smallImage img {
    border-radius: 2px;
  }


  .overlayText{
    font-weight: 400;
    font-size: 10px;
    line-height: 8.8px;
    text-align: center;
    text-decoration: none;
  }

  .overlay{
    border-radius: 2px;
    position: absolute;
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .moreImageContainer{
    z-index: 0;
    position: relative;
    display: flex;
    gap: 10px;
    margin: 16px;
    grid-column: 1;
    grid-row: 1;
    place-self: end;
  }

  .videoActions {
    grid-row: 2;
    display: flex;
    align-items: flex-end;
    gap: 16px;
    justify-content: center;
  }

  .shareContainer{
    margin: 16px;
    grid-column: 1;
    grid-row: 1;
    width: fit-content;
    height: fit-content;
    justify-self: self-end;
  }

  .shareButton{
    border-radius: 50%;
    background: rgba(255, 255, 255, 1);
    border: none;
    justify-content: center;
    padding: 8px;
    height: 32px;
    width: 32px;
  }

  .shareButton img{
    padding-bottom:0 ;
    width: 16px;
    height: 14px;
  }

  .backButton{
    margin: 16px;
    grid-column: 1;
    grid-row: 1;
    width: fit-content;
    height: fit-content;
  }

  .backIcon{
    width: 32px;
  }

  .videoActions .button {
    margin-right: 0;
    width: 100px;
  }

  .imageItem{
    width: calc(100vw + 5px); 
  }

  .backdrop {
    display: none;
  }

  .button {
    width: 104px;
    height: 42px;
    font-size: 10px;
    padding: 10px 16px;
    margin-right: 16px;
  }

  .button:last-child {
    margin-right: 0px;
  }

  .ispravaBuilt {
    display: none;
  }

  .title {
    display: none;
  }

  .subtitle {
    display: none;
  }

  div.scroll {
    display: none;
  }

  .partnerHome {
    font-size: 12px;
  }

  .partnerHomeIcon {
    margin: -8px 0 -8px -12px;
    height: 35px;
    width: 35px;
  }

  .smallImages{
    display: none;
  }

  .largeImage{
    display: none;
  }
}

@media (min-width: 768px) {
  .container {
    padding: 0px 0px 24px 0px;
    margin-bottom: -32px;
    /* gap: 8px 0; */
  }

  .subtitle {
    max-width: 600px;
  }

  .carouselContainer{
    display: none;
  }

  .overlayText{
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0%;
    text-align: center;
    vertical-align: middle;
    width: max-content;
  }

  .backdrop {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background: linear-gradient(
      142deg,
      rgba(0, 0, 0, 0) 55%,
      rgba(0, 0, 0, 0.42) 77%
    );
    cursor: pointer;
  }

  .button {
    height: 42px;
    font-size: 14px;
    margin-left: 16px;
  }

  .actions {
    justify-content: flex-end;
    margin: 0 -5px;
    grid-column: 3;
  }

  .ispravaBuilt {
    justify-self: flex-start;
    align-self: flex-end;
  }


  .smallImage{
    height: 250px;
  }

  .largeImage{
    height: 512px;
  }

  .partnerHome {
    font-size: 14px;
  }

  .partnerHomeIcon {
    margin: -10px 0 -10px -10px;
    height: 45px;
    width: 45px;
  }
}

/* @media (min-width: 768px) and (max-width: 1024px) {
  .container {
    margin-left: calc(-1 * (100vw - 712px) / 2);
    margin-right: calc(-1 * (100vw - 712px) / 2);
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .container {
    margin-left: calc(-1 * (100vw - 960px) / 2);
    margin-right: calc(-1 * (100vw - 960px) / 2);
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .container {
    margin-left: calc(-1 * (100vw - 1100px) / 2);
    margin-right: calc(-1 * (100vw - 1100px) / 2);
  }
}

@media (min-width: 1440px) {
  .container {
    margin-left: calc(-1 * (100vw - 1200px) / 2);
    margin-right: calc(-1 * (100vw - 1200px) / 2);
  }
} */
