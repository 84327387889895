.container {
  background-color: rgba(216, 112, 54, 0.12);
  line-height: 24px;
  align-content: center;
  border-radius: 6px;
}

.customcheckbox {
  display: grid;
  grid-template: auto / auto 1fr;
  /* align-items: center; */
  cursor: pointer;
}

.hiddencheckbox {
  display: none;
}

.boxbackground .checkboxstyler {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(216, 112, 54, 0.7);
  background: white;
  border-radius: 2px;
  transition: 0.3s, border-color 0.3s;
  align-self: center;
  margin: 10px;
}

.priceboxbackground .checkboxstyler {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(216, 112, 54, 0.7);
  background: white;
  border-radius: 2px;
  transition: 0.3s, border-color 0.3s;
  align-self: center;
  margin: 8px;
}

.checkboxstyler::after {
  content: "";
  background: rgba(216, 112, 54, 0.9);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease-in-out;
}

.hiddencheckbox:checked + .checkboxstyler {
  background: rgba(216, 112, 54, 0.9);
  background-image: url(../../../../../assets/icons/check.svg);
  border-color: rgba(216, 112, 54, 0.9);
  background-repeat: no-repeat; 
  background-position: center;  
  background-size: auto; 
}

.hiddencheckbox:checked + .checkboxstyler::after {
  transform: translate(-50%, -50%) scale(1);
}

.checkboxcontent{
  align-content: center;
  display: grid;
}

.boxbackground {
  display: flex;
  background-color: rgba(216, 112, 54, 0.09);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-right: 8px;
  place-content: center;
}

.priceboxbackground{
  display: flex;
  background-color: rgba(216, 112, 54, 0.09);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  margin-right: 4px;
}

.knowMore{
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  font-size:12px;
  line-height: 24px;
  color: #562D16;
  text-align: end;
  grid-column: 2;
  grid-row: 1;
}

.hiddenKnowMore{
  display: none;
}

@media (max-width: 768px) {
.knowMore{
  grid-column: 2;
  grid-row: 1;
  line-height: 16px;
  justify-self: end;
}

.customcheckbox {
  align-items: center;
}

.checkboxcontent{
  display: grid;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
}
}
