.container {
  display: grid;
  gap: 16px;
  color: white;
  transition: all ease-in-out 0.3s;
  grid-column: span 2;
}

.background {
  object-fit: cover;
  grid-column: 1;
  grid-row: 1 / span 3;
  height: 100%;
  width: 100%;
}

.overlay {
  grid-column: 1;
  grid-row: 1 / span 3;
  background: rgba(0, 0, 0, 0.73);
  height: 100%;
}

.title {
  grid-column: 1;
  grid-row: 2;
  font-weight: 700;
  margin: 0;
  align-self: flex-end;
}

.bannerTitle{
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0%;
    text-align: center;
    color: rgba(255, 255, 255, 1);

    
}

.subtitle {
    grid-column: 1;
    grid-row: 3;
    margin: 0;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.015em;
    text-align: center;
  }

  .header{
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.5em;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

@media (max-width: 768px) {
  .container {
    height: 81px;
  }

  /* .container>*:not(img) {
      padding: 0 16px;
    */

  .container > *:not(img) {
    padding: 8px 16px;
  }

  .title {
    display: none;
  }

  .subtitle {
    display: none;
  }
}

@media (min-width: 768px) {
  .container {
    height: 192px;
  }

  .container > *:not(img) {
    padding: 18px 20px;
  }

  .title {
    font-size: 26px;
    margin-bottom: -10px;
  }

  .subtitle {
    font-size: 16px;
    margin-bottom: 52px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container > *:not(img):not(.webOffer) {
    padding: 0 calc((100% - 712px) / 2);
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .container > *:not(img):not(.webOffer) {
    padding: 0 calc((100% - 960px) / 2);
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .container > *:not(img):not(.webOffer) {
    padding: 0 calc((100% - 1100px) / 2);
  }
}

@media (min-width: 1440px) {
  .container > *:not(img):not(.webOffer) {
    padding: 0 calc((100% - 1200px) / 2);
  }
}
