
  .placeholder {
    margin-right: 100px;
  }
  
  .item {
    width: 350px;
  }
  
  .item:nth-child(2) {
    margin-left: 2px;
  }
  
  .property {
    padding: 0 16px 16px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 8px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.10);
    background: white;
    display: grid;
    grid-template: auto auto 1fr auto / auto 1fr;
    gap: 16px;
    height: 100%;
  }
  
  .strike {
    text-decoration: line-through;
    margin-left: 4px;
  }
  
  a.property {
    color: #626366;
  }
  
  .thumbnail {
    cursor: pointer;
    grid-column: span 2;
    margin: 0 -16px 0;
    height: 200px;
    width: calc(100% + 32px);
    border-radius: 6px 6px 0 0;
    object-position: center;
  }
  
  .thumbnail :global .image {
    object-fit: cover;
  }
  
  .title {
    font-size: 20px;
    font-weight: 700;
    margin-top: 8px;
    color: var(--secondary-contrast-text);
    overflow: hidden;
  }
  
  .ratings{
    grid-row: 2;
    align-content: end;
    grid-column: 2;
    justify-self: end;
    float: inline-end;
  }
  
  .logoratingcounts{
    grid-row: 2 / span 2;
    margin-top: 37px;
    grid-column: 2;
    justify-self: end;
    float: inline-end;
  }
  
  .subtitle {
    grid-column: 1;
    font-weight: 500;
    font-size: 14px;
    margin-top: -16px;
    color: var(--secondary-contrast-text-2);
  }
  
  .list {
    grid-column: 1 / span 2;
    font-size: 14px;
    color: var(--secondary-contrast-text-2);
  }
  
  .rate {
    font-size: 16px;
    font-weight: 700;
    color: var(--secondary-contrast-text);
  }

  .startFrom{
    color: var(--secondary-contrast-text-2);
    font-size: 12px;
    line-height: 16px;
  }

  .exc{
    color: var(--secondary-contrast-text-2);
    font-size: 14px;
    line-height: 20px;
  }
  
  .ispravaIcon {
    position: absolute;
    width: 52px;
    right: 8px;
    top: 200px;
    transform: translateY(-50%);
  }
  
  .specContainer {
    padding-bottom: 16px;
    border-bottom: 1px solid #0000001a;
    color: #333;
    display: flex;
    flex-direction: row;
    grid-column: 1 / span 2;
    grid-row: 4;
    font-size: 12px;
  }

  .spec {
    padding: 0 8px;
    border-right: 1px solid rgba(53,58,60,0.3);
  }
  
  .spec:first-child {
    padding-left: 0;
  }
  
  .spec:last-child {
    padding-right: 0;
    border-right: none;
  }
  
  @media (max-width: 768px) {
  
    .property{
      grid-template: 200px auto 1fr auto / 170px 1fr;
      padding: 0 8px 16px;
    }

    .thumbnail{
        margin: 0 -8px 0;
        width: calc(100% + 16px);
    }

    .title{
        grid-column: 1 / span 2;
        grid-row: 2;
        margin-top: 0;
    }
 
    .logoratingcounts{
      margin-top: 27px;
    }
  
    .list {
      grid-column: span 2;
      white-space: pre;
      font-size: 12px;
    }
  
    .scroller {
      display: none;
    }
    
    .item {
      width: 280px;
    }
  
    .price {
      font-size: 14px;
    }
    
  }
  
  @media (min-width: 768px) {

    .thumbnail {
        height: 260px;
      }
   
  }
  