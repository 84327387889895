.container {
  display: grid;
  padding: 16px;
  background: white;
}

.header {
  color: var(--secondary-contrast-text);
  font-weight: bold;
  letter-spacing: 0.5px;
}

.thumbnail {
  border-radius: 4px;
  object-fit: contain;
}

.title {
  color: var(--secondary-contrast-text);
  font-weight: bold;
  letter-spacing: 0.5px;
}

.subtitle {
  color: var(--secondary-contrast-text);
  letter-spacing: 0.5px;
}

.titlePartial {
  display: grid;
  gap: 4px;
}

.occupancy {
  display: inline-flex;
  align-items: center;
  color: var(--secondary-contrast-text);
  /* width: 75px; */
}

.occupancyAdults{
  display: inline-flex;
  align-items: center;
  color: var(--secondary-contrast-text);
  width: 75px;
}

.occupancy b {
  margin-right: 4px;
}

.occupancyAdults b {
  margin-right: 4px;
}

.occupancy:last-child {
  padding-left: 24px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.datePartial {
  display: grid;
}

.changeDate,
.changeSelection {
  text-transform: uppercase;
  text-decoration: underline;
  letter-spacing: 0.4px;
  cursor: pointer;
  color: var(--secondary-contrast-text);
}

.changeDate{
  text-transform: capitalize;
}

.changeSelection {
  margin-top: 2px;
}

.configRooms {
  font-size: 12px;
  line-height: 15px;
  color: var(--secondary-contrast-text);
  padding: 8px 0;
  margin-top: 16px;
}

.configsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: auto;
}

.configRoom {
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid rgba(53, 58, 60, 0.3);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: var(--secondary-contrast-text);
  white-space: nowrap;
}

.configRoom:last-child {
  border-right: none;
}

.mealContainer{
  display: flex;
  grid-column: 1 / span 4;
  border: 1px solid rgba(39, 174, 96, 0.1);
  background: rgba(39, 174, 96, 0.05);
  color: rgba(39, 174, 96, 1);
  padding: 8px 16px;
  border-radius: 4px;
  margin-top: 20px;
}

.mealIcon{
  margin-right: 8px;
}

.mealPlan{
  font-weight: 600;
  font-size: 14px;
  line-height:24px;
}
@media (max-width: 768px) {
  .desktop {
    display: none;
  }

  .container {
    grid-template: auto / auto 1fr;
  }

  .header {
    margin-bottom: 12px;
    font-size: 14px;
    grid-column: span 4;
    line-height: 16px;
  }

  .thumbnail {
    align-self: center;
    margin-right: 8px;
    width: 151px;
    border-radius: 4px;
    grid-row: 2 / span 2;
  }

  .title {
    font-size: 12px;
    line-height: 14px;
  }

  .subtitle {
    font-size: 10px;
    margin-bottom: 10px;
  }

  .titlePartial {
    grid-template: auto/1fr;
    grid-column: span 3;
  }

  .occupancy {
    font-size: 12px;
    line-height: 16px;
  }

  .occupancyAdults{
    font-size: 12px;
    line-height: 16px;
  }

  .datePartial {
    grid-template: auto/1fr auto;
    margin-top: 8px;
    grid-column: span 3;
    gap: 2px;
  }

  .changeDate,
  .changeSelection {
    font-size: 8px;
    align-self: flex-start;
    padding-top: 2px;
  }

  .changeDate {
    grid-row: 2;
    font-size: 10px;
    line-height: 12px;
  }

  .configsContainer {
    grid-row: 5;
    grid-column: 1/3;
  }

  .configRooms {
    padding: 8px 0;
  }

  .changeSelection {
    grid-row: 6;
    grid-column: 2; 
    justify-self: flex-end;
    padding: 6px 0;
    font-size: 10px;
  }

  .mealContainer{
    padding: 8px;
    margin-top: 16px;
  }

  .mealIcon{
    width: 16px;
  }

  .mealPlan{
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}

@media (min-width: 768px) {
  .mobile {
    display: none;
  }

  .container {
    grid-template: auto/auto auto 1fr;
    border-radius: 4px;
    border: 1px solid rgba(151, 151, 151, 0.25);
  }

  .header {
    font-size: 18px;
    padding-bottom: 12px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    grid-column: span 4;
    grid-row: 1;
  }

  .thumbnail {
    height: 100px;
    margin-right: 14px;
  }

  .title {
    font-size: 14px;
  }

  .subtitle {
    font-size: 12px;
  }

  .titlePartial {
    grid-template: auto 1fr auto/1fr;
  }

  .occupancy {
    font-size: 12px;
  }

  .occupancyAdults{
    font-size: 12px;
  }

  .datePartial {
    grid-template: auto 1fr auto/1fr 1fr;
    gap: 4px;
    margin-left: 32px;
    padding-left: 32px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    /* width: 250px; */
  }

  .configRooms {
    grid-column: span 3;
    grid-row: 3;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .configsContainer {
    grid-column: span 3;
    grid-row: 4;
  }

  .configRoom:first-child {
    padding-left: 0;
  }

  .configRoom:last-child {
    padding-right: 0;
  }

  .changeDate,
  .changeSelection {
    padding: 6px;
    font-size: 10px;
    justify-self: flex-end;
  }

  .changeDate {
    grid-column: 2;
  }

  .changeSelection {
    grid-column: 3;
  }

  .divider {
    background: rgba(213, 215, 220, 0.4);
    margin: 8px 0;
    height: 1px;
    width: 100%;
  }
}