.container {
  position: relative;
  display: grid;
  grid-template: auto/auto;
}

.introAnchor {
  position: absolute;
  top: -90px;
  left: 0;
  height: 0;
  width: 0;
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 4px;
}

.background :global .image {
  object-fit: cover;
}

.title {
  font-weight: bold;
}

.subtitle {
  font-weight: 500;
}

@media (max-width: 768px) {
  .container {
    gap: 10px;
  }

  .introAnchor {
    display: none;
  }

  .background {
    display: none;
  }

  .title {
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #121212;
  }

  .subtitle {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 18px;
    color: var(--secondary-contrast-text);
  }
}

@media (min-width: 768px) {
  .container {
    gap: 16px;
    align-content: center;
    border-radius: 4px;
    height: 450px;
  }

  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 38.73px;
    text-align: center;
    color: white;
  }

  .subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.005em;
    text-align: center;
    color: white;
    max-width: 832px;
    justify-self: center;
  }
}