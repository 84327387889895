.container {
  display: grid;
  background-image: url("../../assets/refer-n-earn/points-bg.png");
  align-items: center;
}

.points {
  font-weight: 700;
  background: linear-gradient(90deg, #d39f68 0%, #e5d7aa 48.44%, #d39f68 98.44%),
    #484848;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.criteriaTitle {
  font-weight: 600;
  color: #ffffff;
}

.criteriaSubtitle {
  font-weight: 400;
  color: #ffffffcc;
}

.buttonContainer {
  cursor: pointer;
}

.redeemButton {
  background: linear-gradient(
    90deg,
    #d39f68 0%,
    #e5d7aa 48.44%,
    #d39f68 98.44%
  );
  border-radius: 4px;
  font-weight: 600;
}

.referHistory {
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.8);
}

.referHistory:visited {
  color: white;
}

@media (max-width: 768px) {
  .container {
    padding: 16px;
    border-radius: 4px;
    gap: 8px;
    /* position: absolute;
    left: 0; */
    /* margin-top: -14px; */
  }

  .logo {
    grid-row: 1 / span 2;
    width: 32px;
    height: 32px;
  }

  .points {
    font-size: 14px;
    line-height: 20px;
  }

  .points,
  .criteria {
    grid-column: 2;
  }

  .criteria {
    line-height: 14px;
    margin-top: -6px;
  }

  .criteriaTitle {
    font-size: 10px;
    display: inline;
  }

  .criteriaSubtitle {
    font-size: 12px;
    display: inline;
  }

  .buttonContainer {
    grid-column: span 2;
    display: flex;
    /* flex-direction: column; */
    text-align: center;
    gap: 10px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
  }

  .redeemButton {
    width: 60%;
    padding: 12px;
    display: block;
    text-align: center;
  }

}

@media (min-width: 768px) {
  .container {
    grid-template: auto/auto 1fr auto;
    padding: 24px;
    border-radius: 8px;
    gap: 0 16px;
  }

  .logo {
    grid-row: span 2;
  }

  .points {
    font-size: 20px;
    line-height: 32px;
  }

  .criteria {
    grid-column: 2;
    font-size: 16px;
    line-height: 20px;
  }

  .buttonContainer {
    display: flex;
    gap: 32px;
    align-items: center;
    grid-row: 1 / span 2;
    grid-column: 3;
  }

  .redeemButton {
    font-size: 16px;
    line-height: 19px;
    padding: 18px 28px;
  }
}
