.bannerContainer {
  position: relative;
  z-index: 0;
  background: #262626;
}

.container {
  color: rgba(255, 255, 255, 0.9);
  display: grid;
  grid-template: auto/1fr;
}

.banner {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
}

.banner img {
  object-fit: fill;
}

.content {
  align-self: center;
}

@media (max-width: 768px) {
  .container {
    min-height: calc(45vh - var(--navbar-height));
  }
}

@media (min-width: 768px) {
  .container {
    min-height: calc(60vh - var(--navbar-height));
  }

  .banner {
    height: 390px;
  }
}
