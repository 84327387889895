.container {
  display: grid;
  grid-template: auto/2fr 3fr;
  gap: 16px;
  background: var(--root-background);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.description {
  grid-column: span 2;
  color: var(--secondary-contrast-text);
}

.yes {
  border: none;
  width: 100%;
  border-radius: 4px;
  background: var(--primary-main);
  color: var(--primary-contrast-text);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
}

.no {
  border: none;
  background: rgba(31, 44, 56, 0.1);
  color: var(--secondary-contrast-text);
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
}

@media (max-width: 768px) {
  .container {
    padding: 12px;
    border-radius: 12px;
    width: calc(100% - 32px);
  }

  .yes, .no {
    font-size: 14px;
    height: 48px;
  }
}

@media (min-width: 768px) {
  .container {
    padding: 16px;
    border-radius: 8px;
    max-width: 400px;
  }

  .yes, .no {
    font-size: 16px;
    height: 56px;
  }
}
