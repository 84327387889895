.container {
  display: grid;
  row-gap: 8px;
  padding: 16px;
}

.columnContainer {
  display: grid;
  grid-template: auto / 1fr 23% auto;
  margin-bottom: 8px;
}

.title {
  font-weight: 600;
  color: var(--secondary-contrast-text-3);
}

.icon {
  width: 40px;
  object-fit: contain;
}

.generic {
  width: 20px;
  object-fit: contain;
}

.viewAll {
  text-decoration: underline;
  color: #1f2c38;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  margin: -8px 0 8px auto;
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-self: flex-start;
  border-radius: 6px;
  margin: 0;
  /* z-index: 1; */
}

.viewAll.expanded {
  display: none;
}

.viewMore {
  display: flex;
}

.viewLess {
  display: flex;
  margin-top: 8px;
}

/* .viewAllIcon {
    margin-left: 4px;
  } */

/* .collapse {
  grid-column: span 3;
} */

.collapseInner {
  display: grid;
  /* grid-template: auto / 164px 1fr 1fr; */
  row-gap: 8px;
}

.columnTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.columnVegNonVegTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.nonVegTitle {
  font-weight: 600;
  color: #c21717;
  font-size: 14px;
  line-height: 16px;
  padding-left: 4px;
  margin: 0;
}

.vegTitle {
  font-weight: 600;
  margin: 0;
  color: #2da40e;
  font-size: 14px;
  line-height: 16px;
  padding-left: 4px;
}

.columnHeader span {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: var(--secondary-contrast-text-2);
}

.columnHeader h2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}

.menuIcon {
  width: 12px;
  height: 12px;
}


@media (min-width: 1024px) and (max-width: 1300px) {
  .container {
    padding: 16px 12px;
    column-gap: 8px;
  }
}

@media (min-width: 800px) and (max-width: 1024px) {
  .container {
    padding: 16px 12px;
    column-gap: 8px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 16px 12px;
    column-gap: 8px;
  }

  .columnContainer {
    display: grid;
    grid-template: auto / auto 20% 30%;
    row-gap: 16px;
  }

  .columnVegNonVegTitle {
    grid-column: 1 / span 3;
  }

  .title {
    font-size: 16px;
    line-height: 19px;
    grid-column: span 2;
    gap: 18px;
    margin-bottom: 16px;
  }

  .icon {
    display: none;
  }

  .viewAll {
    display: block;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    z-index: 0;
  }

  .collapseInner {
    display: grid;
    column-gap: 9px;
  }

  .columnHeader h2 {
    text-align: end;
  }
}

@media (min-width: 768px) {
  /* .container {
      gap: 24px;
    } */

  .title {
    font-size: 32px;
    grid-column: span 2;
  }

  .viewAll {
    grid-column: 1;
    justify-self: flex-start;
  }
}