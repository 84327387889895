.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
}

.infinityLogo {
  margin: 0 auto;
}

.title,
.subtitle {
  margin: 0;
  text-align: center;
}

.title {
  color: rgba(255, 255, 255, 0.8);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 100% */
  letter-spacing: 0.15px;
  margin-bottom: -12px;
}

.subtitle {
  color: rgba(255, 255, 255, 0.9);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 166.667% */
  letter-spacing: 0.5px;
}

.loginContainer {
  background: #151515;
  box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  display: grid;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  font-style: normal;
  line-height: 28px; /* 140% */
}

.login {
  color: #ffffff;
  height: 44px;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .container {
    padding-top: 16px;
    gap: 12px;
  }

  .loginContainer {
    padding: 16px;
    grid-template: auto/auto 1fr;
    gap: 16px;
    font-size: 14px;
    margin-top: 8px;
  }

  .title {
    text-align: left;
    color: rgba(255, 255, 255, 0.8);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1px;
  }

  .subtitle {
    font-size: 12px;
    text-align: left;
    color: rgba(255, 255, 255, 0.9);
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }

  .loginContainer {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.15px;
  }

  .infinityLogo {
    width: 79.73px;
    height: 26px;
  }

  .login {
    grid-column: span 2;
  }
}

@media (min-width: 768px) {
  .container {
    padding-top: 32px;
  }

  .loginContainer {
    padding: 24px;
    grid-template: auto/auto 1fr auto;
    gap: 16px;
    font-size: 20px;
    margin-top: 24px;
  }

  .login {
    width: 280px;
  }

  .title {
    font-size: 28px;
    margin-bottom: -16px;
  }

  .subtitle {
    font-size: 20px;
  }
}
