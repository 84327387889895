.container {
  display: grid;
  grid-template: auto/1fr;
}

.title {
  color: #222222;
  font-weight: bold;
}

.subtitle {
  color: #707070;
  font-weight: 500;
}

.staticCollection{
  margin-top: -30px;
}

.moments {
  display: grid;
  gap: 16px;
}

.eventMoments{
  display: grid;
}

.scroller{
  grid-column: 2;
  margin-top: -75px;
}

.section{
  grid-column: span 2;
  grid-template: auto / 1fr;
}

.momentContainer{
  text-align: center;
  margin-right: 24px;
  border-radius: 4px;
}

.momentThumbnail {
  width: 586px;
  height: 440px;
  object-fit: cover;
  border-radius: 4px;
}

.partial1{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.partial1Title{
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: var(--secondary-contrast-text);
}

@media (max-width: 768px) {
  .container {
    gap: 10px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
  }

  .momentThumbnail{
    width: 303px;
    height: 210px;
    border-radius: 4px;
  }

  .section{
    margin-right: -16px;
  }

  .scroller{
    display: none;
  }
  
  .partial1Title{
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;  
  }

  .staticCollection{
    margin-top: -15px;
  }

  .eventMoments{
    margin-top: 6px;
  }

  .subtitle {
     font-size: 14px;
     font-weight: 400;
     line-height: 20px;     
     text-align: left;
  }

  .moments {
    margin-top: 16px;
  }
}

@media (min-width: 768px) {
  .container {
    gap: 32px;
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .title {    
    font-size: 32px;
    font-weight: 700;
    line-height: 38.73px;
    text-align: left;
  }

  .subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    margin-top: -24px;
    padding-right: 122px;
  }

  .section{
    margin-top: 8px;
    margin-right: calc(-1*(100vw - 1100px) / 2);
  }

  .moments {
    grid-template: auto/repeat(auto-fill, minmax(380px, 1fr));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .section{
    margin-right: calc(-1 * (100vw - 712px) / 2);
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .section{
    margin-right: calc(-1 * (100vw - 960px) / 2);
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .section{
    margin-right: calc(-1 * (100vw - 1100px) / 2);
  }
}

@media (min-width: 1440px) {
  .section{
    margin-right: calc(-1 * (100vw - 1200px) / 2);
  }
}
