.section {
  position: relative;
  overflow: hidden;
}

.carousel {
  gap: 16px;
  padding: 16px 0;
}

.placeholder {
  margin-right: 100px;
}

.item {
  width: 350px;
}

.item:nth-child(2) {
  margin-left: 2px;
}

.property {
  position: relative;
  padding: 0 16px 16px;
  border-radius: 6px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.10);
  background: white;
  display: grid;
  grid-template: 200px auto 1fr auto / auto 1fr;
  gap: 2px;
  height: 100%;
  border: 1px solid #D7D7D7;
}

.strike {
  text-decoration: line-through;
  margin-left: 4px;
}

a.property {
  color: #626366;
}

.thumbnail {
  grid-column: span 2;
  margin: 0 -16px 0;
  height: 200px;
  width: calc(100% + 32px);
  border-radius: 6px 6px 0 0;
  object-position: center;
}

.thumbnail :global .image {
  object-fit: cover;
}

.title {
  color: var(--secondary-contrast-text);
}

.ratings{
  grid-row: 2;
  grid-column: 2;
  justify-self: end;
  float: inline-end;
}

/* .logoratingcounts{
  grid-row: 2 / span 2;
  margin-top: 37px;
  grid-column: 2;
  justify-self: end;
  float: inline-end;
} */

.subtitle {
  grid-column: 1;
  font-weight: 400;
  font-size: 14px;
  color: var(--secondary-contrast-text-2);
}

.list {
  grid-column: 1 / span 2;
  font-size: 14px;
  color: var(--secondary-contrast-text-2);
  margin-top: 20px;
  /* padding-top: 12px; */
  position: relative;
}

.rate {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--secondary-contrast-text);
}

.ispravaIcon {
  position: absolute;
  width: 52px;
  right: 8px;
  top: 200px;
  transform: translateY(-50%);
}

.scroller {
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
}

@media (max-width: 768px) {

  .property{
    grid-template: 200px auto auto auto / 170px 1fr;
  }
  .carousel {
    margin: 0px -16px -12px -16px;
  }

  .logoratingcounts{
    margin-top: 27px;
  }

  .list {
    grid-column: span 2;
    white-space: pre;
    font-size: 12px;
    margin-top: 18px;
  }

  .scroller {
    display: none;
  }
  
  .item {
    width: 280px;
  }

  .price {
    font-size: 14px;
  }

  .thumbnail {
    max-width: 280px;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 8px;
  }

  .ratings{
    margin-top: 12px;
  }

  .rate {
    font-size: 14px;
    text-transform: capitalize;
  }
  
}

@media (min-width: 768px) {
  .carousel {
    margin: 0px -18px -12px -16px;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    margin-top: 16px;
  }

  .ratings{
    margin-top: 20px;
  }

}
