.container {
  display: grid;
  gap: 32px;
  height: 80vh;
}

.background {
  grid-column: 1;
}

.background :global .image {
  object-fit: cover;
}

.backdrop {
  grid-column: 1;
}

.title {
  grid-row: 1;
  grid-column: 1;
}

.contactUs {
  grid-row: 2;
  grid-column: 1;
  justify-self: flex-start;
  font-weight: 700;
  width: 180px;
  height: 54px;
}

.scrollDown {
  grid-row: 3;
  grid-column: 1;
  align-self: flex-end;
  position: relative;
  bottom: 24px;
  left: 50%;
}

@media (max-width: 768px) {
  .container {
    grid-template: 1fr/1fr;
  }

  .background {
    grid-row: 1;
  }

  .backdrop {
    background: linear-gradient(0deg, transparent 0%, #1c252f 100%);
    grid-row: 1;
  }

  .title {
    align-self: center;
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
  }

  .contactUs {
    display: none;
  }

  .scrollDown {
    display: none;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: 50% auto 1fr/1fr;
  }

  .background {
    grid-row: 1/span 3;
  }

  .backdrop {
    background: linear-gradient(322.67deg, transparent 0%, #1c252f 100%);
    grid-row: 1/span 3;
  }

  .title {
    align-self: flex-end;
    white-space: pre;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.4px;
    line-height: 46px;
  }
}