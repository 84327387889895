.container {
  gap: 32px;
}

.title {
  line-height: 39px;
  letter-spacing: 0.005em;
}

.experiences {
  display: grid;
}

.icon {
  max-width: 80px;
  height: 80px;
}

.content {
  max-width: 330px;
}

.experience {
  color: var(--secondary-contrast-text);  
  font-weight: 500;
  font-size: 18px;
}

.experience {
  display: flex;
  align-items: center;
  gap: 24px;
}

.explore, .explore:visited {
  color: var(--primary-main);
}

.explore {
  justify-self: center;
  font-weight: 700;
  font-size: 16px;
  outline: none;
  border: 1px solid #b83737;
  background: transparent;
  border-radius: 4px;
  text-transform: capitalize;
  width: 300px;
  height: 60px;
  padding: 24px;
  box-shadow: none;
  margin-top: 16px;
}

@media (max-width: 768px) {
  .container {
    gap: 16px;
  }

  .title {
    line-height: 24px;
  }

  .icon {
    max-width: 40px;
    height: 40px;
  }

  .experiences {
    gap: 24px;
  }

  .experience {
    font-size: 12px;
    gap: 8px;
  }

  .explore {
    display: none;
  }
}

@media (min-width: 768px) {
  .experiences {
    grid-template: auto/repeat(2, 1fr);
  }

  .experience:nth-child(2n+1) {
    border-right: 1px solid #DDDDDD66;
    padding-right: 16px;
  }
  
  .experience:nth-child(3), .experience:nth-child(4){
    border-top: 1px solid #DDDDDD66;
    padding-top: 32px;
  }
  
  .experience:nth-child(2), .experience:nth-child(4) {
    padding-left: 32px;
  }
  
  .experience:nth-child(1), .experience:nth-child(2) {
    padding-bottom: 32px;
  }
}

