.container {
  background: white;
  /* box-shadow: 0 -1px 5px 1px rgba(0, 0, 0, 0.08); */
  font-size: 8px;
  line-height: 12px;
  font-weight: 400;
  color: var(--secondary-contrast-text-2);
  padding: 8px 14px;
  display: grid;
  grid-template: auto/1fr auto;
}

.soldOutContainer{
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0%;
  color: #D87036;
  padding: 8px;
  background: #D870361A;
  border-radius: 4px;
  text-align: center;
  text-transform: uppercase;
  margin-top: -4px;
  margin-bottom: 4px;
  height: fit-content;
  grid-column: 2;
  grid-row: 1;
}

.price {
  display: grid;
  grid-column: 1;
  grid-row: 1 / span 2;
  height: fit-content;
  align-self: anchor-center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;
  color: #292D32;
}

.startsFrom{
  grid-row: 1;
  grid-column: 1;
}

.starts{
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-2);
}

.preBookMeals{
  background-color: rgba(250, 238, 231, 1);
  height: 48px;
  font-weight: 500;
  font-size: 12px;
  Line-height:16px;
  color: rgba(130, 67, 32, 1);
  padding: 8px 16px;
  border-radius: 0;
}

.strike {
  text-decoration: line-through;
  font-size: 10px;
}

.discountedContainer{
  display: flex;
  gap: 2px;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-2);
}

.actualPricingContainer{
  display: flex;
  gap: 2px;
  flex-direction: row;
  align-items: baseline;
}

.rate{
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  color: var(--secondary-contrast-text-3);
}

.subscript{
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0%;
  text-transform: capitalize;
  color: var(--secondary-contrast-text-2);
}

.info {
  margin-right: 6px;
  cursor: pointer;
}

.subtitle {
  font-size: 10px;
  grid-column: 1;
  line-height: 14px;
  margin-top: 7px;
}

.edit {
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin-left: 6px;
}

.checkout {
  font-size: 14px;
  height: 40px;
  border-radius: 6px;
  align-self: center;
  grid-row: 2;
  grid-column: 2;
  padding: 10px;
  /* width: 138px; */
  line-height: 1.15;
  text-transform: none;
}

.checkout:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.unavailable {
  border-radius: 4px;
  background-color: rgba(183,55,53,0.08);
  font-weight: 500;
  letter-spacing: 0.88px;
  color: #b73735;
  text-transform: capitalize;
  font-size: 10px;
  padding: 8px 14px;
  justify-self: flex-start;
  align-self: center;
}

.excTax {
  font-size: 8px;
  line-height: 10px;
  color: #a2a2a2;
  grid-column: 1;
  margin-top: 2px
}
