.couponCard {
  background: white;
  padding: 16px;
  border-radius: 8px;
  display: grid;
  grid-template: auto / 1fr;
  gap: 12px;
  width: 380px;
  align-self: center;
  justify-items: center;
}

.couponHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.msgImg{
  margin-bottom: -16px;
  width: 120px;
  height: 120px;
}

.validity {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--secondary-contrast-text-3);
}

.couponCode {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5%;
  color: #33aa31;
}

.gotIt {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #aa3131;
}

@media (max-width: 768px) {
  .couponCard {
    width: 90%;
    padding: 0 54px 24px;
    gap: 12px;
  }
}
