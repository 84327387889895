.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
}

.title {
  color: var(--primary-main);
  font-weight: 700;
}

.banner {
  width: 100%;
  object-fit: cover;
}

.action {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.action > * {
  width: 180px;
  height: 58px;
}

.viewDetails {
  font-weight: 700;
  background: transparent;
  border: 1px solid var(--primary-main);
  color: var(--primary-main);
}

.viewDetails span {
  width: 100%;
}

.contactUs {
  font-weight: 700;
}

@media (max-width: 768px) {
  .title {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.4px;
  }

  .viewDetails {
    width: 100%;
  }

  .contactUs {
    display: none;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.8px;
  }

  .action > *:not(:last-child) {
    margin-right: 16px;
  }
}
