.container {
  display: grid;
  gap: 16px;
  color: white;
  transition: all ease-in-out 0.3s;
  grid-template: 1fr auto/1fr;
  grid-column: span 2;
}

.background {
  object-fit: cover;
  grid-column: 1;
  grid-row: 1 / span 3;
  height: 100%;
  width: 100%;
}

.title {
  grid-column: 1;
  grid-row: 1;
  font-weight: 700;
  color: #fff;
  margin: 0;
  white-space: pre-wrap;
  align-self: flex-end;
}

.overlay {
  grid-column: 1;
  grid-row: 1 / span 3;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.subtitle {
  color: #fff;
  grid-column: 1;
  grid-row: 2;
  margin: 0;
  display: flex;
  align-items: flex-start;
  font-weight: 500;
}

@media (max-width: 768px) {
  .container {
    gap: 8px;
  }

  .container > *:not(img) {
    padding: 0px 16px;
  }

  .title {
    margin-top: 28px;
    font-size: 24px;
    line-height: 24px;
    align-self: flex-end;
    margin-bottom: 4px;
  }

  .subtitle {
    font-size: 12px;
    line-height: 20px;
  }
}

@media (min-width: 768px) {
  .container {
    height: 250px;
  }

  .container > *:not(img) {
    padding: 18px 20px;
  }

  .title {
    margin-top: 30px;
    margin-bottom: -10px;
    font-size: 52px;
    line-height: 64px;
  }

  .subtitle {
    max-width: 100%;
    font-size: 24px;
    line-height: 34px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container > *:not(img):not(.webOffer) {
    padding: 0 calc((100% - 712px) / 2);
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .container > *:not(img):not(.webOffer) {
    padding: 0 calc((100% - 960px) / 2);
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .container > *:not(img):not(.webOffer) {
    padding: 0 calc((100% - 1100px) / 2);
  }
}

@media (min-width: 1440px) {
  .container > *:not(img):not(.webOffer) {
    padding: 0 calc((100% - 1200px) / 2);
  }
}
