.container {
  display: grid;
  gap: 8px;
  grid-template: auto/1fr;
}

.titleContainer {
  font-size: 16px;
  color: var(--secondary-contrast-text-3);
  font-weight: 600;
  line-height: 24px;
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 24px;
  align-items: center;
  border-bottom: 1px solid #FFFFFF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
  margin: 0 -16px;
  padding: 24px 20px 16px;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #222222;
}

.notes {
  padding: 0 16px;
}

.notes,
.subTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-contrast-text);
}

.subTitle {
  margin: 10px 0 -12px;
}

.selectMenu {
  width: 100%;
}

.selectMenu label {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #222222;
}

.note {
  padding: 8px;
}

.resideContainer {
  display: grid;
}

.reason {
  display: grid;
  margin: 10px 0 20px;
}

.reason label {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #222222;
}

.continue {
  width: 100%;
  margin: 8px 0 28px;
  height: 52px;
}