.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 24px;
  background: #262626;
}

.benefitsContainer {
  display: grid;
  grid-template: auto/auto;
  grid-auto-flow: column;
  gap: 16px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.tier {
  scroll-snap-align: start;
}

@media (max-width: 768px) {
  .tier[data-status="ongoing"],
  .tier[data-status="completed"] {
    display: none;
  }

  .container {
    margin: 0px !important
  }
}

@media (min-width: 768px) {
  .benefitsContainer {
    justify-content: space-evenly;
  }

  .container {
    margin: 0px -120px;
  }
}
