.container {
  position: relative;
  display: inline-grid;
  grid-template: auto/1fr;
  gap: 4px;
  align-content: flex-start;
  font-weight: 400;
  margin: 8px 0;
  border-radius: 4px;
  padding: 0 16px;
  align-content: center;
  border: 1px solid #DADFE6;
}

.container[data-focus='true'] {
  /* background: #F5F5F7; */
  border: 1px solid var(--border-color) !important;
}

.container[data-focus='true'][data-price-calculator='true'] {
  border-radius: 0 0 6px 6px; 
}

.optionsContainer {
  position: absolute;
  display: grid;
  gap: 12px;
  left: 0;
  right: 0;
  top: calc(100% + 1px);
  padding: 16px;
  background: white;
  border-radius: 4px;
  border: 1px solid #E6E6E6;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 1;
}

.separator{
  border-bottom: 1px solid #E6E6E6;
  margin-right: -16px;
  margin-left: -16px;
}

.label {
  text-transform: capitalize;
  grid-row: 1;
  color: var(--secondary-contrast-text-2);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0%;
  opacity: 0.8;
  cursor: pointer;
}

.label ~ .expandIcon {
  top: 50%;
}

.input {
  display: flex;
  flex-direction: row;
  gap: 4px;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text);
  outline: none;
  width: 100%;
}

.input[data-value='Select guests'] {
  color: var(--secondary-contrast-text-2);
  font-weight: 500;
}

.input[data-error='true']:hover {
  border-color: #CC385A;
}

.input[data-error='true']:focus {
  border-color: #CC385A;
}

.input[data-error='false']:hover {
  border-color: #474e66;
}

.input[data-error='false']:focus {
  border-color: #474e66;
}

.error {
  color: #CC385A;
  font-size: 12px;
}

.expandIcon {
  position: absolute;
  top: 22px;
  transform: translateY(-50%);
  right: 12px;
  fill: #cccccc;
  pointer-events: none;
}

.expandIcon:hover {
  fill: #474e66;
}

.done {
  margin: 0 0 0 auto;
  background: transparent;
  color: var(--primary-main);
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  border: 1px solid #AA3131;
}