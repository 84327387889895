.container {
  display: grid;
  gap: 8px;
}

.digitsContainer {
  display: grid;
  grid-column: span 2;
  grid-template: auto/repeat(4, auto);
  justify-content: center;
  gap: 32px;
  font-weight: 700;
}

.digit {
  border: 1px solid #1F2C3833;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  background: transparent;
  width: 60px;
  height: 60px;
}

.smallDigit{
  border: 1px solid #1F2C3833;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  background: transparent;
  width: 58px;
  height: 58px;
}

.digit[disabled] {
  opacity: 0.5;
}

.timer {
  font-weight: 600;
  color: var(--secondary-contrast-text);
  grid-column: span 2;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
  letter-spacing: 0.1px;
}

.timer[data-visible="false"] {
  visibility: hidden;
}

.resendContent {
  grid-row: 3;
  grid-column: span 2;
  color: var(--secondary-contrast-text-2);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  text-align: center;
}

.resend {
  background: transparent;
  border: none;
  text-decoration: underline;
  color: #B73735;
  cursor: pointer;
}

.resend[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  color: #D3D3D3;
}

.error {
  grid-column: span 2;
  color: #CC385A;
  font-size: 12px;
}

@media (max-width: 360px) {
  .digitsContainer {
    gap: 16px;
  }
}

@media (max-width: 768px) {
  .timer {
    font-size: 12px;
  }

  .timerIcon {
    width: 10px;
    height: 10px;
    margin-right: 6px;
  }

  .smallDigit{
    width: 40px;
    height:40px;
  }

}

@media (min-width: 768px) {
  .timer {
    font-size: 14px;
  }

  .timerIcon {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }
}