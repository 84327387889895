.container {
  display: grid;
  color: var(--secondary-contrast-text-3);
  gap: 6px;
}

.title {
  font-weight: bold;
}

.description {
  margin: 0;
}

.carousel {
  gap: 24px;
  grid-row: 3;
  grid-column: 1;
}

.scroller {
  padding: 12px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-contrast-text-3);
  align-self: center;
  cursor: pointer;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.2);
}

.scroller img {
  width: 28px;
  height: 28px;
}

.property {
  display: grid;
  grid-template: auto/1fr;
  gap: 6px;
}

.propertyThumbnail {
  border-radius: 6px;
  object-fit: cover;
}

.propertyTitle {
  font-weight: bold;
  color: var(--secondary-contrast-text-3);
  margin-top: 8px;
}

.propertySubtitle {
  font-weight: 500;
  color: rgba(35, 35, 35, 0.6);
}

@media (max-width: 768px) {
  .title {
    font-size: 20px;
  }

  .description {
    font-size: 14px;
  }

  .carousel {
    margin-top: 12px;
  }

  .scroller {
    grid-row: 4;
    grid-column: 1;
  }

  .scrollerLeft {
    justify-self: center;
    margin-left: -70px;
  }

  .scrollerRight {
    justify-self: center;
    margin-right: -70px;
  }

  .propertyThumbnail {
    width: 160px;
    height: 160px;
  }

  .propertyTitle {
    font-size: 12px;
  }

  .propertySubtitle {
    font-size: 10px;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 28px;
  }

  .description {
    font-size: 16px;
  }

  .carousel {
    margin-top: 20px;
  }

  .scroller {
    grid-row: 3;
    grid-column: 1;
    z-index: 0;
  }

  .scrollerLeft {
    justify-self: flex-start;
    margin-left: -80px;
  }

  .scrollerRight {
    justify-self: flex-end;
    margin-right: -80px;
  }

  .propertyThumbnail {
    width: 280px;
    height: 280px;
  }

  .propertyTitle {
    font-size: 14px;
    text-align: center;
  }

  .propertySubtitle {
    font-size: 12px;
    text-align: center;
  }
}
