.container {
  display: grid;
  color: var(--secondary-contrast-text-3);
}

.title {
  font-weight: bold;
}

.approaches {
  display: grid;
}

.approach {
  display: grid;
}

.approachThumbnail {
  grid-row: span 2;
}

.approachTitle {
  color: #2f0e0e;
  font-weight: bold;
}

.approachDescription {
  color: var(--secondary-contrast-text-3);
}

@media (max-width: 768px) {
  .container {
    gap: 10px;
  }

  .title {
    font-size: 20px;
  }

  .approaches {
    grid-template: auto/1fr;
  }

  .approach {
    grid-template: auto/1fr;
    gap: 8px;
  }

  .approach:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .approachThumbnail {
    display: none;
  }

  .approachTitle {
    margin-top: 16px;
    font-size: 14px;
  }

  .approachDescription {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .container {
    gap: 24px;
  }

  .title {
    font-size: 28px;
  }

  .approaches {
    grid-template: auto/repeat(auto-fit, minmax(450px, 1fr));
    gap: 24px;
  }

  .approach {
    padding: 16px;
    grid-template: auto 1fr/auto 1fr;
    gap: 18px 24px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.2);
  }

  .approachTitle {
    margin-top: 24px;
    font-size: 18px;
  }

  .approachDescription {
    font-size: 16px;
  }
}