.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
}

.banner {
  object-fit: cover;
}

@media (max-width: 768px) {
  .banner {
    margin: 0 -16px;
    width: 100vw;
    height: 250px;
  }
}

@media (min-width: 768px) {
  .banner {
    width: 100%;
    height: 675px;
  }
}
