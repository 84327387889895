.container {
  display: grid;
  grid-template: auto/auto 1fr auto auto;
  gap: 12px;
  padding: 16px;
  background: var(--primary-main);
  color: var(--primary-contrast-text);
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

a.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 16px;
  font-weight: 500;
  color: var(--primary-main);
  background: var(--primary-contrast-text);
  cursor: pointer;
  font-size: 10px;
}

@media (min-width: 768px) {
  .container {
    display: none;
  }
}
