.container {
  display: grid;
  gap: 16px;
  color: #ffffff;
  align-content: center;
}

.title {
  font-weight: 700;
  letter-spacing: 0.3px;
  color: rgba(231, 232, 244, 0.9);
}

.silver {
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: linear-gradient(179.71deg, #f7f7f7 20.15%, #1b1b1b 129.14%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 0.508898px 1.0178px rgba(0, 0, 0, 0.25);
}

.book {
  color: #ffffff;
  height: 44px;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr auto;
  }

  .points {
    grid-column: span 2;
  }

  .title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.3px;
  }

  .silver {
    font-size: 16px;
  }

  .book {
    width: 120px;
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: auto/1fr;
  }

  .title {
    font-size: 34px;
    align-self: flex-end;
  }

  .silver {
    font-size: 32px;
    display: block;
  }

  .book {
    width: 200px;
    font-size: 16px;
    margin-top: 20px;
  }
}
