@keyframes snackbar-slide-in {
  from {
    transform: translate(-50%, 100%);
  }

  to {
    transform: translate(-50%, 0%);
  }
}

.container {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 8px 8px 8px 16px;
  font-size: 14px;
  animation: snackbar-slide-in 0.2s;
  display: grid;
  grid-template: auto/1fr auto;
  gap: 12px;
  align-items: center;
  border-radius: 4px;
  /* box-shadow: rgb(0 0 0 / 20%) 0 3px 5px -1px, rgb(0 0 0 / 14%) 0 6px 10px 0, rgb(0 0 0 / 12%) 0 1px 18px 0; */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  font-weight: 400;
  min-height: 48px;
  z-index: 1;
}

.container[data-severity="error"] {
  background: #d32f2f;
  color: white;
}

.container[data-severity="warning"] {
  background: #f57c00;
  color: white;
}

.container[data-severity="info"] {
  background: #0288d1;
  color: white;
}

.container[data-severity="success"] {
  background: rgba(229, 243, 224, 1);
  color: rgba(82, 106, 74, 1);
}

.clear {
  font-size: 32px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .container {
    width: 92vw;
    bottom: 155px;
  }
}

@media (min-width: 768px) {
  .container {
    min-width: 400px;
    bottom: 16px;
  }
}