.location {
  gap: 8px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #D7D7D7;
}

.image {
  width: 100%;
}

.image :global .image {
  object-fit: cover;
}

.title {
  font-weight: 600;
  align-self: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--secondary-contrast-text);
}

@media (max-width: 768px) {
  .image {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    aspect-ratio: 1.2;
  }

  .title {
    text-align: center;
    padding: 0 8px 8px;
    font-size: 12px;
  }

  .location {
    display: grid;
    grid-template: auto/auto;
  }
}

@media (min-width: 768px) {
  .location {
    display: grid;
    grid-template: auto/auto 1fr;
  }

  .title {
    font-size: 14px;
  }

  .image {
    height: 65px;
    border-radius: 4px;
    aspect-ratio: 1;
  }
}