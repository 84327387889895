@keyframes scroll-down {
  from {
    transform: translate(-50%, -20px);
  }

  to {
    transform: translate(-50%, 0);
  }
}

.scroll {
  display: grid;
  grid-template: auto/1fr;
  gap: 8px;
  max-width: 150px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  justify-items: center;
  animation: scroll-down 1s infinite ease-in-out alternate;
}
