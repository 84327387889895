.carousel {
  margin: 36px 0 24px;
  grid-template: auto/repeat(3, 100%);
  scroll-snap-type: x mandatory;
}

.feature {
  display: grid;
  grid-template: 1fr auto/1fr auto;
  gap: 8px;
  color: white;
  height: 75vh;
  position: relative;
  padding: 16px 40px 32px;
  scroll-snap-align: start;
}

.featureImage {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.featureGradient {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 6px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 25%, transparent 0%);
}

.featureTitle {
  align-self: flex-end;
  font-weight: 700;
  letter-spacing: 0.9px;
}

.featureSubtitle {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.35px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.featureSubtitle b {
  margin-left: 6px;
}

.readMore {
  grid-row: span 2;
  align-self: flex-end;
  background: white;
  width: 145px;
  height: 50px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
}

a.readMore {
  color: #b73735;
}

.intro {
  font-size: 16px;
  margin: 64px 0;
}

.catalogue {
  display: grid;
  grid-template: auto/repeat(auto-fill, minmax(300px, 1fr));
  gap: 32px;
  margin-bottom: 64px;
}

.publish {
  display: grid;
  grid-template: auto/1fr;
  gap: 8px;
  padding: 0;
  border-radius: 8px;
  background: white;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.10);
}

.thumbnail {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.publisher {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  margin-top: 8px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.publisher b {
  padding-left: 6px;
}

.title {
  padding: 0 16px 24px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.scroller {
  display: grid;
  width: 60px;
  margin: 0 0 0 auto;
}

@media (max-width: 768px) {
  .featureTitle {
    font-size: 18px;
  }

  .publisher {
    font-size: 14px;
  }

  .title {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .featureTitle {
    font-size: 28px;
  }

  .publisher {
    font-size: 17px;
  }

  .title {
    font-size: 20px;
  }
}
