.locationContainer {
  background: #1b1b1b;
  padding-bottom: 32px;
  color: #ffffff99;
}

.container {
  display: grid;
  gap: 24px;
  align-items: flex-start;
  color: rgba(193, 193, 193, 0.6);
  background: #100e0d;
  padding-top: 32px;
  padding-bottom: 60px;
}

.hideFooter[data-hidden="true"] {
  display: none;
}

.brand {
  color: #ffffff99;
  font-size: 14px;
}

.logo {
  margin: 0 0 16px 0;
  width: 230px;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .brand {
    line-height: 18px;
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .brand {
    line-height: 24px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    grid-template: auto/1fr 1fr;
  }

  .brand {
    grid-column: span 2;
  }
}
