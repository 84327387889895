@keyframes calendar-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  z-index: 1;
}

.whiteBackDrop{
  background: none;
  backdrop-filter: none;
}

.container {
  display: none;
  background: white;
  box-shadow: 0px 4px 4px 1px #00000026;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  overflow: hidden;
  grid-template: auto/1fr;
  padding: 16px 24px;
  z-index: 1;
  height: initial;
  animation: calendar-fade-in 0.3s ease-in-out;
  margin-left: 0;
  margin-right: 0;

  top: var(--calendar-dialog-top);
  left: var(--calendar-dialog-left);
  transform: var(--calendar-dialog-transform);
}

.backdrop[data-open='false'] {
  display: none;
}

.container[open] {
  display: grid;
}

.transition {
  display: grid;
  grid-column: 1;
  grid-row: 1;
  gap: 0 32px;
  grid-template: auto/1fr;
  grid-auto-flow: column;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  cursor: pointer;
  width: 32px;
  height: 32px;
  color: #b6b6b6;
  border: none;
  font-size: 40px;
  align-self: flex-start;
  grid-column: 1;
  grid-row: 1;
}

.navigation[data-action='previous'] {
  justify-self: flex-start;
}

.navigation[data-action='next'] {
  justify-self: flex-end;
}

.actions {
  display: grid;
  margin-top: 8px;
  grid-template: auto/repeat(2, auto);
  gap: 8px;
}

.action {
  color: var(--primary-main);
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  width: 80px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .container {
    --calendar-dialog-top: 50%;
    --calendar-dialog-left: 50%;
    --calendar-dialog-transform: translate(-50%, -50%);

    position: fixed;
  }

  .actions {
    justify-content: space-between;
  }

  .isTwoMonthTransition{
    grid-auto-flow: row;
    width: 100%;
    justify-self: center;
    grid-row: 2;
    gap: 16px;
    padding-top: 12px;
    overflow-y: auto;
    max-height: calc(100vh - 410px);
    padding-bottom: 14px;
    overflow-x: hidden;
  }

  .dayNameContainer{
    --calendar-cell-size: calc((100vw - 52px) / 7);
    display: grid;
    gap: 2px 0;
    grid-template-columns: repeat(7, auto);
    border-bottom: 1px solid #E6E6E6;
    height: 24px;
    align-content: center;
    margin-right: -10px;
    margin-left: -10px;
    margin-top: -12px;
    grid-column: 1;
    grid-row: 1;
    position: sticky;
    background: #F5F5F7; 
    padding: 4px 8px;
    z-index: 2;
  }

  .dayName{
    width: var(--calendar-cell-size);
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0px;
    /* text-align: center;
    vertical-align: middle; */
    color: var(--secondary-contrast-text-2);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hideNavigation{
    display: none;
  }

  .mobileDialogActions{
    position: sticky;
    padding: 10px;
    margin-right: -10px;
    margin-left: -10px;
    align-items: center;
    margin-bottom: -12px;
    margin-top: 0;
    background: white; 
    z-index: 2;
    border-top: 1px solid #E6E6E6;
  }

  .selectAction{
    background: #DDDDDD;
    height: 40px;
    width: 118px;
    border: none;
    border-radius: 4px;
    padding: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0%;
    color: #FFFFFF;
    pointer-events: none;
  }

  .selectAction[data-selected="true"]{
    background: #1F2C38;
    pointer-events: all;
  }

  .clearAction{
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0%;
    text-decoration: underline;
    text-decoration-style: solid;
    color: #1F2C38;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .loadMoreDates{
    border: 1px solid #1F2C38;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0%;
    color: #1F2C38;
    padding: 9px;
    border-radius: 4px;
    text-align: center;
    margin-top: -4px;
    width: 99%;
  }
}

@media (min-width: 768px) {
  .backdrop {
    display: none;
  }

  .container {
    --calendar-dialog-top: calc(100% + 20px);

    position: absolute;
  }

  .container[data-position='left'] {
    --calendar-dialog-left: 0;
  }

  .container[data-position='right'] {
    --calendar-dialog-left: 100%;
    --calendar-dialog-transform: translateX(-100%);
  }

  .actions {
    justify-content: space-between;
  }

  .dayNameContainer{
    display: none;
  }

  .loadMoreDates{
    display: none;
  }

  .dayNameContainer{
    display: none;
  }

  .clearAction{
    display: none;
  }

  .selectAction{
    display: none;
  }

  .mobileDialogActions{
    display: none;
  }
}
