.container {
  display: grid;
  grid-template: auto/1fr auto;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.15);
  background: var(--header-background);
  height: var(--navbar-height)
}

.logoContainer {
  width: 160px;
  justify-content: flex-start;
}

.logo {
  max-width: 160px;
}

.callDesktop {
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 8px;
  align-items: center;
  height: 48px;
  border: 1px solid #979797;
  color: var(--secondary-contrast-text);
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.4px;
  border-radius: 4px;
  padding: 0 16px;
}

@media (max-width: 768px) {
  .logo {
    max-height: 40px;
  }

  .callDesktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .logo {
    max-height: 52px;
  }

  .callMobile {
    display: none;
  }
}
