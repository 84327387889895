.container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: fit-content;
  border-bottom: 1px solid rgba(221, 221, 221, 1);
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01));
  overflow: auto;
  padding: 0 24px 12px;
  display: grid;
  grid-template: auto/1fr;
  gap: 12px;
  align-content: flex-start;
  overflow: visible;
}

.search {
  position: fixed;
}

.searchDesktop{
  box-shadow: none;
}
