.mobileHeader {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #ffffff;
    box-shadow: 0 2px 4px rgb(0 0 0 / 6%);
    display: grid;
    padding: 8px 2px;
    grid-template: auto/auto 1fr;
    margin-bottom: -16px;
  }
  
  .mobileMenu {
    align-self: center;
    cursor: pointer;
    padding: 10px;
  }
  
  .mobileHeaderContent {
    display: grid;
    grid-template: auto/1fr;
    align-items: center;
    background: #0000000f;
    border-radius: 49px;
    margin-right: 16px;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--secondary-contrast-text-3);
  }
  
  .leftChevron {
    grid-row: 1;
    grid-column: 1;
    margin: 0 0 0 12px;
    justify-self: flex-start;
    height: 24px;
  }
  
  .mobileHeaderContentIcon {
    grid-row: 1;
    grid-column: 1;
    display: flex;
    justify-self: center;
  }
  
  .locationIcon,
  .caretIcon,
  .addGuestsIcon,
  .eventIcon {
    margin: 0 6px;
  }
  
  .container {
    display: grid;
    gap: 16px;
    color: white;
    transition: all ease-in-out 0.3s;
    grid-template: 1fr auto/1fr;
  }
  
  .collectionContainer {
    display: grid;
    gap: 16px;
    color: white;
    transition: all ease-in-out 0.3s;
    grid-template: 1fr auto/1fr;
  }
  
  .collectionfiltersDesktop .search{
    display: none;
  }
  
  .background {
    object-fit: cover;
    grid-column: 1;
    grid-row: 1/span 3;
    height: 100%;
    width: 100%;
  }
  
  .overlay {
    grid-column: 1;
    grid-row: 1/span 3;
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
  }
  
  .breadcrumb {
    grid-column: 1;
    grid-row: 1;
    font-weight: 500;
    margin: 16px 0 0;
    color: white;
  }
  
  .title {
    grid-column: 1;
    grid-row: 1;
    font-weight: 700;
    margin: 0;
    white-space: pre-wrap;
    align-self: flex-end;
  }
  
  .collectionContainer .title {
    grid-column: 1;
    grid-row: 1;
    font-weight: 400;
    line-height: 56px;
    margin: 0;
    white-space: pre-wrap;
    align-self: flex-end;
  }
  
  .subtitle {
    grid-column: 1;
    grid-row: 2;
    margin: 0;
    display: flex;
    align-items: flex-start;
    font-weight: 400;
  }
  
  .collectionContainer .subtitle {
    grid-column: 1;
    grid-row: 2;
    margin: 0;
    display: flex;
    align-items: flex-start;
    font-weight: 500;
  }
  
  .filters {
    width: 100%;
    background: white;
    align-self: flex-end;
    border-radius: 4px;
  }
  
  .filtersDesktop {
    top: 10px;
    display: grid;
    grid-template: auto/repeat(4, 1fr) auto;
    margin-top: -120px;
    margin-bottom: 48px;
    transition: all 0.1s;
    position: sticky;
    z-index: 1;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(170, 170, 170, 0.5);
  }
  
  .collectionfiltersDesktop {
    top: 10px;
    display: grid;
    grid-template: auto/repeat(3, 1fr) auto;
    margin-top: -120px;
    margin-bottom: 48px;
    transition: all 0.1s;
    position: sticky;
    z-index: 1;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(170, 170, 170, 0.5);
  }
  
  .filtersMobile {
    top: 6px;
    font-size: 10px;
    display: grid;
    grid-template: auto/3fr 2fr;
    color: var(--secondary-contrast-text-3);
    transition: all 0.1s;
    cursor: pointer;
    border: 1px solid #F0F0F0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  }
  
  .mobileContainer {
    display: grid;
    grid-template: auto/1fr auto;
    gap: 8px;
    padding: 8px 16px 0 16px;
    background: #ffffff;
  }
  
  .destination :global .input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top: none;
    border-left: none;
    border-bottom: none;
  }
  
  .destination :global .expand-icon {
    top: 27px;
  }
  
  .stay:hover :global .value {
    border-color: #ebebeb;
  }
  
  .stay :global .value {
    border-top: none;
    border-bottom: none;
  }
  
  .stay :global .value:nth-child(odd) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
  
  .stay :global .value:nth-child(even) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .occupancy :global .input {
    border-radius: 0;
  }
  
  .occupancy :global .input[data-error='false']:hover,
  .occupancy :global .input[data-error='false']:focus {
    border-color: #ebebeb;
  }
  
  .occupancy :global .expand {
    top: 50%;
  }
  
  .search {
    position: relative;
  }
  
  .search input {
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    font-size: 16px;
    background: white;
    padding: 12px;
    color: #474e66;
  }
  
  .searchButton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 14px;
  }
  
  .collectionfiltersDesktop .searchButton{
    border-radius: 4px;
    font-size: 14px;
    margin: 8px;
    width: 116px;
  }
  
  .friendly {
    padding: 12px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .caret {
    margin-left: auto;
    opacity: 0.6;
  }
  
  .friendly:not(:last-child) {
    border-right: 1px solid #ebebeb;
  }
  
  .friendly[data-populated='false'] {
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.3px;
    color: #777777;
  }
  
  .webOffer {
    display: grid;
    grid-column: 1;
    grid-row: 2;
    grid-template: auto/auto 1fr;
    align-content: flex-start;
    align-self: flex-end;
    justify-self: center;
    gap: 0 12px;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(14px);
    border-radius: 8px;
    width: 100%;
  }
  
  .webOfferTitle {
    color: #FFABAB;
    font-weight: 800;
    letter-spacing: 0.5px;
  }
  
  .webOfferSpan {
    color: #ffffff;
  }
  
  .webOfferSubtitle {
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #FFFFFFCC;
  }
  
  .clockIcon {
    grid-row: 1/span 2;
  }
  
  @media (max-width: 768px) {
    .container {
      height: 160px;
    }
  
    .collectionContainer {
      height: 160px;
      gap: 0px;
    }
  
    /* .container>*:not(img) {
      padding: 0 16px;
    */
  
    .container>*:not(picture) {
      padding: 8px 16px;
    }
  
    .collectionContainer>*:not(picture) {
      padding: 16px 16px;
    }
  
    .collectionfiltersDesktop {
      display: none;
    }
  
    .breadcrumb {
      font-size: 12px;
    }
  
    .webOffer {
      grid-template: auto/1fr;
      margin: 0 12px;
      letter-spacing: 0.25px;
    }
  
    .clockIcon {
      display: none;
    }
  
    .webOfferTitle {
      font-size: 16px;
    }
  
    .webOfferSpan {
      font-size: 8px;
    }
  
    .webOfferSubtitle {
      font-size: 8px;
      line-height: 10px;
    }
  
    .title {
      font-size: 16px;
      align-self: flex-end;
    }
  
    .collectionContainer .title {
      font-size: 24px;
      font-weight: 600;
      align-self: flex-end;
      font-family: Tantinotes;
      /* font-family: var(--font-family); */
      line-height: 24px;
      margin-bottom: -26px;
    }
  
    .subtitle {
      font-size: 12px;
    }
  
    .collectionContainer .subtitle {
      max-width: 270px;
      font-size: 12px;
    }
  
    .filters {
      max-width: 100%;
    }
  
    .filtersDesktop {
      display: none;
    }
  
    .filtersMobile {
      display: grid;
    }
  
    .friendly {
      white-space: nowrap;
      overflow: hidden;
    }
  
    .webOffer {
      max-width: calc(100% - 32px);
    }

    .mobileContainer button {
      font-size: 10px !important;
    }
  }
  
  @media (min-width: 768px) {
    .mobileHeader {
      display: none;
    }
  
    .container {
      height: 250px;
    }
  
    .collectionContainer {
      height: 480px;
    }
  
    .breadcrumb {
      font-size: 16px;
    }
  
    .container>*:not(picture) {
      padding: 18px 20px;
    }
  
    .collectionContainer>*:not(picture) {
      padding: 18px 20px;
    }
  
    .webOffer {
      margin: 100px 130px;
    }
  
    .webOfferTitle {
      font-size: 28px;
    }
  
    .webOfferSubtitle {
      font-size: 12px;
      line-height: 19px;
    }
  
    .webOfferSpan {
      font-size: 16px;
    }
  
    .title {
      font-size: 26px;
      margin-bottom: -10px;
    }
  
    .collectionContainer .title {
      font-size: 80px;
      font-family: Tantinotes;
      /* font-family: var(--font-family); */
      margin-left: 12px;
    }
  
    .subtitle {
      font-size: 18px;
      margin-bottom: 90px;
    }
  
    .collectionContainer .subtitle{
      font-size: 24px;
      margin-bottom: 100px;
    }
  
    .filtersMobile {
      display: none;
    }
  
    .stay {
      min-height: unset;
      padding: 8px 16px;
      border-right: 1px solid #ebebeb;
    }
  
    .destination :global .input {
      padding: 20px 12px;
    }
  
    /* .occupancy :global .input {
      padding: 18px 12px;
    } */

    .occupancy{
      margin: 0;
      padding: 8px 16px;
      border: none;
    }
  
    .search {
      padding: 8px 12px;
    }
  
    .mobileContainer {
      display: none;
    }

    .collectionfiltersDesktop .searchButton{
      width: 225px;
    }
  }
  
@media (min-width: 768px) and (max-width: 1024px) {
  .container>*:not(picture):not(.webOffer) {
    padding: 0 calc((100% - 712px) / 2);
  }

  .collectionContainer>*:not(picture):not(.webOffer) {
    padding: 0 calc((100% - 712px) / 2);
  }

  .filters {
    max-width: 712px;
    margin-left: auto;
    margin-right: auto;
  }

  .webOffer {
    max-width: 712px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .container>*:not(picture):not(.webOffer) {
    padding: 0 calc((100% - 960px) / 2);
  }

  .collectionContainer>*:not(picture):not(.webOffer) {
    padding: 0 calc((100% - 960px) / 2);
  }

  .filters {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

  .webOffer {
    max-width: 960px;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .container>*:not(picture):not(.webOffer) {
    padding: 0 calc((100% - 1100px) / 2);
  }

  .collectionContainer>*:not(picture):not(.webOffer) {
    padding: 0 calc((100% - 1100px) / 2);
  }

  .filters {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }

  .webOffer {
    max-width: 1100px;
  }
}

@media (min-width: 1440px) {
  .container>*:not(picture):not(.webOffer) {
    padding: 0 calc((100% - 1200px) / 2);
  }

  .collectionContainer>*:not(picture):not(.webOffer) {
    padding: 0 calc((100% - 1200px) / 2);
  }

  .filters {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .webOffer {
    max-width: 1200px;
  }
}
