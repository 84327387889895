.calligraphy {
  width: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  z-index: -1;
}

.container {
  position: relative;
  display: grid;
  grid-template: auto/1fr;
}

.title {
  color: #474e66;
  font-weight: 700;
  letter-spacing: 1.1px;
  display: flex;
  flex-direction: row;
}

.decorate {
  flex: 1 1 0;
  margin-left: 16px;
  align-self: flex-end;
  height: 1px;
  background-color: #a6a6a6;
  margin-bottom: 10px;
}

.image {
  width: 100%;
  object-fit: contain;
  background: #fbfbfe;
}

.pdf {
  width: 100%;
  min-height: 150vh;
  border: none;
}

@media (max-width: 768px) {
  .calligraphy {
    top: 60px;
  }

  .container {
    gap: 28px;
  }

  .title {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .calligraphy {
    top: 85px;
  }

  .container {
    gap: 42px;
  }

  .title {
    font-size: 28px;
  }
}
