.button {
  background: #ffffff;
  border: 1px solid #F0F0F0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.3px;
  color: var(--secondary-contrast-text);
  position: relative;
}

.icon {
  margin-left: 6px;
}

.sortIcon{
  width: 17px;
  height: 17px;
}

.menu {
  grid-template: auto/1fr;
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  display: none;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #F0F0F0;
  padding: 8px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
}

.menu[data-visible='true'] {
  display: grid;
  width: 150px;
  z-index: 1;
}

.item {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 16px;
  font-weight: 500;
}

.item[data-selected='true'] {
  background: #f7f7f7;
}
