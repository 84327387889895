.expandIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.faqContainer:not(:last-child) {
  border-bottom: 1px solid #DDDDDD;
  padding-bottom: 0;
}

.question {
  display: grid;
  grid-template: auto/1fr 24px;
  color: var(--secondary-contrast-text);
  padding: 0 0 32px 0;
  cursor: pointer;
  align-items: center;
  font-weight: 500;
}

.answer {
  color: var(--secondary-contrast-text-2);
  padding-bottom: 32px;
  white-space: pre-wrap;
  line-height: 24px;
}

@media (max-width: 768px) {
  .expandIcon img {
    width: 16px;
    height: 16px;
  }

  .question {
    font-size: 12px;
    padding: 0 0 16px 0;
  }

  .answer {
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 16px;
  }
}

@media (min-width: 768px) {
  .expandIcon img {
    width: 24px;
    height: 24px;
  }

  .question {
    font-size: 20px;
  }

  .answer {
    font-size: 20px;
  }
}