.container {
  gap: 16px;
}

.title {
  font-weight: 700;
  margin-bottom: 16px;
  color: var(--secondary-contrast-text-3);
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr 1fr;
  }

  .title {
    font-size: 16px;
    grid-column: span 2;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: auto/repeat(3, auto);
  }

  .title {
    font-size: 24px;
    line-height: 29.05px;
    grid-column: span 3;
  }
}