.container {
  display: flex;
  justify-content: center;
  gap: 6px;
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0%;
  
}

.container img {
  height: 16px;
  width: 16px;
}

.container[data-discount-type="no_tier"] {
  background: #19191C;
  color: white;
}

.container[data-discount-type="silver"] {
  background: 
  linear-gradient(0deg, rgba(172, 172, 172, 0.3), rgba(172, 172, 172, 0.3)),
  linear-gradient(90deg, rgba(182, 182, 182, 0) 0%, rgba(192, 192, 192, 0.16) 50%, rgba(182, 182, 182, 0.4) 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  color: #505050;
}

.container[data-discount-type="gold"] {
  background: linear-gradient(90deg, rgba(205, 173, 78, 0.5) 0%, rgba(242, 206, 84, 0.35) 50%, rgba(205, 173, 78, 0.5) 100%);
  color: #6C5415;
}

.container[data-discount-type="platinum"] {
  background:  linear-gradient(90deg, rgba(123, 119, 127, 0.8) 0%, rgba(172, 167, 178, 0.72) 51.39%, rgba(123, 119, 127, 0.8) 100%),linear-gradient(0deg, #7B777F, #7B777F);
  color: #FFFAED;
}

@media (max-width: 768px) {
  .container {
    font-size: 12px;
    padding: 8px;
  }
}

@media (min-width: 768px) {
  .container {
    font-size: 14px;
    padding: 12px 8px;
  }
}