.container {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 6px;
  min-height: 75vh;
  margin: 48px 80px;
  padding: 48px 32px;
}

.header {
  font-weight: 600;
  color: var(--secondary-contrast-text);
}

.backIcon {
  align-self: center;
  margin-top: 8px;
}

.tabs {
  display: grid;
  grid-template: auto/auto;
  grid-auto-flow: column;
  gap: 8px;
  letter-spacing: 0.005em;
  border-bottom: 1px solid #dddddd;
  justify-content: flex-start;
  margin-top: 48px;
}

.tab {
  font-weight: 500;
  color: var(--secondary-contrast-text-2);
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
}

.tab[data-active="true"] {
  color: var(--primary-main);
  border-color: var(--primary-main);
}

.history {
  display: grid;
	grid-template: 1fr auto/auto 1fr;
	color: var(--secondary-contrast-text);
	padding-top: 32px;
}

.history .profileContainer {
  display: flex;
}

.history .name {
	display: block;
	font-size: 20px;
	font-weight: 600;
}

.history .status {
	font-size: 16px;
	font-weight: 400;
	text-transform: capitalize;
  color: var(--secondary-contrast-text);
}

.historySuccess .points{
  color: #2D9C7A;
}

.historySuccess .reason {
  color: #2D9C7A;
  background: rgba(45, 156, 122, 0.04);
}

.historyPending .points {
  color: #CC8904;
}

.historyPending .reason {
  background: rgba(206, 152, 47, 0.06);
	color: #CE982F;
}

.historyFailed .points {
  color: #B5294A;
}

.historyFailed .reason {
  background: rgba(181, 41, 74, 0.08);
  color: #B5294A;
}

.history .points {
	font-weight: 600;
	font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 7px;
}

.history .reason {
	grid-column: span 2;
	text-align: center;
	border-radius: 4px;
	padding: 22px;
	font-size: 16px;
	margin-top: 16px;
  margin-bottom: 8px;
}

.divider {
  width: 100%;
  border: 1px solid #DDD;
	grid-column: span 2;
  margin: 0;
  margin-top: 24px;
}

.verticalRuler {
  height: 90%;
  position: absolute;
  border: 1px dashed #dddddd;
  left: 16px;
}

.subtitle {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.005em;
  color: var(--secondary-contrast-text);
  padding-top: 40px;
}

.app {
  display: flex;
  gap: 40px;
  align-items: center;
  padding-top: 48px;
  height: 260px;
}

.appLinks {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.verticalDivider {
  position: relative;
  border-right: 1px solid #dddddd;
  height: 80%;
  align-self: center;
  margin: 0 40px;
}

.separator {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 64px;
  left: -26px;
  border-radius: 50%;
  background: #e1e3e7;
  height: 50px;
  width: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
}

.playStore,
.appStore,
.QR {
  object-fit: contain;
}


@media (max-width: 768px) {

	.container {
		/* margin: 0px -8px; */
    margin: 0px;
		padding: 0;
		border: none;
	}

  .header {
    height: var(--navbar-height);
    display: grid;
    grid-template: auto/auto 1fr;
    gap: 16px;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    font-size: 16px;
    padding-right: 16px;
    padding-left: 16px;
    margin-left: -16px;
    margin-right: -16px;
  }

	.tabs {
		margin-top: 0px;
	}

  .tab {
    font-size: 14px;
    margin-top: 12px;
  }

  .history {
    padding-top: 20px;
  }

  .history .name {
    font-size: 14px;
  }

  .history .status {
    font-size: 14px;
  }

  .history .points {
    font-size: 14px;
    gap: 2px;
  }

  .history .points img {
    height: 16px;
    width: 16px;
  }

  .history .reason {
    font-size: 12px;
    padding: 12px 16px;
    margin-top: 12px;
    margin-bottom: 0px;
  }
	
	.verticalRuler {
    height: 90%;
    left: 10px;
  }

  .divider {
    margin: 16px 0 0 0;
  }

  .subtitle {
    font-size: 14px;
    /* display: none; */
    padding-top: 20px;
    /* text-align: center; */
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .subtitle img {
    height: 16px;
    width: 16px;
  }

  .playStore,
  .appStore {
    width: 100%;
  }

  .app {
    padding-top: 16px;
    padding-bottom: 16px;
    height: auto;
    justify-content: center;
  }

  .QR,
  .verticalDivider {
    display: none;
  }

  .history .profileIcon {
    margin-right: 12px;
    height: 36px;
    width: 36px;
  }

  .history:last-child .divider{
    margin-left: -18px;
    width: calc(100% + 36px);
  }
}

@media (min-width: 768px) {
  .header {
    font-size: 32px;
    display: flex;
    align-items: center;
  }

  a {
    margin-right: 16px;
  }

  .img {
    height: 32px;
    width: 32px;
  }

  .tabs {
    font-size: 16px;
  }

  .subtitle img {
    display: none;
  }

  .history .profileIcon {
    margin-right: 16px;
    height: 46px;
    width: 46px;
  }

  .history:last-child .divider{
    margin-left: -32px;
    width: calc(100% + 64px);
  }
}
