.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
}

.collectionContainer{
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
}

.locationContainer {
  display: grid;
}

.collectionContainer .locationContainer{
  display: grid;
}

.titleContainer {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--secondary-contrast-text);
}

.sectionSubtitle {
  color: #626366;
  font-size: 16px;
  font-weight: 400;
  margin-top: 4px;
}

@media (max-width: 768px) {
  .locationContainer {
    grid-template: auto/repeat(3, 1fr);
    gap: 8px;
  }

  .collectionContainer .locationContainer{
    grid-template: auto/repeat(2, 1fr);
    gap: 16.5px;
  }

  .sectionSubtitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
}
}

@media (min-width: 768px) {
  .locationContainer {
    grid-template: auto/repeat(auto-fit, 210px);
    gap: 12px;
  }
  .collectionContainer .locationContainer{
    grid-template: auto/repeat(auto-fit, 210px);
    gap: 24px;
  }
  .titleContainer{
    font-size: 20px;
  }

  .collectionContainer .titleContainer{
    font-size: 20px;
    margin-bottom: 16px;
  }
}