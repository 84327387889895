.container {
  background: white;
  padding: 24px;
  padding-top: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
  max-width: 500px;
  min-width: 400px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  color: var(--secondary-contrast-text);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.64px;
}

.title .heading {
  display: flex;
  align-items: center;
}

.title .heading img {
  height: 40px;
  width: 40px;
  margin-right: 16px;
}

.title .heading .points {
  font-size: 24px;
  font-weight: 700;
}

.title .heading .points .pointsText {
  font-size: 14px;
  font-weight: 400;
  color: #b5294a;
}

.close {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.manualContainer {
  display: grid;
  grid-template: auto/1fr;
  min-height: 50px;
}

.manualContainer .criteriaHeading {
  font-size: 16px;
  font-weight: 600;
}

.manualContainer .criteriaContent {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
}

.manualContainer .criteriaContent ul {
  padding-left: 10px;
}

.manualContainer .criteriaContent li {
  margin-bottom: 16px;
}

.gotItButton {
  padding: 13px 16px;
  border-radius: 4px;
  background: #241e1c;
  color: #e5d7aa;
  font-size: 14px;
	font-weight: 600;
	text-transform: capitalize;
}

@media (max-width: 768px) {
	
	.container {
		width: calc(100% - 32px);
		min-width: 250px;
	}

}
