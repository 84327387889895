.container {
  display: grid;
}

.propertyTitle {
  display: flex;
  align-items: center;
  color: var(--secondary-contrast-text-2);
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  padding: 32px 0 0;
}

.propertyTitle::after {
  content: '';
  flex: 1;
  margin: 4px 0 0 12px;
  height: 3px;
  background: #ECECEC;
}

.anchor {
  width: 0;
  height: 0;
}

.headerTitle {
  display: flex;
  gap: 12px;
  align-items: center;
}

.nearbyLocationButton {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}

.nearbyLocationButton img {
  height: 13px;
  width: 9px;
}

.nearbyLocationButton .locationText {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0%;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 0%;
  text-decoration-skip-ink: auto;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/repeat(auto-fit, minmax(110px, 1fr));
    gap: 8px;
  }

  .propertyTitle{
    font-size: 16px;
  }

  .headerTitle {
    display: block;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: auto/repeat(auto-fit, minmax(220px, 1fr));
    gap: 16px;
  }
}
