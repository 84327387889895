.expandIcon {
  color: #6d7278;
  display: flex;
  align-items: center;
  justify-content: center;
}

.question {
  display: grid;
  grid-template: auto/1fr 24px;
  gap: 16px;
  color: var(--secondary-contrast-text);
  padding: 12px 0;
  cursor: pointer;
  align-items: center;
  font-weight: 500;
}

.answer {
  color: rgba(0,0,0,0.5);
  padding-bottom: 16px;
  white-space: pre-wrap;
  line-height: 24px;
}

@media (max-width: 768px) {
  .expandIcon {
    font-size: 24px;
  }

  .question {
    font-size: 14px;
  }

  .answer {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .expandIcon {
    font-size: 32px;
  }

  .question {
    font-size: 16px;
  }

  .answer {
    font-size: 16px;
  }
}