

.taxContainer {
  display: grid;
  padding: 20px 16px 16px 16px;
  gap: 12px;
}

.taxBreakdown {
  display: grid;
  grid-template: auto auto / auto 1fr;
  row-gap: 2px;
  column-gap: 40px;
  font-weight: 500;
}

.taxBreakdownDark{
  font-weight: 600 !important;
}

.title{
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-3);
}


.subTitle {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-2);
  text-align: left;
  grid-row: 2;
  grid-column: 1;
}

.description {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text);
  text-align: left;
}

.taxes {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: right;
  color: var(--secondary-contrast-text);
}

.taxGreen{
  color: #33AA31;
}

@media (max-width: 768px) {
  .taxBreakdown {
    row-gap: 0px;
  }

  .taxContainer{
    gap: 12px;
    padding: 12px;
  }

  .title{
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0%;
  }

  .description{
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0%;
  }

  .subTitle{
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0%;
  }

  .taxes{
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0%;
    text-align: right;
  }
}
