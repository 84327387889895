.container {
  --calendar-cell-size: 40px;
  display: grid;
  gap: 2px 0;
  grid-template: repeat(8, var(--calendar-cell-size))/repeat(7, auto);
}

.monthName {
  grid-column: 1/span 7;
  grid-row: 1;
  color: var(--secondary-contrast-text-3);
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--calendar-cell-size);
}

.dayName {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: #a1a1a1;
  width: var(--calendar-cell-size);
  height: var(--calendar-cell-size);
}


.offset[data-offset='0'] {
  display: none;
}

.offset[data-offset='1'] {
  grid-column: span 1;
}

.offset[data-offset='2'] {
  grid-column: span 2;
}

.offset[data-offset='3'] {
  grid-column: span 3;
}

.offset[data-offset='4'] {
  grid-column: span 4;
}

.offset[data-offset='5'] {
  grid-column: span 5;
}

.offset[data-offset='6'] {
  grid-column: span 6;
}

.date {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-contrast-text);
  width: var(--calendar-cell-size);
  height: var(--calendar-cell-size);
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  user-select: none;
}

.date :global .background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.date[data-tense="past"] {
  color: rgba(35, 35, 35, 0.35);
  cursor: not-allowed;
}

.date[data-weekend="start"] :global .background {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.date[data-weekend="end"] :global .background {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.date[data-selection="true"] :global .background {
  background: var(--primary-light);
  color: var(--primary-contrast-text);
}

.date[data-selection="start"] {
  background: var(--primary-light);
  color: var(--primary-contrast-text);
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.date[data-selection="end"] {
  background: var(--primary-light);
  color: var(--primary-contrast-text);
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.date[data-selection="start"] :global .background {
  background: var(--primary-main);
  border-radius: 50%;
}

.date[data-selection="end"] :global .background {
  background: var(--primary-main);
  border-radius: 50%;
}

.date[data-selection="start"][data-weekend="end"] {
  border-radius: 50%;
}

.date[data-selection="end"][data-weekend="start"] {
  border-radius: 50%;
}

.date[data-selection="start_only"] :global .background {
  background: var(--primary-main);
  border-radius: 50%;
  color: var(--primary-contrast-text);
}

.date[data-selection="end_only"] :global .background {
  background: var(--primary-main);
  border-radius: 50%;
  color: var(--primary-contrast-text);
}

.date[data-month-day="start"][data-selection="end"] {
  border-radius: 50%;
}

.date[data-month-day="start"] :global .background {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.date[data-month-day="end"] :global .background {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

/* .date[data-only-checkout="true"] {
  border: 1px solid var(--primary-main);
  border-radius: 50%;
} */

@media (max-width: 768px) {
  .monthNameMobile{
    min-height: unset !important;
    justify-self: left;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0px;
    color: #000000;
  }
}


@media (min-width: 768px) {
  .monthNameMobile{
    justify-self: center;
  }
}
