.container {
  padding: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 2px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
}

.container[data-discount-type="percentage_discount"] {
  background: #EBF6EA;
  color: #33AA31;
}

.container[data-discount-type=""] {
  background: #EBF6EA;
  color: #33AA31;
}

.container[data-discount-type="coupon"] {
  background: #EBF6EA;
  color: #33AA31;
}

.container[data-discount-type="silver"] {
  background: linear-gradient(97.92deg, #ACACAC 1.94%, rgba(227, 227, 227, 0.8) 49.86%),linear-gradient(0deg, #FFFFFF, #FFFFFF);
  color: #444444;
}

.container[data-discount-type="gold"] {
  background:linear-gradient(272.49deg, #F2CE54 2.09%, rgba(208, 182, 93, 0.8) 50%),linear-gradient(0deg, #B7902A, #B7902A);
  color: #FFFFF7;
}

.container[data-discount-type="platinum"] {
  background: linear-gradient(97.92deg, rgba(123, 119, 127, 0.25) 40.28%, #7B777F 97.78%),linear-gradient(0deg, #18181A, #18181A);
  color: #FFFAED;
}

@media (max-width: 768px) {
  .container {
    padding: 2px 4px;
  }
}
