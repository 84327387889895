.serviceContainer {
  background: white;
  padding: 24px;
  border-radius: 8px;
  display: grid;
  grid-template: auto / 1fr;
  width: 70%;
  align-self: center;
}

.serviceHeader {
  margin-top: -16px;
  display: flex;
  justify-self: end;
  margin-right: -16px;
  margin-bottom: -8px;
}

.close {
  width: 24px;
  height: 24px;
  background: #1f2c3814;
  padding: 4px;
  border-radius: 50%;
  cursor: pointer;
}

.serviceImgContainer {
  width: 431px;
  height: 366px;
}

.serviceImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.servicesName {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0%;
  vertical-align: middle;
  color: #242424;
}

.servicesDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
  vertical-align: middle;
  color: var(--secondary-contrast-text);
}

.startsFrom {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
  vertical-align: middle;
  color: var(--secondary-contrast-text);
  border-top: 1px solid #0000001a;
  padding-top: 16px;
}

.availablePost {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-2);
  background: #f4f8fe;
  border-radius: 3px;
  grid-column: 1 / span 2;
  text-align: center;
  padding: 14px;
}

.detailsContainer {
  display: grid;
  grid-template: auto / 1fr auto;
  gap: 16px;
}

.additionalInfo {
  display: grid;
  gap: 4px;
  height: fit-content;
}

.detailListContainer {
  display: grid;
  gap: 16px;
  height: fit-content;
}

.scrollableDetailList {
  display: grid;
  gap: 16px;
  height: fit-content;
  max-height: 270px;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 16px;
  margin-right: -24px;
}

.scrollableDetailListMaxHeight{
    max-height: 365px;
}

.scrollableDetailList::-webkit-scrollbar {
  width: 2px;
}

.scrollableDetailList::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.scrollableDetailList::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollableDetailList::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .serviceContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: flex-end;
    padding: 24px 16px 50px 16px;
    border-radius: 8px 8px 0 0;
    gap: 16px;
  }

  .detailsContainer {
    display: flex;
    flex-direction: column;
  }

  .serviceImgContainer {
    width: auto;
    height: 162px;
  }

  .scrollableDetailList {
    margin-right: -16px;
    gap: 12px;
  }

  .serviceHeader {
    margin: 0;
    justify-content: space-between;
  }

  .close {
    background: none;
    padding: 0;
  }

  .servicesNameMobile {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    vertical-align: middle;
  }

  .additionalInfo {
    gap: 2px;
  }

  .startsFrom {
    padding-top: 8px;
  }

  .availablePost {
    margin-top: -12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0%;
    padding: 8px;
    border-radius: 4px;
  }

  .scrollableDetailListMaxHeight{
    max-height: 300px;
    margin-bottom: 8px;
    padding-bottom: 12px;
    border-bottom: 1px solid #0000001A;
  }
}
