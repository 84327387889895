.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
}

.title {
  color: var(--primary-main);
  /* font-family: "Tantinotes", sans-serif; */
  font-family: var(--font-family);
}

.amenities {
  display: grid;
  gap: 16px;
}

.amenity {
  display: grid;
  grid-template: 50% 1fr/1fr;
  background: #3a5157;
  border-radius: 6px;
}

.amenityIcon {
  height: 50%;
  align-self: flex-end;
  justify-self: center;
}

.amenityTitle {
  justify-self: center;
  text-transform: capitalize;
  text-align: center;
  color: var(--primary-main);
  font-weight: 500;
}

@media (max-width: 768px) {
  .title {
    font-size: 32px;
    letter-spacing: 0.67px;
  }

  .amenities {
    grid-template: auto/1fr 1fr 1fr;
    justify-content: center;
  }

  .amenity {
    gap: 12px;
    padding: 12px;
    height: 110px;
  }

  .amenityTitle {
    font-size: 10px;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 74px;
    letter-spacing: 1.5px;
  }

  .amenities {
    grid-template: auto/repeat(4, 1fr);
    justify-content: space-between;
  }

  .amenity {
    gap: 16px;
    padding: 16px;
    height: 180px;
  }

  .amenityTitle {
    font-size: 14px;
  }
}
