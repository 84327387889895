.container {
  display: grid;
  grid-template: auto/auto auto;
  gap: 8px 12px;
  background: #f9fffd;
  border: 1px solid #d3f0e7;
  border-radius: 5px;
  min-height: 80px;
  justify-content: center;
  align-content: center;
}

.icon {
  grid-row: span 2;
}

.title {
  font-weight: bold;
  color: #2d9c7a;
  font-size: 16px;
}

.subtitle {
  color: #5f5f5f;
  font-size: 12px;
  font-weight: 500;
}
