@keyframes collage-animation {
  0% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-5px);
  }
}

.intro {
  position: relative;
  padding-top: 48px;
  padding-bottom: 16px;
}

.calligraphy {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

.title {
  margin-bottom: 0;
  color: #dd713a;
}

.description {
  color: #474e66;
  font-weight: 500;
}

.collage {
  height: 45vh;
  width: 100%;
  object-fit: contain;
  grid-column: 2;
  grid-row: 1/span 2;
  animation: collage-animation 5s ease-in-out infinite;
}

.balloon {
  /* font-family: "Smithen Script", sans-serif; */
  font-family: var(--font-family);
  font-size: 28px;
  background: var(--primary-main);
  color: var(--primary-contrast-text);
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  text-align: center;
  grid-column: span 2;
  justify-self: center;
  margin-bottom: 32px;
}

.pointTitle {
  color: #626366;
  align-self: flex-end;
  margin-bottom: 0;
  position: relative;
}

.pointDescription {
  color: #626366;
}

@media (max-width: 768px) {
  .intro {
    background: #fef8f4;
    margin: 0 -16px;
    padding: 0 16px;
  }

  .calligraphy {
    display: none;
  }

  .title {
    font-size: 20px;
  }

  .description {
    font-size: 14px;
  }

  .collage {
    display: none;
  }

  .points {
    padding-bottom: 64px;
  }

  .balloon {
    display: none;
  }

  .pointTitle {
    font-size: 16px;
  }

  .pointDescription {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 768px) {
  .intro {
    background: rgba(215,38,47,.02);
    display: grid;
    grid-template: auto/repeat(2, 1fr);
    gap: 0 52px;
    align-content: center;
    height: 65vh;
  }

  .title {
    font-size: 34px;
  }

  .description {
    font-size: 18px;
  }

  .points {
    display: grid;
    grid-template: auto/repeat(2, 1fr);
    gap: 0 64px;
    position: relative;
    padding-bottom: 128px;
  }

  .points::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(0deg,#fbfbfe,hsla(0,0%,100%,0));
    width: 64px;
    height: 320px;
    bottom: 0;
  }

  .balloon {
    display: flex;
  }

  .balloon::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 0;
    border-left: 2px dashed var(--primary-main);
    top: 0;
  }

  .pointTitle {
    font-size: 20px;
  }

  .pointTitle::before {
    content: "";
    background: var(--primary-main);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
  }

  .pointDescription {
    font-size: 16px;
    max-width: 32vw;
  }

  .odd.pointTitle {
    grid-column: 1;
    text-align: center;
  }

  .odd.pointTitle::before {
    left: calc(100% + 32px);
    transform: translate(-50%, -50%);
  }

  .odd.pointDescription {
    grid-column: 1;
    text-align: center;
    justify-self: flex-end;
  }

  .even.pointTitle {
    grid-column: 2;
    text-align: left;
  }

  .even::before {
    left: -32px;
    transform: translate(-50%, -50%);
  }

  .even.pointDescription {
    grid-column: 2;
    text-align: left;
  }
}
