.container {
  background: white;
  overflow: auto;
}

/* Webkit browsers */
.reviewItem::-webkit-scrollbar {
  width: 6px;
}

.reviewItem::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.reviewItem::-webkit-scrollbar-track {
  background: rgba(72, 72, 72, 0.04);
}

.close {
  cursor: pointer;
  z-index: 1;
}

.header {
  font-weight: 700;
  font-size: 32px;
  line-height: 38.73px;
  color: var(--secondary-contrast-text-3);
  align-content: center;
}

.titleContainer {
  font-weight: 700;
  color: var(--secondary-contrast-text-3);
}

.guestRatings {
  display: grid;
  grid-template: auto / auto auto 1fr;
  align-items: center;
  gap: 8px;
  margin-top: -8px;
}

.reviewItem{
  overflow: scroll;
}

.avgRating {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: var(--secondary-contrast-text);
}

.ratingCount {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  color: var(--secondary-contrast-text);
  margin-left: 6px;
}

.titleContainer {
  font-size: 24px;
  Line-height: 29.05px;
  grid-column: span 2;
  grid-row: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.stars {
  gap: 4px;
}

.ratingReviewsContainer{
  display: flex;
  align-items: center;
}

.divider{
  border-bottom: 1px solid #0000001A;
}

@media (max-width: 768px) {
  /* .container {
    padding: 24px 16px;
    grid-template: auto/1fr;
    width: 343px;
    align-self: center;
    max-height: 490px;
    height: 100%;
    align-content: flex-start;
  } */

  .container {
    border-radius: 6px;
    gap: 12px;
    display: grid;
    padding: 16px;
    width: 343px;
    height: 100%;
    max-height: 490px;
    align-self: center;
  }

  .divider{
    grid-row: 2;
    grid-column: 1 / span 2;
    margin-right: -16px;
    margin-left: -16px;
    margin-bottom: -12px;
  }

  .stars{
    gap: 3px;
  }

  .ratingCount{
    margin-left: 8px;
  }

  .reviewItem::-webkit-scrollbar {
    width: 4px;
  }

  .titleContainer{
    font-size: 16px;
    line-height: 19.36px;
  }

  .reviewItem{
    margin-right: -12px;
    padding-right: 8px;
    padding-top: 12px;
  }

  .avgRating {
    font-size: 16px;
    line-height: 19.36px;
  }

  .guestRatings{
    margin-top: 16px;
  }

  .close {
    width: 16px;
    height: 16px;
  }

  .ratingCount {
    font-size: 12px;
    line-height: 14.52px;
    text-decoration: none;
  }

  .guestReviewIcon{
    width: 12px !important;
    height: 12px !important;
  }

  .header{
    grid-row: 1;
    grid-column: 1 / span 2;
  }

  .ratingReviewsContainer{
    grid-row: 2;
    grid-column: 1;
    margin-top: -8px;
    gap: 4px;
  }
}

@media (min-width: 768px) {
  .container {
    border-radius: 8px;
    gap: 16px;
    display: grid;
    grid-template: auto / 1fr 77.3%;
    padding: 16px 32px 24px 32px;
    width: 888px;
    height: 100%;
    max-height: 892px;
    align-self: center;
  }

  .header{
    grid-row: 1;
    grid-column: 1 / span 2;
  }

  .divider{
    grid-row: 1;
    grid-column: 1 / span 2;
    margin-bottom: -24px;
    margin-left: -32px;
    margin-right: -32px;
  }

  .reviewItem{
    margin-right: -36px;
    padding-right: 30px;
    margin-top: 10px;
    padding-top: 16px;
  }
  .close {
    margin-right: -16px;
  }

  .ratingReviewsContainer{
    gap: 8px;
    grid-row: 1;
    grid-column: 2;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 8px 10px;
    width: fit-content;
    height: fit-content;
  }

  .guestReviewIcon{
    width: 16px !important;
    height: 16px !important;
  }
}