.container {
  display: grid;
  grid-template: auto/1fr 1fr;
  padding: 16px 16px;
  gap: 12px 0;
  z-index: 0;
}

.title {
  padding-bottom: 16px;
  margin-bottom: 16px;
  line-height: 24px;
  color: var(--secondary-contrast-text-3);
  border-bottom: 1px solid rgba(0,0,0,0.1);
  font-weight: 700;
  grid-column: span 2;
}

.destination {
  grid-column: span 2;
}

.destination :global .input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top: none;
  border-left: none;
  border-bottom: none;
}

.destination{
  align-content: center;
  border: 1px solid #ebebeb;
  padding: 8px 12px;
  border-radius: 4px;
}

.destination :global .expand-icon {
  top: 50% !important;
}

.stay {
  grid-column: span 2;
}

.searchContainer {
  z-index: -1;
  grid-column: span 2;
}

.search {
  margin-top: 8px;
  height: 48px;
  font-size: 16px;
  width: 100%;
}

.stay{
  min-height: auto;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 8px 12px;
}

.stay :global .label{
  /* grid-row: 1; */
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(120, 120, 120, 1);
  text-transform: capitalize;
  opacity: 80%;
}

.stay :global .labeltwo{
  /* grid-row: 1; */
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(120, 120, 120, 1);
  text-transform: capitalize;
  opacity: 80%;
}

.stay :global .value{
  font-size: 16px;
}

.occupancy{
  grid-column: span 2;
  padding: 8px 12px;
  margin: 0;
  border: 1px solid #ebebeb;
}

.occupancy :global .label {
  border-radius: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(120, 120, 120, 1);
  text-transform: capitalize;
  opacity: 80%;
}

@media (max-width: 768px) {
  .container {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .title {
    font-size: 14px;
  }


  .stay{
    padding: 12px;
  }

  .occupancy{
    padding: 12px;
  }

  .destination :global .input {
    background: none;
    width: -webkit-fill-available;
    font-size: 14px;
    padding: 0;
    border: none;
  }

  .destination :global .value {
    font-size: 14px;
  }


  .stay :global .value{
    font-size: 14px;
    line-height: unset;
  }

  .destination{
    padding: 12px;
  }

  .occupancy :global .input {
    font-size: 14px;
    line-height: unset;
  }

}

@media (min-width: 768px) {
  .container {
    border-radius: 6px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.10);
    background: white;
    max-width: 384px;
  }

  /* .mobileDialog {
    width: calc(100% - 48px) !important;
  } */

  .title {
    font-size: 16px;
  }

  .destination :global .input {
    background: none;
    width: -webkit-fill-available;
    font-size: 14px;
    padding: 0;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
  }

  .occupancy :global .input {
    font-size: 16px;
  }

}
