.container {
  display: grid;
  background: white;
  width: 849px;
  border-radius: 8px;
  grid-template: auto / auto 1fr ;
}

.close {
  cursor: pointer;
  grid-row: 1;
  justify-self: end;
  grid-column: 2;
  padding: 16px 16px 0px 0px;
  z-index: 1000;
}

.sendRequestTitle{
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: var(--secondary-contrast-text-3);
}

.contentContainer{
  display: grid;
  grid-template: auto 1fr / 1fr;
  grid-column: 1;
  grid-row: 1;
}

.content{
  display: grid;
  grid-template: auto auto 1fr / 1fr;
  padding: 8px 24px 8px 24px;
  gap: 6px;
  background-color: rgba(170, 49, 49, 0.05);
}

.thumbnailBackground Img{
  object-fit: cover;
  border-radius: 8px 0px 0px 0px;
}

.thumbnail{
  grid-row: 1;
  grid-column: 1;
}

.thumbnailBackground{
  display: grid;
  max-height: 232px;
}

.header{
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: var(--secondary-contrast-text-3);
}

.title{
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: var(--secondary-contrast-text);
}

.subtitle{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--secondary-contrast-text);
}

.needHelp {
  background-color: white;
  border-radius: 6px;
  padding: 20px;
  width: 382px;
  grid-row: 1;
  grid-column: 2;
}

.needHelp :global .needHelp {
  background: white;
  justify-content: flex-start;
  min-height: unset;
  color: #222222;
  font-size: 16px;
  font-weight: 700;
}

.submit {
  grid-column: span 2;
  height: 54px;
  font-size: 16px;
  border-radius: 6px;
  margin-top: 8px;
  padding: 12px 28px;
}

.action {
  grid-column: span 2;
}

.callIcon {
  margin-right: 4px;
  margin-top: -1px;
}

.callUs {
  padding: 12px 28px;
  border-radius: 6px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: var(--primary-main);
  border: 1px solid var(--primary-main);
  font-weight: 500;
}

.backdrop {
  z-index: 0;
  grid-row: 1;
  grid-column: 1;
  background: linear-gradient(180deg, rgba(251, 245, 245, 0) 60%, #FBF5F5 100%);
}

.orContainer {
  grid-column: span 2;
  position: relative;
}

.orContainer::before {
  position: absolute;
  content: "";
  z-index: 0;
  width: 100%;
  height: 1px;
  background: #e6e6e9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.or {
  color: #626366;
  font-weight: 500;
  font-size: 8px;
  border: 1px solid #e6e6e9;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

@media (max-width: 768px) {
  
  .close {
    margin: -10px 0 10px;
  }
}

@media (min-width: 768px) {
  /* .close {
    grid-row: 1;
    align-self: flex-start;
    margin-top: -22px;
    margin-right: -22px;
    grid-column: 2;
    justify-self: flex-end;
  } */
}
