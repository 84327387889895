.container {
  display: grid;
}

.title {
  grid-column: span 3;
}

.card {
  display: grid;
  gap: 8px;
  background-image: url("../../../assets/refer-n-earn/tier-bg.png");
  background-repeat: no-repeat;
  border-radius: 8px;
  align-items: center;
  padding: 32px 16px;
  background-size: cover;
  width: 100%;
  height: auto;
}

.cardTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: white;
}

.cardSubtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-line;
  color: #D1D1D1;
}

.cardSubtitle span {
  font-weight: 600;
  color: white;
}

.tierCard {
  max-width: 400px;
  height: auto;
  width: 100%;
  object-fit: contain;
  align-items: center;
}

@media(max-width: 768px) {
  .container {
    gap: 16px 12px;
    overflow-x: scroll;
  }

  .card {
    min-width: 290px;
    max-height: 280px;
    padding: 24px 16px;
  }

  .cardTitle {
    font-size: 16px;
    line-height: 19px;
  }

  .cardSubtitle {
    font-size: 12px;
    line-height: 17px;
  }
}

@media(min-width: 768px) {
  .container {
    gap: 32px 16px;
    grid-template: auto/repeat(3,1fr);
  }
}
