.container {
  display: grid;
  color: var(--secondary-contrast-text-3);
}

.title {
  font-weight: bold;
}

.description {
  margin: 0;
}

.highlights {
  display: grid;
  gap: 16px;
}

.highlight {
  background: white;
  border-radius: 6px;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template: 1fr auto/1fr;
  justify-items: center;
  gap: 16px;
  padding: 24px 16px;
}

.highlightTitle {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 768px) {
  .container {
    gap: 10px;
  }

  .title {
    font-size: 20px;
  }

  .description {
    font-size: 14px;
  }

  .highlights {
    grid-template: auto/repeat(auto-fit, minmax(150px, 1fr));
  }

  .highlightTitle {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .container {
    gap: 24px;
  }

  .title {
    font-size: 28px;
  }

  .description {
    font-size: 16px;
  }

  .highlights {
    grid-template: auto/repeat(auto-fit, minmax(200px, 1fr));
  }

  .highlightTitle {
    font-size: 16px;
  }
}
