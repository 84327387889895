.headerContainer {
  box-shadow: 0 2px 4px 0 #00000014;
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 500;
  font-size: 16px;
  height: var(--navbar-height);
}

.backIcon {
  margin-top: 8px;
}

.profileContainer {
  display: grid;
  grid-template: auto/1fr;
  gap: 32px;
  align-content: flex-start;
}

.avatarContainer {
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 8px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #B737351A;
  border-radius: 50%;
  padding: 6px;
  width: 64px;
  height: 64px;
  color: #B73735;
}

.navLinks {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
}

.navLink {
  display: grid;
  grid-template: auto/auto 1fr auto;
  align-items: center;
  font-size: 16px;
  gap: 16px;
  height: 52px;
  font-weight: 500;
  border-radius: 4px;
  padding: 0 4px;
  cursor: pointer;
}

.navLink[data-active="true"] {
  background: #B737351A;
  color: #b73735;
}

a.navLink, div.navLink {
  color: var(--secondary-contrast-text-2);
}

.icon {
  width: 32px;
  aspect-ratio: 1;
  align-self: center;
  justify-self: center;
}

.rewards {
  composes: navLink;
  grid-template: auto/auto 1fr auto auto;
}

.rewardPoints {
  margin-right: -8px;
}

@media (max-width: 768px) {
  .headerContainer[data-in-child-route='true'] {
    display: none;
  }

  .profileContainer {
    height: calc(100vh - var(--navbar-height));
    padding-top: 32px;
  }

  .profileContainer[data-in-child-route='true'] {
    display: none;
  }

  .navLink {
    padding-right: 16px;
  }

  .action {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .container {
    display: grid;
    grid-template: auto/auto 1fr;
    gap: 32px;
    padding-top: 52px;
    padding-bottom: 52px;
  }

  .headerContainer {
    display: none;
  }

  .profileContainer {
    width: 280px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 6px;
    padding: 16px;
    align-self: flex-start;
  }

  .action {
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 6px;
    min-height: 75vh;
  }
}