.container {
  background: white;
  padding: 0 24px 16px;
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
  max-width: 500px;
  align-self: center;
}

.couponContainer{
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  gap: 16px
}

.couponContainer::-webkit-scrollbar {
  width: 4px; 
}

.couponContainer::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 4px; 
}

.couponContainer::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.couponContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  color: var(--secondary-contrast-text);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.64px;
}

.close {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.manualContainer {
  display: grid;
  grid-template: auto/1fr auto;
  min-height: 50px;
}

.manual {
  border-radius: 3px 0 0 3px;
  background: transparent;
  outline: none;
  padding-left: 16px;
  color: var(--secondary-contrast-text);
  font-size: 14px;
  border: 1px solid #d8d8d8;
  border-right: none;
}

.manualApply {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #50953f;
  background: #50953f;
  color: white;
  border-radius: 0 3px 3px 0;
  outline: none;
  padding: 0 18px;
  font-size: 14px;
}

.coupon {
  display: grid;
  grid-template: auto/1fr auto;
  gap: 8px;
  padding: 12px 8px 12px 20px;
  color: var(--secondary-contrast-text);
  font-size: 14px;
  letter-spacing: 0.5px;
  background: rgba(183, 55, 53, .03);
  font-weight: 500;
  
}

.couponTitle {
  color: #cd5e24;
  font-weight: 700;
}

.couponApply {
  grid-row: span 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-left: 1px solid rgba(183, 55, 53, .1);
  background: white;
  padding: 0 16px;
  color: #b73735;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}

.noOffer {
  padding: 16px;
  color: #cd5e24;
  border: 1px dashed #cd5e24;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}

.noOffer a {
  display: inline-block;
  margin: 0 4px;
  font-weight: bold;
}

.needHelp {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  border-radius: 2px;
  background: hsla(0, 0%, 84.7%, .5);
  font-size: 13px;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.coupon[data-discount-type="silver"], .coupon[data-discount-type="silver"] .couponTitle, .coupon[data-discount-type="silver"] .couponApply {
  background: linear-gradient(254.44deg, #d5d5db 25.79%, #888aa0 72.65%);
  color: white;
}

.coupon[data-discount-type="gold"], .coupon[data-discount-type="gold"] .couponTitle, .coupon[data-discount-type="gold"] .couponApply {
  background: linear-gradient(252.28deg, #fccd87 2.32%, #dca24c 71.95%);
  color: white;
}

.coupon[data-discount-type="platinum"], .coupon[data-discount-type="platinum"] .couponTitle, .coupon[data-discount-type="platinum"] .couponApply {
  background: linear-gradient(255.48deg, #292828 5.57%, #211E1E 73.01%);
  color: white;
}

@media (max-width: 768px) {
  .container {
    width: 90%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 100%;
  }
}
