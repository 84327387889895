.container {
    display: grid;
    grid-template: 1fr auto auto/1fr;
    justify-items: center;
    text-align: center;
    gap: 10px;
}

.container .text {
    font-weight: 700;
    font-size: 24px;
}

.container .iconImage {
    height: 56px;
    width: 56px;
}

.container .bigImage {
    height: 104px;
    width: 100%;
}