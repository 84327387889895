.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
}

.title {
  color: var(--primary-main);
  /* font-family: "Tantinotes", sans-serif; */
  font-family: var(--font-family);
}

.activities {
  display: grid;
  gap: 16px;
}

.activity {
  display: flex;
  flex-direction: column;
}

.activityThumbnail {
  height: 160px;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.activityTitle {
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  font-size: 16px;
}

.showActivities {
  font-weight: bold;
  font-size: 14px;
  min-width: max-content;
  margin: 8px 0;
  padding: 16px 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--primary-main);
  border: 1px solid var(--primary-main);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: transparent;
}

@media (max-width: 768px) {
  .title {
    font-size: 32px;
    letter-spacing: 0.7px;
  }

  .activities {
    grid-template: auto/1fr 1fr;
  }

  .activityThumbnail {
    display: none;
  }

  .activityTitle::before {
    content: '';
    margin-right: 8px;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 74px;
    letter-spacing: 1.5px;
  }

  .activities {
    grid-template: auto/repeat(auto-fit, 280px);
    justify-content: space-between;
  }

  .activityTitle {
    margin-top: 8px;
  }
}
