.container {
  display: grid;
}

.properties{
  display: grid;
  gap: 16px;
}

.title{
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  color: #333;
}

.sendRequestContainer {
  position: sticky;
  margin-top: 24px;
  top: 40px;
  align-self: flex-start;
}


.sendRequest :global .need-help {
  font-weight: 700;
  background: transparent;
  color: var(--secondary-contrast-text);
  min-height: unset;
  justify-content: flex-start;
}

.locationFooter{
  grid-column: span 2;
}


@media (max-width: 768px) {
  .container {
    gap: 16px;
    padding-bottom: 32px;
  }

  .title{
    font-size: 20px;
    line-height: 24px;
  }

  .properties{
    gap: 12px;
    margin-top: 8px;
  }


  .sendRequestContainer{
    position: unset;
    grid-row: 2;
    margin-top: 16px;
    margin-bottom: 16px;
    max-width: 300px;
    justify-self: center;
  }

  .sendRequestContainer2{
    position: unset;
    margin-top: 16px;
    margin-bottom: 16px;
    max-width: 300px;
    justify-self: center;
  }

  .bannerSection{
    grid-column: span 1 !important;
  }

}

@media (min-width: 768px) {
  .container {
    grid-template: auto/1fr 30%;
    gap: 16px 32px;
    padding-bottom: 64px;
  }

  .properties{
    display: grid;
    grid-template: auto auto / 1fr 1fr;
    margin-top: 24px;
  }

  .title{
    grid-column: span 2;
  }

  .sendRequestContainer{
    grid-row: span 2;
  }

  .sendRequestContainer2{
    display: none;
  }

  .experienceSection{
    grid-column: span 2;
  }
}
