.container {
  background: white;
  display: grid;
  box-shadow: 0 1px 24px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid #dfdfdf;
  padding: 16px;
  position: relative;
}

.thumbnail :global .image {
  object-fit: cover;
}

.title {
  font-weight: 700;
  margin: 0;
}

.subtitle {
}

.specContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spec {
  padding: 0 8px;
  border-right: 1px solid rgba(53, 58, 60, 0.3);
  color: var(--secondary-contrast-text);
  font-weight: 500;
}

.spec:first-child {
  padding-left: 0;
}

.spec:last-child {
  padding-right: 0;
  border-right: none;
}

.viewOnMap {
  font-weight: 400;
  letter-spacing: 0.5px;
  text-decoration: underline;
  color: #1f2c38;
  margin: 0 12px;
}

.paymentStatus {
  align-self: flex-end;
  font-weight: 500;
  color: #00A699;
}

.pricingContainer {
  display: grid;
}

.price {
  font-weight: 700;
}

.netAmount {
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--secondary-contrast-text-2);
}

.view {
  text-transform: uppercase;
  color: var(--primary-main);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-right: -8px;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr;
    gap: 16px;
    border-radius: 4px;
  }

  .thumbnail {
    width: calc(100% + 32px);
    height: 216px;
    margin: -16px -16px 0 -16px;
    border-radius: 4px 4px 0 0;
  }

  .title {
    font-size: 16px;
  }

  .subtitle {
    font-size: 12px;
    margin-top: -12px;
  }

  .spec {
    font-size: 12px;
  }

  .paymentStatus {
    font-size: 10px;
    grid-column: 1;
    grid-row: 5;
  }

  .pricingContainer {
    font-size: 10px;
    grid-column: 1;
    grid-row: 5;
    grid-template: auto/auto 1fr;
  }

  .price {
    grid-column: 1;
    font-size: 12px;
    display: grid;
    grid-template: auto/auto auto;
    gap: 4px;
    align-items: flex-end;
  }

  .view {
    grid-row: 2;
    grid-column: 2;
    justify-self: flex-end;
  }

  .netAmount {
    font-size: 10px;
  }

  .viewOnMap {
    display: none;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: repeat(3, auto) 1fr/280px auto 1fr;
    gap: 20px 16px;
    border-radius: 6px;
  }

  .thumbnail {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    grid-row: span 5;
  }

  .title {
    font-size: 20px;
    padding-right: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    grid-column: span 2;
  }

  .subtitle {
    font-size: 14px;
    margin-top: -16px;
    grid-column: span 2;
  }

  .spec {
    font-size: 12px;
    grid-column: span 2;
  }

  .viewOnMap {
    font-size: 12px;
  }

  .paymentStatus {
    font-size: 14px;
    grid-column: 2;
    grid-row: 5;
  }

  .pricingContainer {
    font-size: 12px;
    align-self: flex-end;
    justify-self: flex-end;
    grid-column: 3;
    grid-row: 4/span 2;
    grid-template: auto/1fr;
  }

  .price {
    text-align: right;
    font-size: 18px;
  }

  .view {
    justify-self: flex-end;
    grid-row: 5;
    padding-top: 16px;
  }

  .netAmount {
    font-size: 11px;
  }
}
