.banner {
  display: grid;
  grid-template: 1fr 1fr/1fr;
  color: #ffffff;
  gap: 16px;
}

.bannerImage {
  grid-row: 1/span 3;
  grid-column: 1;
}

.bannerImage img {
  object-fit: cover;
}

.bannerTitle {
  margin: 0;
  grid-row: 1;
  grid-column: 1;
  align-self: flex-end;
  font-weight: bold;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.35);
}

.bannerEnquire {
  grid-row: 2;
  grid-column: 1;
  justify-self: flex-start;
  align-self: flex-start;
  border-radius: 4px;
}

.wellness {
  background: white;
  display: grid;
}

.wellnessTitle,
.aboutSectionTitle {
  font-weight: bold;
  color: var(--secondary-contrast-text-3);
  white-space: pre;
}

.wellnessDescription {
  color: var(--secondary-contrast-text);
}

.wellnessImage,
.aboutSectionImage {
  grid-row: span 2;
  width: 100%;
  object-fit: contain;
}

.detailTitle {
  font-weight: 600;
}

.detailSubtitle {
  font-weight: 500;
  margin-top: 8px;
}

.detailsContainer {
  background: #ffffff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 16px;
  display: grid;
  grid-template: auto/repeat(auto-fit, minmax(250px, 1fr));
}

.venue {
  display: grid;
  background: #F3F3F3;
}

.iconContainer {
  background: #4848481A;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 42px;
  height: 42px;
  margin-bottom: 16px;
}

.detailsBookNow,
.itineraryBookNow,
.inclusionBookNow {
  cursor: pointer;
  font-weight: 500;
  border-radius: 4px;
  text-transform: capitalize;
  margin-bottom: 24px;
}

.featureImage {
  width: 100%;
}

.featureImage img {
  object-fit: cover;
}

.featureTitle {
  font-weight: bold;
}

.inclusion {
  display: grid;
  color: var(--secondary-contrast-text);
  background: #F3F3F3;
}

.inclusionContent {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
  align-content: flex-start;
}

.inclusionTitle {
  font-weight: bold;
  color: var(--secondary-contrast-text-3);
}

.inclusionItem {
  display: grid;
  grid-template: auto/auto 1fr;
  align-items: center;
  gap: 16px;
  font-weight: 500;
}

.inclusionBanner {
  width: 100%;
  object-fit: cover;
}

.about {
  white-space: pre-wrap;
}

.tnc {
  display: grid;
  grid-template: auto/1fr;
  letter-spacing: 0.005em;
  color: var(--secondary-contrast-text);
  gap: 8px;
}

.tncTitle,
.faqTitle {
  font-weight: bold;
  color: var(--secondary-contrast-text-3);
}

.villaBranco {
  display: grid;
  grid-template: auto/1fr;
}

.villaBrancoTitle {
  color: var(--secondary-contrast-text-3);
  font-weight: bold;
}

.villaBrancoImages {
  display: grid;
}

.villaBrancoImage {
  width: 100%;
  object-fit: cover;
}

.villaBrancoDescription {
  color: var(--secondary-contrast-text-2);
}

.itineraryContainer {
  position: relative;
}

.itineraryTitle {
  font-weight: 700;
  color: #FFFFFF;
}

.itineraryBg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.itineraryItemContainer {
  display: grid;
  gap: 16px;
}

.itineraryItem {
  background: #FFFFFF;
  border-radius: 4px;
  display: grid;
  grid-template: auto/1fr;
  padding: 16px;
  align-content: flex-start;
}

.itineraryItemTitle {
  font-weight: 500;
}

.itineraryItemBorderIcon {
  margin-bottom: 12px;
}

.itineraryPlan {
  font-weight: 400;
  font-size: 14px;
  color: var(--secondary-contrast-text);
  height: 33px;
  display: grid;
  grid-template: auto/auto 45px 1fr;
  gap: 12px;
  align-items: center;
}

.itineraryPlan::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #c7ad9c;
}

.itineraryPlan span {
  color: var(--secondary-contrast-text-2);
}

@media (max-width: 768px) {
  .banner {
    height: calc(75vh - var(--navbar-height));
  }

  .bannerTitle {
    font-size: 32px;
  }

  .bannerEnquire {
    padding: 16px 24px;
  }

  .wellness {
    grid-template: auto/1fr;
    padding-top: 32px;
    padding-bottom: 32px;
    gap: 16px;
  }

  .wellnessTitle {
    font-size: 24px;
    margin-bottom: -16px;
  }

  .wellnessDescription {
    font-size: 14px;
    line-height: 26px;
  }

  .wellnessImage {
    display: none;
  }

  .detailsContainer {
    gap: 24px;
    padding: 32px 16px;
  }

  .details {
    display: grid;
    grid-template: auto/auto 1fr;
    gap: 0 16px;
  }

  .detailTitle {
    font-size: 16px;
  }

  .detailIcon {
    grid-row: span 2;
    align-self: center;
  }

  .detailsBookNow,
  .itineraryBookNow,
  .inclusionBookNow {
    height: 40px;
    margin: 16px 0;
    width: 100%;
  }

  .featureTitle {
    font-size: 18px;
  }

  .venue {
    grid-template: auto/1fr;
    gap: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .venueTitle {
    font-size: 16px;
  }

  .inclusion {
    grid-template: auto/1fr;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-top: 24px;
  }

  .inclusionItem {
    font-size: 14px;
  }

  .inclusionBanner {
    display: none;
  }

  .inclusionBookNow {
    padding: 12px 16px;
  }

  .retreatTitle {
    font-weight: 700;
    font-size: 24px;
  }

  .retreatFeatures {
    display: grid;
    overflow: auto;
    grid-template: auto/repeat(4, 280px);
    gap: 32px;
  }

  .retreat {
    display: grid;
    grid-template: auto/1fr;
    gap: 32px;
    margin: 32px 0;
  }

  .aboutSectionTitle {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  }

  .about {
    font-size: 14px;
    line-height: 26px;
  }

  .aboutSectionImage {
    height: 240px;
    background: #ECE5D7;
    border: 4px;
  }

  .itineraryContainer {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .itineraryTitle {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 12px;
  }

  .itineraryItemContainer {
    grid-template: auto/repeat(3, 90vw);
    overflow-y: auto;
    margin: 0 -16px;
    padding: 0 16px
  }

  .villaBranco {
    gap: 8px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .villaBrancoTitle {
    font-size: 18px;
  }

  .villaBrancoImages {
    grid-template: auto/repeat(4, 60vw);
    gap: 16px;
    overflow: auto;
  }

  .villaBrancoDescription {
    font-size: 14px;
    line-height: 26px;
  }

  .tnc {
    gap: 8px;
    font-size: 12px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .tncTitle {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .banner {
    height: calc(100vh - var(--navbar-height));
  }

  .bannerTitle {
    font-weight: 800;
    font-size: 52px;
    letter-spacing: 0.5px;
    width: 680px;
    white-space: pre;
  }

  .bannerEnquire {
    padding: 16px 32px;
  }

  .wellness {
    grid-template: auto/1fr 35vw;
    padding-top: 64px;
    padding-bottom: 64px;
    gap: 24px;
  }

  .wellnessTitle,
  .aboutSectionTitle {
    font-weight: 700;
    font-size: 42px;
    line-height: 51px;
    margin-bottom: -20px;
  }

  .wellnessDescription {
    font-size: 18px;
    line-height: 36px;
  }

  .venue {
    gap: 32px;
  }

  .venueTitle {
    font-weight: 700;
    font-size: 42px;
    margin-top: 24px;
    color: var(--secondary-contrast-text-3);
  }

  .detailsBookNow {
    justify-self: flex-end;
    padding: 16px 64px;
    font-size: 16px;
  }

  .detailsContainer {
    padding: 32px 24px;
  }

  .detailIcon {
    margin-bottom: 8px;
  }

  .detailTitle {
    font-size: 20px;
  }

  .detailSubtitle {
    font-size: 16px;
    margin-top: 8px;
  }

  .retreatTitle {
    font-weight: 700;
    font-size: 42px;
  }

  .retreatFeatures {
    display: grid;
    grid-template: auto/repeat(2, 1fr);
    gap: 32px;
  }

  .retreat {
    display: grid;
    grid-template: auto/1fr;
    gap: 32px;
    margin: 32px 0;
  }

  .featureTitle {
    font-size: 24px;
  }

  .featureContent {
    font-size: 18px;
    color: var(--secondary-contrast-text);
  }

  .featureImage {
    margin-bottom: 20px;
  }

  .borderIcon {
    margin: 8px 0 12px;
  }

  .inclusion {
    grid-template: auto/1fr 35vw;
    padding-top: 64px;
    padding-bottom: 64px;
    margin-top: 48px;
  }

  .inclusionTitle {
    font-weight: 700;
    font-size: 42px;

  }

  .inclusionItem {
    font-size: 16px;
    padding: 10px;
  }

  .inclusionBookNow {
    padding: 16px 32px;
    width: 280px;
    font-weight: 600;
    font-size: 16px;
    margin: 10px;
  }

  .about {
    font-size: 18px;
    line-height: 32px;
    color: var(--secondary-contrast-text);
  }

  .aboutSectionImage {
    height: 350px;
  }

  .itineraryContainer {
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
  }

  .itineraryItemContainer {
    grid-template: auto/repeat(3, 1fr);
  }

  .itineraryTitle {
    font-size: 42px;
    line-height: 51px;
    margin-bottom: 32px;
  }

  .itineraryBookNow {
    align-self: flex-end;
    margin-top: 16px;
    width: 220px;
    height: 40px;
    font-size: 16px;
  }

  .villaBranco {
    gap: 16px;
    padding-top: 48px;
    padding-bottom: 48px;
    text-align: center;
  }

  .villaBrancoTitle {
    font-size: 24px;
  }

  .villaBrancoImages {
    grid-template: auto/repeat(4, 1fr);
    gap: 16px;
  }

  .villaBrancoDescription {
    font-size: 18px;
    line-height: 26px;
  }

  .tnc {
    font-size: 16px;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .tncTitle,
  .faqTitle {
    font-size: 24px;
    margin-bottom: 16px;
  }
}