.container {
  background: white;
  padding: 0 16px 16px;
  display: grid;
  grid-template: auto / 1fr;
  gap: 16px;
  max-height: 513px;
  align-self: end;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}

.couponContainer {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  gap: 16px;
  margin-right: -16px;
  padding-right: 16px;
  margin-left: -16px;
  padding-left: 16px;
  margin-top: -8px;
  padding-top: 8px;
  padding-bottom: 120px;
  -webkit-overflow-scrolling: touch;
  position: relative;
  /* Ensure content is not clipped on iOS */
  height: auto;
  min-height: 0;
  /* Fix for iOS momentum scrolling */
  transform: translateZ(0);
}


.couponContainer::-webkit-scrollbar {
  width: 4px;
}

.couponContainer::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.couponContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.couponContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  color: var(--secondary-contrast-text-3);
  margin-left: -16px;
  margin-right: -16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
}

.close {
  cursor: pointer;
  width: 20px;
  padding: 3px;
  background: #1f2c381a;
  border-radius: 50%;
  height: 20px;
}

.manualContainer {
  display: grid;
  grid-template: auto/1fr auto;
  min-height: 50px;
}

.manual {
  background: #ffffff;
  outline: none;
  padding: 12px;
  color: var(--secondary-contrast-text);
  font-size: 14px;
  border: 1px solid #0000000d;
  grid-row: 1;
  grid-column: 1 / span 2;
  border-radius: 6px;
}

.manualCouponApplied{
  border: 1px solid #33AA31
 }

 .invalidCouponApplied{
  padding: 0 12px 8px;
  border: 1px solid #CC385A;
}

.codeApplied{
  font-weight: 400;
  font-size: 10px;
  line-height: 12.1px;
  letter-spacing: 0%;
  color: #33AA31;
  margin-left: 14px;
  margin-top: -20px;
}

.invalidCodeApplied {
  position: relative;
  margin-bottom: -300px;
  margin-top: 6px;
  z-index: 1;
  font-weight: 400;
  font-size: 10px;
  line-height: 12.1px;
  letter-spacing: 0%;
  height: fit-content;
  padding: 9px;
  width: 100%;
  border: 1px solid #AA31311A;
  background: linear-gradient(97.92deg,#AA313114 40.28%,#AA313114 97.78%),linear-gradient(0deg, white, white);
  border-radius: 4px;
  color: #751919;
  text-align: center;
}

/* Adding the arrow */
.invalidCodeApplied::after {
  content: "";
  position: absolute;
  top: -6px;
  left: 8%;
  transform: translateX(-50%);
  width: 0;
  background: #f4f8fe;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #AA31311A;
}

.manualApply {
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  background: #1100001a;
  color: #ffffff;
  margin: 4px;
  border-radius: 6px;
  padding: 8px 24px;
  font-size: 14px;
  grid-row: 1;
  grid-column: 2;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.manualApply[data-disabled="true"]{
  cursor: not-allowed;
}

.greenManualApply{
  background: #33AA31;
  cursor: pointer;
}

/* .coupon {
  display: grid;
  grid-template: auto / 1fr auto;
  gap: 8px;
  padding: 12px 16px;
  color: var(--secondary-contrast-text);
  font-size: 12px;
  line-height: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px #00000026;
  border: 1px solid #0000000D;
  border-radius: 4px;
} */

.coupon {
  display: grid;
  grid-template: auto / 1fr auto;
  gap: 4px;
  padding: 12px 16px;
  color: var(--secondary-contrast-text);
  font-size: 12px;
  line-height: 16px;
  background: #ffffff;
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0px 0px 6px 0px #00000026;
  position: relative;
}

.coupon::before,
.coupon::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 16px;
}

.coupon::before {
  right: -1px;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.05), 
    rgba(0, 0, 0, 0.02) 40%, 
    rgba(0, 0, 0, 0.08) 100% 
  ),  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border-right: none;
  border-radius: 8px 0 0 8px;
}

.coupon::after {
  left: -1px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.05), /* Light shadow effect on the left */
    rgba(0, 0, 0, 0.02) 40%, /* Fades in the middle */
    rgba(0, 0, 0, 0.08) 100% /* Darker on the right */
  ),  linear-gradient(0deg, #FFFFFF, #FFFFFF);

  border-radius: 0 8px 8px 0;
}


.couponTitle {
  font-weight: 600;
  color: var(--secondary-contrast-text-3);
}

.couponDescription {
  font-weight: 400;
  color: var(--secondary-contrast-text-2);
}

.savings {
  font-weight: 400;
  color: #33aa31;
}

.couponApply {
  border: none;
  background: none;
  font-weight: 600;
  color: #aa3131;
  outline: none;
  cursor: pointer;
  align-self: start;
}

.noOffer {
  padding: 16px;
  color: #cd5e24;
  border: 1px dashed #cd5e24;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}

.tncLink {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0%;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: Auto;
  text-decoration-skip-ink: auto;
  color: var(--secondary-contrast-text-2)66;
  align-content: flex-end;
  margin-bottom: -4px;
}

.noOffer a {
  display: inline-block;
  margin: 0 4px;
  font-weight: bold;
}

.needHelp {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  border-radius: 2px;
  background: hsla(0, 0%, 84.7%, 0.5);
  font-size: 13px;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.coupon[data-discount-type="silver"],
.coupon[data-discount-type="silver"] .couponTitle,
.coupon[data-discount-type="silver"] .couponApply {
  background: linear-gradient(254.44deg, #d5d5db 25.79%, #888aa0 72.65%);
  color: white;
}

.coupon[data-discount-type="gold"],
.coupon[data-discount-type="gold"] .couponTitle,
.coupon[data-discount-type="gold"] .couponApply {
  background: linear-gradient(252.28deg, #fccd87 2.32%, #dca24c 71.95%);
  color: white;
}

.coupon[data-discount-type="platinum"],
.coupon[data-discount-type="platinum"] .couponTitle,
.coupon[data-discount-type="platinum"] .couponApply {
  background: linear-gradient(255.48deg, #292828 5.57%, #211e1e 73.01%);
  color: white;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 100%;
  }
}
