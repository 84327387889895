.container {
  display: grid;
}

.banner :global .title, .banner :global .subtitle {
  display: none;
}

.banner :global .share {
  font-weight: 700;
  border: 1px solid var(--primary-main);
  background: var(--secondary-main);
  color: var(--primary-main);
  grid-row: 3;
  grid-column: 2;
  cursor: pointer;
}

.banner :global .view-photos {
  font-weight: 700;
  border: 1px solid var(--primary-main);
  background: var(--primary-main);
  color: var(--secondary-main);
  grid-row: 3;
  grid-column: 3;
  cursor: pointer;
  white-space: pre;
}

.banner :global .scroll-down {
  display: none;
}

.titleContainer {
  display: grid;
  grid-template: auto auto/1fr auto;
}

.title {
  margin: 0;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.subtitle {
  font-weight: 400;
  margin: -4px 0 0 0;
}

.startFromContainer {
  grid-row: span 2;
  text-align: right;
}

.price {
  margin-top: 4px;
  font-weight: 700;
}

.calculatorContainer {
  grid-row: span 11;
}

.description {
  white-space: pre-wrap;
  line-height: 1.7;
}

.houseRules {
  border-bottom: none;
}

.houseRules :global .title {
  color: var(--primary-main);
  font-family: "Tantinotes", sans-serif;
  padding: 16px;
}

.houseRules :global .read-more {
  color: var(--primary-main);
  border: 1px solid var(--primary-main);
}

.sendRequest {
  padding: 16px;
  background-color: #24303c;
  border-radius: 5px;
  border: 1px solid rgba(202, 165, 138, 0.3);
  box-shadow: 0 2px 25px 0 rgba(202, 165, 138, 0.24);
  position: sticky;
  top: 32px;
}

.sendRequest :global .need-help {
  background: none;
  justify-content: flex-start;
  min-height: unset;
  font-family: "Tantinotes", sans-serif;
  font-weight: 500;
  font-size: 36px;
  color: #dae3e6;
  margin-left: 8px;
}

.sendRequest :global .need-help-subtitle {
  margin-top: 0;
  color: #dae3e6;
  font-size: 12px;
}

.sendRequest label {
  color: #dae3e6;
}

.sendRequest input {
  background-color: transparent;
  color: white;
}

.sendRequest input::placeholder {
  color: white;
}

.divider, .dividerTwoColumn {
  border: 0.5px solid #91a3a74d;
}

.readMoreCollapsed {
  --content-peek: 200px;
  opacity: 1;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr;
    gap: 16px;
  }

  .houseRulesContainer {
    margin-bottom: -50px;
  }

  .banner {
    height: 50vh;
    margin-bottom: 16px;
  }

  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 14px;
  }

  .startFromContainer {
    font-size: 10px;
  }

  .price {
    font-size: 16px;
  }

  .calculatorContainer {
    display: none;
  }

  .description {
    font-size: 13px;
  }

  .elevated {
    margin: 16px -16px;
  }

  .houseRules :global .title {
    font-size: 32px;
    letter-spacing: 0.67px;
  }

  .divider {
    margin: 8px 0 24px;
  }

  .dividerTwoColumn {
    margin: 8px 0 8px;
  }

  .sendRequest {
    display: none;
  }

  .houseRules :global .collapse-container {
    grid-column: span 2;
  }

  .houseRules {
    gap: 0 12px;
    padding-bottom: 0;
    margin: 0;
    margin-bottom: -8px;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: auto/1fr 340px;
    gap: 24px 64px;
    padding-bottom: 64px;
  }

  .banner {
    grid-column: span 2;
    height: 80vh;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .title {
    font-size: 36px;
  }

  .subtitle {
    font-size: 20px;
  }

  .startFromContainer {
    font-size: 14px;
  }

  .houseRulesContainer, .cancellationPolicyContainer {
    grid-column: span 2;
  }

  .price {
    font-size: 24px;
  }

  .description {
    font-size: 15px;
  }

  .elevated {
    margin: 16px 0;
  }

  .houseRules {
    grid-column: span 2;
  }

  .houseRules :global .title {
    font-size: 74px;
    letter-spacing: 1.5px;
  }

  .divider {
    margin: 16px 0 28px;
  }

  .dividerTwoColumn {
    margin: 16px 0 28px;
    grid-column: span 2;
  }

  .twoColumn {
    grid-column: span 2;
  }
}