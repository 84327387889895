.container {
  gap: 16px;
}

.title {
  color: #D87036;
}

.content {
  color: var(--secondary-contrast-text);
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.highlight {
  color: #1F2C38;
  font-weight: 600;
}

.subContent {
  color: var(--secondary-contrast-text);
  font-size: 16px;
}

.subContent a,
.subContent a:visited {
  text-decoration: underline;
  color: #1F2C38;
  font-weight: 600;
}

.cta {
  align-self: start;
  font-size: 16px;
  font-weight: 600;
  padding: 14px 20px;
  text-transform: none;
  color: white;
}

.ctaLoggedIn {
  align-self: start;
  font-size: 16px;
  font-weight: 600;
  padding: 14px 20px;
  text-transform: none;
  color: white;
}

.cta:visited {
  color: white;
}

.ctaLoggedIn:visited {
  color: white;
}

@media(max-width:768px) {
  .content {
    font-size: 12px;
    max-width: 250px;
  }

  .logo {
    width: 32px;
    height: 32px;
  }

  .subContent {
    font-size: 12px;
    max-width: 250px;
  }

  .ctaContainer {
    position: fixed;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08);
    z-index: 1;
    padding: 8px 16px;
    margin: 0 -32px;
    width: 100%;
  }

  .cta {
    max-height: 48px;
    width: 100%;
  }

  .ctaLoggedIn {
    max-height: 48px;
    width: 100%;
  }

  .illustration {
    max-width: 250px;
    width: 100%;
    justify-self: center;
  }
}

@media(min-width:768px) {
  .container {
    grid-template: auto/auto 1fr;
    white-space: pre;
  }

  .title {
    margin: 0px;
  }

  .illustration {
    grid-column: 2;
    grid-row: 1/span 4;
    justify-self: flex-end;
    align-self: center;
    max-width: 380px;
    width: 100%;
  }

  .cta {
    width: 300px;
    height: 64px;
    margin-top: 16px;
  }

  .ctaLoggedIn {
    width: 300px;
    height: 64px;
    margin-top: 16px;
  }

  .ctaLoggedIn[data-referral-code=true] {
    display: none;
  }
}
