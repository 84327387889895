.container {
  display: grid;
  grid-template: 1fr auto auto 1fr/1fr;
  gap: 16px;
  justify-items: center;
  text-align: center;
  min-height: calc(100vh - var(--navbar-height));
}

.checkmark {
  width: 180px;
  height: 180px;
  margin-bottom: 16px;
  align-self: flex-end;
}

.thankYou {
  font-weight: 700;
  font-size: 24px;
  color: #474e66;
  text-transform: capitalize;
}

.message {
  font-weight: 700;
  color: #49ba8e;
}

.description {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #626366;
  align-self: flex-start;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .message {
    font-size: 28px;
  }
}

@media (min-width: 768px) {
  .message {
    font-size: 34px;
  }
}
