.room {
  width: 100%;
  padding: 8px;
  border: 1px solid #DDDDDD;
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 264px 1fr auto auto;
  gap: 16px;
  border-radius: 6px;
}

.room[ data-selected="true"]{
  border: 1px solid #1F2C3899;
  background: #1F2C3805;
}

.roomPreviewImage {
  display: none;
  height: 50px;
  width: 50px;
  border-radius: 4px;
}

.divider{
  border-left: 1px solid #0000001A;
  grid-column: 3;
  grid-row: 1 / span 5;
}

.roomName {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--secondary-contrast-text);
}

.bedType {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--secondary-contrast-text-2);
}

.roomAmenities {
  grid-column: span 2;
}

.roomDetails {
  grid-column: span 2;
}

.roomInventory {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  height: fit-content;
  width: fit-content;
  justify-self: self-end;
}

.inventoryStatus {
  display: inline-block;
  right: -10px;
  font-size: 11px;
  font-weight: 500;
  padding: 5px 15px 5px 5px;
}

.inventoryStatusAvailable {
  background: #D870360D;
  color: #D87036;
  padding: 8px;
  border-radius: 4px;
}

.inventoryStatusSoldOut {
  background: #FFECE3;
  color: #DA6528;
  padding: 8px;
  border-radius: 4px;
}

.roomPrice {
  grid-row: 4 / span 2;
  grid-column: 4;
  display: grid;
  text-align: end;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-contrast-text-2);
  gap:2px;

}

.roomSelect {
  grid-row: 2 / span 2;
  align-self: flex-end;
  grid-column: 4;
  width: 100%;
}

.roomSelect :global .input {
  max-height: 40px;
}

.roomSelect :global input::placeholder  {
  max-height: 40px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.amenityContainer {
  grid-row: 2 / span 3;
  height: fit-content;
  display: grid;
  gap: 10px;
  grid-column: 2;
  font-size: 14px;
}

.amenity {
  font-weight: 500;
  color: var(--secondary-contrast-text);
  display: flex;
  gap: 8px;
  align-items: center;
}

.amenityIcon{
  width: 24px;
  border: 1px solid #78787833;
  border-radius: 50%;
  padding: 4px;
}

.viewRoomDetails {
  font-weight: 500;
  font-size: 14px;
  align-content: center;
  line-height: 20px;
  cursor: pointer;
  text-decoration: underline;
  color: #1F2C38;
  grid-column: 2;
  grid-row: 5 / span 1;
}

.startFrom {
  gap: 4px;
  display: flex;
  font-size: 11px;
  color: var(--secondary-contrast-text-2);
  justify-content: flex-end;
  align-items: center;
}

.excTax {
  color: var(--secondary-contrast-text-2);
  text-transform: capitalize;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.strike {
  text-decoration: line-through;
  color: var(--secondary-contrast-text-2);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
}

.amount {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: var(--secondary-contrast-text-3);
}

.carouselContainer {
  display: grid;
  grid-template: auto/1fr;
  grid-row: span 5;
  position: relative;
}

.carousel {
  scroll-snap-type: x mandatory;
  grid-column: 1;
  grid-row: 1;
  height: 188px;
}

.imageItem {
  scroll-snap-align: start;
  overflow: hidden;
  width: 264px;
}

.imgSelect {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
  cursor: pointer;
  border-radius: 4px;
}

.imgSelect img {
  object-fit: cover;
}

.scrollCrumb {
  grid-column: 1;
  grid-row: 1;
  align-self: flex-end;
  justify-self: center;
}

.mobileRoomPrice {
  display: none;
}

.item {
  width: 20px;
}

.itemActive .inner {
  background: white;
}

.itemActive .outer {
  transform: none;
}

.inner {
  width: 8px;
  height: 8px;
  background: #626262;
  border: 1px solid white;
}

.outer {
  width: 8px;
  height: 8px;
  border: none;
}

.roomNameContainer{
  display: flex;
  flex-direction: column;
  gap:4px;
}

@media (max-width: 768px) {
  .roomHeader {
    grid-column: 1 / span 2;
    grid-row: 2;
    display: grid;
    grid-template: auto/auto 1fr;
  }

  .carouselContainer {
    grid-row: 1;
    grid-column: 1 / span 2;
  }

  .carousel{
    height: 184px;
  }

  .imageItem{
    width: calc(100vw - 50px);
  }

  .room {
    display: grid;
    grid-template: auto/1fr 1fr;
  }

  .roomName {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1;
    color: var(--secondary-contrast-text);
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 130px;
    display: inline-block;
    font-size: 12px;
  }

  .bedType {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;    
  }

  .roomPreviewImage {
    display: block;
    margin-right: 12px;
  }

  .amenityContainer {
    display: flex;
    grid-column: 1 / span 2;
    grid-row: 3;
    flex-direction: row;
    gap: 12px;
    border-top: 1px solid #E6E6E699;
    padding-top: 10px;
    margin-bottom: -2px;
    align-items: center;
  }

  .amenity {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--secondary-contrast-text);
    border: none;
    margin-bottom: -2px;
  }


  .roomInventory{
    grid-column: 1/span 2;
    justify-self: flex-end;
    width: -webkit-fill-available;
    margin: 0 -4px -4px -4px;
  }

  .viewRoomDetails {
    justify-self: flex-end;
    border: 1px solid #DADFE6;
    padding: 6px 10px;
    height: fit-content;
    align-self: center;
    border-radius: 50%;
  }

  .divider{
    border-bottom: 1px solid #E6E6E699;
    grid-row: 4;
    grid-column: 1 / span 2;
  }

  .roomSelect {
    grid-column: 2;
    grid-row: 2;
  }

  .amenityIcon {
    width: 24px;
  }

  .amenityName {
    display: inline-block;
  }

  .roomPrice{
    text-align: left;
    grid-column: 1 / span 2;
    align-items: center;
    width: calc(100% - 40px);
    grid-row: 5;
    gap: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  .startFrom {
    gap: 2px;
    justify-content: flex-start;
  }

  .price {
    font-size: 12px;
  }
  
  .inventoryStatusAvailable{
    padding: 6px;
  }

  .inventoryStatusSoldOut{
    padding: 6px;
  }

  .amount{
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .inventoryStatus{
    display: flex;
    justify-content: center;
  }

}
