.container {
  display: grid;
}

.section{
grid-column: span 2;
}
/* .spacer {
  grid-column: span 2;
  margin-bottom: 16px;
} */

.search {
  grid-column: span 2;
}

.titleContainer {
  display: grid;
  grid-template: auto/1fr auto;
  align-self: flex-start;
  grid-column: span 2;
}

.title {
  margin: 0;
  align-self: center;
  font-weight: 700;
}

.properties {
  display: grid;
  grid-template: auto / 1fr 1fr;
  gap: 40px;
  align-items: flex-start;
  grid-column: 2 span;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr 1fr;
    gap: 12px 16px;
  }

  .properties{
    display: grid;
    align-items: flex-start;
    grid-column: 2 span;
    grid-template: auto / 1fr;
    gap: 16px;
    border-radius: 4px;
  }

  .popularButton{
    display: none;
  }

  .titleContainer {
    grid-column: span 2;
  }

  .title {
   font-size: 12px; 
   }

  .sortDesktop {
     display: none ; 
  }

  .nearbyLocations {
    margin: 0 0 32px;
  }

  .gallery{
    margin-bottom: 32px;
  }

}

@media (min-width: 768px) {
  .container {
    grid-template: auto/1fr 30%;
    gap: 16px 32px;
  }

  .titleContainer {
    row-gap: 12px;
    margin-bottom: 16px;
  }

  .nearbyLocations {
    margin: 0 0 56px 0;
  }

  .gallery{
    margin-bottom: 56px;
  }
}