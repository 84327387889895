.container {
  display: flex;
  flex-direction: column;
}

.features {
  display: grid;
  gap: 35px;
  grid-template: auto / 1fr 1fr 1fr 1fr 1fr;
  overflow-x: scroll; /* Add horizontal scrolling */
  padding-bottom: 10px; /* Add padding to avoid scrollbar overlap */
}

.header {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  display: inline-block;
  color: #333;
  margin: 8px 0;
}

.title {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 700;
  color: #333;
  line-height: 20px;
}

.subtitle {
  margin-top: 4px;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-contrast-text-2);
}

.featuresContainer {
  max-width: 224px;
}

@media (max-width: 768px) {
  .container {
    gap: 8px;
    overflow: scroll;
  }

  .features {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr 1fr;
    overflow: scroll;
    overflow-y: auto;
    gap: 16px;
  }

  /* Custom scrollbar styles */
  .features::-webkit-scrollbar {
    height: 4px; /* Horizontal scrollbar height */
  }

  .features::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }

  .features::-webkit-scrollbar-track {
    background: rgba(72, 72, 72, 0.04);
  }

  /* Styles for other browsers */
  .features {
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: #ccc rgba(72, 72, 72, 0.04);
  }

  .icon {
    max-width: 56px;
  }

  .header {
    margin: 0;
    font-size: 20px;
    line-height: 24px;
  }

  .content {
    align-content: center;
    margin-left: 8px;
  }

  .title {
    font-size: 12px;
    margin-top: 0;
  }

  .subtitle {
    font-size: 8px;
    line-height: 12px;
    margin-top: 0;
  }

  .featuresContainer {
    display: grid;
    grid-template: auto / auto auto;
    min-width: 277px;
  }
}
