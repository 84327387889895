.container {
  display: grid;
  grid-template: auto/1fr;
  color: white;
}

.content{
  z-index: 0;
  display: grid;
  align-self: end;
  padding-left: calc((100vw - 1200px) / 2);
  padding-right: calc((100vw - 1200px) / 2);
  height: fit-content;
}


.carousel {
  scroll-snap-type: x mandatory;
  grid-column: 1;
}

.item {
  display: grid;
  scroll-snap-align: start;
  overflow: hidden;
  width: 100vw;
}

.scrollCrumb {
  grid-column: 1;
  grid-row: 1;
  align-self: flex-end;
  justify-self: center;
}

.img {
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 0;
  top: 0;
  grid-row: 1 / span 2;
  grid-column: 1 / span 2;
  object-position: center;
}

.img > img {
  object-fit: cover;
}


/* .background :global .image {
  object-fit: cover;
} */

/* .subtitle {
  text-align: right;
  justify-self: flex-end;
  align-self: flex-end;
  font-style: italic;
} */

.actions {
  display: grid;
  grid-template: auto/auto auto;
  justify-items: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  justify-self: flex-start;
  gap: 16px;
  margin-bottom: 410px;
}

.viewMore {
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  width: 200px;
  height: 54px;
  color: white;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: bold;
  border: 1px solid white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactUs {
  width: 200px;
  height: 54px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: bold;
}

.subtitle{
  font-size: 20px;
  letter-spacing: 0.6px;
  line-height: 28px;
  padding-top: 10px;
  margin: 0;
  font-weight: 600;
}

.title{
  font-size: 60px;
  letter-spacing: 0.6px;
  margin: 0;
}

@media (max-width: 768px) {
  .container {
    grid-template: 1fr / 1fr;
    gap: 16px;
    height: 40vh;
  }

  .content{
    padding-left: 16px;
    padding-right: 16px;
    grid-row: 1;
    grid-column: 1;
  }

  .carousel{
    grid-column: 1;
    grid-row: 1 ;
  }

  .viewMore{
    display: none;
  }

  .subtitletitle {
    text-align: center;
    padding-top: 50px;
    grid-column: 1 / span 2;
    margin: 0 16px;
    grid-row: 1 / span 2;
  }

  .title{
    font-size: 24px;
  }

  .subtitle{
    font-size: 14px;
    letter-spacing: 0.6px;
    line-height: 20px;
    padding-top: 0;
  }

  .actions {
    display: none;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: 1fr /1fr;
    gap: 32px;
    height: calc(100vh - var(--navbar-height));
  }

  .content{
    grid-row: 1;
    grid-column: 1;
    justify-content: center;
  }
  
  .carousel {
    grid-row: 1 ;
    height: 100%;
  }

  .subtitletitle {
    font-size: 40px;
    letter-spacing: 2px;
    line-height: 55px;
    align-self: center;
    text-align: center;
    padding-bottom: 500px;
    grid-row: 2;
    grid-column: 1 / span 2;
    padding-left: calc((100vw - 1200px) / 2);
    padding-right: calc((100vw - 1200px) / 2);
  }
}