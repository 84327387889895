.title {
  color: var(--secondary-contrast-text-3);
  margin: 32px 0 8px 0;
}

.description {
  color: var(--secondary-contrast-text);
  padding: 16px 0;
}

.container {
  display: grid;
  gap: 16px;
  grid-template: auto/repeat(auto-fit, minmax(280px, 1fr));
  margin: 24px 0 100px;
  min-height: 60vh;
  align-content: flex-start;
}

@media (max-width: 768px) {
  .title {
    font-size: 18px;
  }

  .description {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 30px;
  }

  .description {
    font-size: 16px;
  }
}