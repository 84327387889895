.container {
  position: relative;
  display: grid;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  object-fit: cover;
}

.overlay {
  background: linear-gradient(179.99deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.58) 53.62%, rgba(0, 0, 0, 0.6) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.title {
  font-weight: bold;
  margin: 0;
  color: white;
  z-index: 0;
  align-self: flex-end;
}

.subtitle {
  margin: 0;
  color: white;
  z-index: 0;
  font-weight: normal;
}

.sendRequest {
  z-index: 0;
  padding: 16px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.13);
  max-width: 400px;
}

.sendRequest :global .need-help {
  background: transparent;
  justify-content: flex-start;
  font-size: 20px;
  letter-spacing: 0.7px;
  line-height: 27px;
  color: var(--secondary-contrast-text-3);
  min-height: initial;
}

.sendRequest :global .need-help-subtitle {
  margin-top: 0;
  margin-bottom: 16px;
}

.sendRequest :global .submit {
  background: var(--primary-main);
  color: var(--primary-contrast-text);
  min-height: 52px;
}

.anchor {
  position: absolute;
  width: 0;
  height: 0;
  outline: none;
}

@media (max-width: 768px) {
  .container {
    grid-template: 1fr auto/1fr;
    gap: 16px;
  }

  .title {
    font-size: 24px;
    text-align: center;
  }

  .subtitle {
    font-size: 16px;
    text-align: center;
    margin-bottom: 64px;
  }

  .sendRequest {
    display: none;
  }

  .anchor {
    top: -60px;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: 1fr 1fr/1fr auto;
    gap: 16px 32px;
  }

  .title {
    font-size: 40px;
  }

  .subtitle {
    font-size: 20px;
  }

  .sendRequest {
    grid-row: span 2;
    align-self: center;
  }

  .anchor {
    top: -90px;
  }
}