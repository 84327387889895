.container {
  padding: 8px 8px 8px 16px;
  font-size: 14px;
  animation: snackbar-slide-in 0.2s;
  display: grid;
  grid-template: auto/1fr auto;
  gap: 12px;
  align-items: center;
  border-radius: 4px;
  font-weight: 400;
  min-height: 48px;
}

.container[data-severity="error"] {
  background: #fdeded;
  color: #5f2120;
}

.container[data-severity="warning"] {
  background: #fff4e5;
  color: #663c00;
}

.container[data-severity="info"] {
  background: #e5f6fd;
  color: #014361;
}

.container[data-severity="success"] {
  background: #edf7ed;
  color: #1e4620;
}

.clear {
  font-size: 32px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .container {
    bottom: 64px;
  }
}

@media (min-width: 768px) {
  .container {
    min-width: 400px;
    bottom: 16px;
  }
}