.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
}

.subtitle {
  white-space: pre-wrap;
}

.items {
  display: grid;
}

.item {
  display: flex;
  flex-direction: column;
}

.itemThumbnail {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.itemTitle {
  margin-top: 16px;
  text-align: center;
}

@media (max-width: 768px) {
  .items {
    grid-template: auto/1fr 1fr;
    gap: 16px;
  }

  .item:last-child:nth-child(odd) {
    grid-column: span 2;
  }

  .item:last-child:nth-child(odd) .itemThumbnail {
    aspect-ratio: unset;
    height: 225px;
  }
}

@media (min-width: 768px) {
  .title {
    text-align: center;
  }

  .subtitle {
    text-align: center;
  }

  .items {
    grid-template: auto/repeat(auto-fill, 375px);
    justify-content: center;
    gap: 32px;
  }
}