.theme {
  --primary-main: #caa58a;
  --primary-contrast-text: #24303c;
  --primary-light: #ffe9d8;
  --primary-dark: #a58570;

  --secondary-main: #24303c;
  --secondary-contrast-text: #ededed;

  --root-background: var(--secondary-main);

  --font-family: 'Montserrat', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
