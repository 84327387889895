.container {
  display: grid;
  grid-template: 0.65fr auto 1fr/1fr;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.background {
  grid-row: 1/span 3;
  grid-column: 1;
  aspect-ratio: 2/1;
  object-fit: cover;
  width: 100%;
}

.title {
  grid-row: 1;
  grid-column: 1;
  text-align: center;
  /* font-family: "Tantinotes", sans-serif; */
  font-family: var(--font-family);
  color: var(--primary-main);
  align-self: flex-end;
}

.subtitle {
  grid-row: 2;
  grid-column: 1;
  text-align: center;
  white-space: pre;
  font-weight: 500;
}

.discover {
  grid-row: 3;
  grid-column: 1;
  font-weight: 700;
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid var(--primary-main);
  color: var(--primary-contrast-text);
  width: fit-content;
  border-radius: 4px;
  justify-self: center;
  text-transform: uppercase;
}

.discover span {
  color: var(--primary-main);
}

@media (max-width: 768px) {
  .title {
    font-size: 32px;
    letter-spacing: 0.7px;
    line-height: 38px;
  }

  .subtitle {
    font-size: 12px;
    letter-spacing: 0.25px;
    line-height: 17px;
  }

  .discover {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 52px;
    letter-spacing: 1.1px;
    line-height: 62px;
  }

  .subtitle {
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 29px;
  }

  .discover {
    margin: 24px 0 0;
    padding: 16px 32px;
  }
}
