.container {
  display: grid;
}

.image :global .image {
  object-fit: cover;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr;
    gap: 24px;
  }

  .image {
    width: 100%;
    grid-row: 3;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: 40% 1fr/1fr auto;
    gap: 16px 64px;
  }

  .image {
    width: 380px;
    height: 450px;
    grid-row: span 2;
  }
}
