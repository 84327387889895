.header {
  display: flex;
  justify-content: space-between;
  margin: 2px;
  background: #f4f8fe;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--secondary-contrast-text-3);
  padding: 8px 10px;
}

.viewAllBtn {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.container {
  padding: 12px;
  display: grid;
  gap: 12px;
  overflow-x: scroll;
  max-height: 340px;
}

.contentContainer {
  display: grid;
  grid-template: auto / 50px auto;
  gap: 4px 10px;
  height: fit-content;
}

.title {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: var(--secondary-contrast-text);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.description {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--secondary-contrast-text-2);
  /* Multi-line ellipsis */
  display: -webkit-box;
  /* Limit text to 2 lines */
  -webkit-line-clamp: 2;
  line-clamp: 2;
  /* Ensures the text is treated as a vertical box */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.offerImg {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.tncLink {
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  font-variation-settings: "slnt" -10;
  text-decoration-line: underline;
  color: var(--secondary-contrast-text-2)99;
  grid-column: 2;
  justify-self: end;
  /* font-style: italic; */
  cursor: pointer;
}

.content {
  display: grid;
  gap: 4px;
  height: fit-content;
}

.hideDivider {
  display: none;
}

.divider {
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
  border-image: repeating-linear-gradient(to right,
      rgba(0, 0, 0, 0.1) 0px,
      rgba(0, 0, 0, 0.1) 5px,
      transparent 5px,
      transparent 10px) 1;
  margin: 8px 0 0 0;
  grid-column: 1 / span 2;
}

/* .whitedrop {
  position: absolute;
  border-radius: 4px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(175deg,
      rgba(255, 255, 255, 0) 85%,
      #ffffff 100%);
  pointer-events: none;
} */

@media (max-width: 768px) {
  .header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: var(--secondary-contrast-text-3);
    padding: 0px;
    background: transparent;
  }

  .carousel {
    scroll-snap-type: x mandatory;
    grid-column: 1;
    gap: 16px;
  }

  .item {
    display: grid;
    scroll-snap-align: start;
    overflow: hidden;
    width: calc(100vw - 32px);
  }

  .contentContainer {
    border: 1px solid #dadfe6;
    padding: 12px;
    border-radius: 4px;
    grid-template: auto / auto 1fr auto;
    gap: 8px;
  }

  .scrollCrumb {
    grid-column: 1;
    /* grid-row: 2; */
    align-self: flex-end;
    justify-self: center;
    margin-bottom: -4px;
  }

  .offerImg {
    width: 32px;
    height: 32px;
    align-self: center;
  }

  .title {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;

    /* Ellipsis styling */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tncLink {
    grid-column: 3;
    font-size: 8px;
    font-weight: 400;
    line-height: 10px;
    text-align: left;
    text-decoration-style: solid;
    align-content: flex-start;
    text-decoration-skip-ink: auto;
    font-variation-settings: "slnt" -10;
    margin: 0;
  }

  .content {
    gap: 4px;
  }
}