.container {
  display: grid;
}

.titleContainer {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
  align-content: center;
}

.title {
  color: #dd713a;
  margin: 0;
}

.subtitle {
  color: #474e66;
  font-weight: 500;
}

.banner {
  width: 100%;
  object-fit: contain;
  margin-bottom: 40px;
}

.inquiriesContainer {
  display: grid;
  align-content: flex-start;
}

.inquiry {
  display: grid;
  gap: 8px 16px;
  color: #474e66;
  border-radius: 6px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.10);
  padding: 20px 16px;
}

.inquiryIcon {
  width: 68px;
  height: 78px;
  object-fit: contain;
  grid-row: span 3;
}

.inquiryTitle {
  font-weight: 700;
  margin: 0;
}

.inquiryContact {
  font-weight: 500;
}

a.inquiryContact {
  color: #626366;
}

.sendRequestTitle {
  color: #474e66;
  font-weight: 700;
  grid-column: span 2;
  display: flex;
  align-items: center;
}

.submit {
  grid-column: span 2;
  height: 54px;
  font-size: 16px;
  margin-top: 16px;
  border-radius: 6px;
}

.address {
  display: grid;
  gap: 8px 16px;
  color: #474e66;
}

.addressIcon {
  grid-row: span 3;
  align-self: flex-start;
}

.addressTitle {
  font-weight: 700;
}

.addressLink {
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.45px;
  font-weight: 500;
}

a.addressLink {
  color: var(--primary-main);
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr;
    gap: 32px;
    padding: 48px 0;
  }

  .title {
    font-size: 18px;
  }

  .subtitle {
    font-size: 14px;
  }

  .banner {
    display: none;
  }

  .inquiriesContainer {
    grid-template: auto/repeat(3, 200px);
    gap: 16px;
    overflow-x: auto;
    padding: 16px;
    margin: -16px;
  }

  .inquiry {
    grid-template: auto/1fr;
    font-size: 10px;
  }

  .inquiryIcon {
    display: none;
  }

  .inquiryTitle {
    font-size: 14px;
  }

  .inquiryContact {
    font-size: 12px;
  }

  .sendRequestTitle {
    font-size: 16px;
    height: 32px;
  }

  .address {
    grid-template: auto/1fr;
    grid-row: 3;
  }

  .addressIcon {
    display: none;
  }

  .addressTitle {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .container {
    gap: 32px 100px;
    grid-template: auto/1fr 1fr;
    padding: 0 0 96px 0;
  }

  .title {
    font-size: 34px;
  }

  .subtitle {
    font-size: 28px;
  }

  .inquiriesContainer {
    grid-template: auto/1fr;
    gap: 20px;
    max-width: 460px;
    justify-self: flex-end;
    width: 100%;
  }

  .inquiry {
    grid-template: auto/auto 1fr;
    font-size: 14px;
  }

  .inquiryTitle {
    font-size: 16px;
  }

  .inquiryContact {
    font-size: 16px;
  }

  .sendRequest {
    border-radius: 6px;
    box-shadow: 0 0 5px 1px rgba(0,0,0,.1);
    background: white;
    padding: 16px;
    grid-row: 2/span 3;
    grid-column: 2;
    align-self: flex-start;
    max-width: 460px;
    position: relative;
    margin: 20px 0 20px 20px;
  }

  .sendRequest::before {
    position: absolute;
    content: '';
    background: url("../../assets/form-pseudo.svg");
    width: 90px;
    height: 100px;
    z-index: -1;
    transform: translate(-20%, -20%);
  }

  .sendRequest::after {
    position: absolute;
    content: '';
    background: url("../../assets/form-pseudo.svg");
    width: 90px;
    height: 100px;
    z-index: -1;
    bottom: 0;
    right: 0;
    transform: translate(20%, 20%);
  }

  .sendRequestTitle {
    font-size: 24px;
    height: 48px;
  }

  .address {
    grid-template: auto/auto 1fr;
    max-width: 460px;
    justify-self: flex-end;
  }

  .addressTitle {
    font-size: 20px;
  }
}
