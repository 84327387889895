.exclusiveCouponOffersContainer {
  display: grid;
  gap: 16px;
}
.header {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: var(--secondary-contrast-text-3);
}

.couponContainer {
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #0000000F;
  border: 1px solid #0000001A;
}


.manualContainer {
  display: grid;
  grid-template: auto/1fr auto;
  min-height: 50px;
  border-radius: 8px 8px 0 0;
  margin: 1px;
  background: #f4f8fe;
  padding: 8px 14px;
}

.manual {
  background: #ffffff;
  outline: none;
  padding: 12px;
  color: var(--secondary-contrast-text);
  font-size: 14px;
  border: 1px solid #0000000d;
  grid-row: 1;
  grid-column: 1 / span 2;
  border-radius: 6px;
  height: 48px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0%;
}


.couponApplied{
  padding: 0 12px 8px;
  border: 1px solid #33AA31;
}

.invalidCouponApplied{
  padding: 12px;
  border: 1px solid #CC385A;
}

 .manualCouponApplied{
  border: 1px solid #33AA31;
 }

.manualApply {
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  background: #1100001a;
  color: #ffffff;
  margin: 4px;
  border-radius: 6px;
  padding: 8px 24px;
  font-size: 14px;
  grid-row: 1;
  grid-column: 2;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}


.greenManualApply{
  background: #33AA31;
  cursor: pointer;
}

.greenSavings{
  color: #33AA31 !important;
}

.codeApplied{
  font-weight: 400;
  font-size: 10px;
  line-height: 12.1px;
  letter-spacing: 0%;
  color: #33AA31;
  margin-left: 14px;
  margin-top: -20px;
}


.invalidCodeApplied {
  position: relative;
  margin-bottom: -300px;
  margin-top: 6px;
  z-index: 1;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  height: fit-content;
  padding: 9px;
  width: 100%;
  border: 1px solid #AA31311A;
  background: linear-gradient(97.92deg,#AA313114 40.28%,#AA313114 97.78%),linear-gradient(0deg, white, white);
  border-radius: 4px;
  color: #751919;
  text-align: center;
}

/* Adding the arrow */
.invalidCodeApplied::after {
  content: "";
  position: absolute;
  top: -6px;
  left: 8%;
  transform: translateX(-50%);
  width: 0;
  background: #f4f8fe;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #AA31311A;
}


.couponCode {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: var(--secondary-contrast-text);
}

.couponDiscount {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--secondary-contrast-text-2);
}

.savings {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: var(--secondary-contrast-text);
}

.tncLink {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  cursor: pointer;
  color: var(--secondary-contrast-text-2)66;
  justify-self: self-end;
  margin-top: -8px;
}

.couponList {
  padding: 16px 14px;
  display: grid;
  gap: 16px;
  overflow-x: scroll;
  max-height: 394px;
}
.couponItem {
  display: flex;
  gap: 12px;
  border-bottom: 1px dashed #d7d7d7;
  padding-bottom: 16px;
  cursor: pointer;
}

.couponItem:last-child {
  border: none;
  padding-bottom: 0;
}

.radioButton {
  align-self: start;
}

input[type="radio"] {
  appearance: none; /* Removes default radio button */
  width: 16px;
  height: 15px;
  border: 1px solid #1F2C38; /* Custom border color */
  border-radius: 50%; /* Circular shape */
  display: inline-block;
  position: relative;
  background-color: transparent; /* Ensure background is clear */
  cursor: pointer;
  z-index: -1;
}

input[type="radio"]:checked::before {
  content: "";
  width: 10px; /* Inner circle size */
  height: 10px;
  background-color: #1F2C38; /* Inner dot color */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-53%, -50%);
  cursor: pointer;
}


.couponDetails {
  display: grid;
  gap: 4px;
  width: -webkit-fill-available;
}

.closeApplied{
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #787878;
  grid-row: 1;
  grid-column: 2;
  background: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 3px;
  align-self: center;
  margin-right: 12px;
}
