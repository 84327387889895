.couponCard {
  background: white;
  padding: 16px;
  border-radius: 8px;
  display: grid;
  grid-template: auto 1fr / 1fr;
  gap: 12px;
  width: 500px;
  align-self: center;
}

.couponHeader {
  display: grid;
  grid-template: auto / auto 1fr auto;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #0000001a;
  padding: 0 12px 12px 12px;
  margin-left: -16px;
  margin-right: -16px;
}

.couponCode {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-3);
}

.backArrow {
  border: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.close {
  grid-column: 3;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: #1f2c381a;
  padding: 4px;
  border-radius: 50%;
}

.termsListTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  color: var(--secondary-contrast-text);
}

.termsListConatiner {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 53vh;
}

.termsList {
  list-style-type: disc;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0px;
  color: var(--secondary-contrast-text-2);
  padding: 0;
  /* max-height: 140px; */
  padding-left: 16px;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 16px;
  margin-right: -16px;
}

.termsList::-webkit-scrollbar {
  width: 4px;
}

.termsList::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.termsList::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.termsList::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.validity {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0%;
  color: #33aa31;
}

.discountBox {
  color: #33AA31;
  /* Green text color */
  background: linear-gradient(to right, #E3F2E3 50%, rgba(255, 255, 255, 0) 100%);
  border-radius: 2px;
  padding: 6px;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
  width: fit-content;
}

@media (max-width: 768px) {
  .couponCard {
    border-radius: 4px 4px 0 0 ;
    align-self: flex-end;
  }

  .couponCode {
    font-size: 14px;
    line-height: 20px;
  }

  .close {
    width: 20px;
    height: 20px;
  }

  .validity {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0%;
  }

  .termsListTitle {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0px;
  }

  .couponHeader {
    padding: 0 16px 16px 16px;
    /* margin-left: -12px;
    margin-right: -12px; */
  }

  .termsList {
    padding-right: 12px;
    margin-right: -12px;
    padding-left: 13px;
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0px;
    padding-bottom: 80px;
  }

  .termsListConatiner {
    max-height: 58vh;
  }
}