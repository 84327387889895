.container {
  position: relative;
}

.anchor {
  position: absolute;
  left: 0;
  height: 0;
  width: 0;
  top: calc(-1 * var(--navbar-height) - 2px);
}

.title {
  font-weight: 700;
}

.video {
  width: 100%;
  aspect-ratio: 16/9;
}

@media (max-width: 768px) {
  .container {
    margin: 16px -16px 0 -16px;
  }

  .title {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .container {
    margin-top: 24px;
  }
  .title {
    font-size: 20px;
  }
}
