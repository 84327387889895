.floating {
  position: fixed;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  bottom: 20px;
  right: 20px;
  background: #da6528;
  color: white;
  border-radius: 26px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  transition: all ease-in-out 0.3s;
}

.floatingVisible {
  opacity: 1;
  transform: translateY(0%);
}

.floatingInvisible {
  opacity: 0;
  transform: translateY(calc(100% + 24px));
}

.navBar {
  margin: 0 -16px;
  position: sticky;
  bottom: 0;
  display: grid;
  color: white;
  background: #da6528;
  font-size: 14px;
  height: auto;
  box-shadow: 0 -1px 4px 0 rgba(0,0,0,0.2);
  transition: all ease-in-out 0.3s;
  z-index: 1;
}

.navBar a {
  color: white;
}

.navBarButton {
  flex: 1 1 0;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.navVisible {
  opacity: 1;
  transform: translateY(0%);
}

.navInvisible {
  opacity: 0;
  transform: translateY(100%);
}

.navNoDisplay {
  display: none;
}

.icon {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

.navBarButton:first-child {
  border-right: 1px solid white;
}

@media (max-width: 768px) {
  .floating {
    display: none;
  }

  .guestsHostedContainer{
    background: #FBF5F5;
    padding: 8px;
    color: rgba(72, 72, 72, 1);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    grid-column: span 2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  
  .guestsHostedIcon{
    width: 16px;
    height: 16px;
  }

  .icon {
    width: 18px;
    height: 18px;
  }

  .enquireNow{
    border-right: 1px solid #FFFFFF;
  }
}

@media (min-width: 768px) {
  .navBar {
    display: none;
  }

  .floatingVisible{
    z-index: 1;
  }
}