.container {
  padding: 16px;
  border-radius: 4px;
  display: grid;
  grid-template: auto/1fr auto;
  align-items: center;
  font-weight: 500;
}

.container[data-discount-type="silver"] {
  background: linear-gradient(254.44deg, #d5d5db 25.79%, #888aa0 72.65%);
  color: white;
}

.container[data-discount-type="gold"] {
  background: linear-gradient(252.28deg, #fccd87 2.32%, #dca24c 71.95%);
  color: white;
}

.container[data-discount-type="platinum"] {
  background: linear-gradient(255.48deg, #292828 5.57%, #211E1E 73.01%);
  color: white;
}

.amount {
  grid-row: span 2;
  font-weight: 500;
  padding-left: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.subtitle {
  font-weight: normal;
}

@media (max-width: 768px) {
  .amount {
    font-size: 12px;
  }

  .subtitle {
    font-size: 10px;
  }
}

@media (min-width: 768px) {
  .amount {
    font-size: 14px;
  }

  .subtitle {
    font-size: 12px;
  }
}