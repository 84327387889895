.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
  font-size: 14px;
}

.readMore {
  color: var(--primary-main);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  margin: 8px 0;
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.readMoreIcon {
  margin-left: 4px;
}

@media (max-width: 768px) {
}

@media (min-width: 768px) {
  .readMore {
    justify-content: flex-end;
  }
}
