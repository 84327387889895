.container {
  display: grid;
  gap: 16px;
  grid-template: auto/1fr;
  text-align: center;
}

.headerContainer {
  font-size: 16px;
  color: var(--secondary-contrast-text-3);
  font-weight: 600;
  line-height: 24px;
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 24px;
  align-items: center;
  border-bottom: 1px solid #FFFFFF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
  margin: 0 -16px;
  padding: 24px 20px 16px;
}

.icon {
  justify-self: center;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #222222;
}

.content {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #424242;
}

.goBack {
  width: fit-content;
  justify-self: center;
}