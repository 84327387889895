@keyframes menu-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.portal {
  --menu-margin-top: 0px;
  --menu-margin-left: 0px;
  --menu-margin-right: 0px;

  --menu-bottom: 0px;
  --menu-left: 0px;
  --menu-right: 0px;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  pointer-events: none;
}

.menu {
  pointer-events: all;
  display: none;
  position: absolute;
  background: white;
  padding: 8px 0;
  border-radius: 4px;
  min-width: 16px;
  min-height: 16px;
  outline: 0;
  top: calc(var(--menu-bottom) + var(--menu-margin-top) + 4px);
  box-shadow: rgb(0 0 0 / 20%) 0 5px 5px -3px, rgb(0 0 0 / 14%) 0 8px 10px 1px, rgb(0 0 0 / 12%) 0 3px 14px 2px;
}

.whiteExpand {
  width: 12px;
  height: 12px;
  margin-left: 4px;
}

.menu[data-open="true"] {
  gap: 8px;
  animation: menu-fade-in 0.2s;
  z-index: 1;
}

@media (min-width: 768px) {
  .menu[data-position='left'] {
    left: calc(var(--menu-left) + var(--menu-margin-left));
  }

  .menu[data-position='right'] {
    left: calc(var(--menu-right) - var(--menu-margin-right));
    transform: translateX(-100%);
  }
}