.container {
  background: white;
}

.couponContainer {
  display: grid;
  grid-template: auto / 1fr auto;
  padding: 12px 16px;
}

.couponApplied{
  background: #33AA3114;
}

.viewCouponContainer {
  display: flex;
  gap: 6px;
  align-items: center;
}

.offerContainer {
  display: flex;
  gap: 6px;
  align-items: center;
}

.viewCoupon {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0%;
  color: #33AA31;
}

.offerCount{
  font-weight: 500;
  font-size: 12px;
  line-height: 14.52px;
  letter-spacing: 0%;
  color: #33aa31;
  align-content: center;
}

.offerRemove{
  font-weight: 500;
  font-size: 12px;
  line-height: 14.52px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-2);
  align-content: center;
}
