.container {
  display: grid;
  grid-template: auto/1fr;
  border-radius: 4px;
}

.partial1 {
  display: grid;
  grid-template: auto/1fr;
  transition: all 0.5s;
}


.thumbnailBackground Img{
  object-fit: cover; 
  border-radius: 4px 4px 0px 0px;
}

.thumbnail{
  grid-row: 1;
  grid-column: 1;
}

.thumbnailBackground{
  display: grid;
}

.backdrop {
  z-index: 0;
  grid-row: 1;
  grid-column: 1;
  background: linear-gradient(180deg, rgba(251, 245, 245, 0) 60%, #FBF5F5 100%);
}

.content{
  display: grid;
  grid-template: auto auto 1fr / 1fr;
  padding: 8px 16px 16px 16px;
  gap: 8px;
  background-color: rgba(170, 49, 49, 0.05);
}

.header{
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: var(--secondary-contrast-text-3);
}

.title{
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: var(--secondary-contrast-text);
}

.subtitle{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--secondary-contrast-text);
}


@media (max-width: 768px) {
  .container {
    background: #FFFFFF;
  }

  .thumbnailBackground{
    grid-template: 200px auto / 1fr;
  }
  
}