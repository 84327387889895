.container {
  display: inline-grid;
  grid-template: auto/auto 1fr;
  align-content: flex-start;
  gap: 4px;
  font-weight: 400;
  cursor: pointer;
}

.input {
  display: none;
}

.checkmark {
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  border-radius: 2px;
  width: 24px;
  height: 24px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkmark[data-active="false"] {
  border: 1px solid gray;
}

.checkmark[data-active="true"] {
  background: var(--primary-main);
  color: var(--primary-contrast-text);
}

.error {
  color: #CC385A;
  font-size: 12px;
  grid-column: span 2;
}
