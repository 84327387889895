.label {
  display: grid;
  grid-template: auto/1fr auto;
  gap: 16px;
  cursor: pointer;
  --primary-color: #1f2c38;
  --primary-color-light: #c4c4c4;
  --accent-color: #ffffff;
}

.input {
  display: none;
}

.switch {
  width: 50px;
  height: 32px;
  background: var(--primary-color-light);

  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 0 2px;
  justify-content: flex-start;
  user-select: none;
}

.input:checked ~ .switch {
  justify-content: flex-end;
  background: var(--primary-color);
}

.toggle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background: var(--accent-color);
  color: var(--primary-color-light);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input:checked ~ .switch .toggle {
  content: "⨯";
}
