.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 4px;
  width: fit-content;
  cursor: pointer;
}

.tag {
  min-height: 36px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 34px;
  display: grid;
  grid-template: auto/auto 1fr;
  align-items: center;
  gap: 8px;
  padding: 4px 16px 4px 4px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #FFFFFF;
  font-size: 16px;
}

.star {
  width: 28px;
}

.available {
  font-weight: 400;
  font-size: 8px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
}
