.container {
  display: inline-grid;
  grid-template: auto/auto 1fr;
  gap: 8px;
  align-items: center;
  padding: 4px 8px;
  border: 1px solid rgba(31, 44, 56, 0.6);
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.15px;
  color: rgba(31, 44, 56, 0.8);
}