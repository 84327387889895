.container {
  display: grid;
  grid-template: auto/1fr;
  color: rgba(255, 255, 255, 0.8);
  background: #262626;
}

.question {
  font-weight: 500;
  letter-spacing: 0.5px;
}

.answer {
  letter-spacing: 0.5px;
}

.answer:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  .question {
    font-size: 14px;
    padding: 8px 0;
  }

  .answer {
    font-size: 14px;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
}

@media (min-width: 768px) {
  .container {
    margin: 0px -120px;
    padding: 68px 120px !important;
  }

  .question {
    font-size: 20px;
    padding: 12px 0;
  }

  .answer {
    font-size: 16px;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }

  .title {
    margin-left: 0px;
  }
}
