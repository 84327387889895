.container {
  display: inline-grid;
  grid-template: auto/1fr;
  align-content: flex-start;
  gap: 4px;
  font-weight: 400;
}

.label {
  text-transform: capitalize;
  grid-row: 1;
  color: #626366;
  opacity: 0.8;
  font-weight: 500;
  letter-spacing: 0.36px;
  font-size: 14px;
}

.input {
  background: white;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 12px;
  color: #474e66;
  font-size: 16px;
  outline: none;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input[data-error='true']:hover, .input[data-error='true']:focus {
  border-color: #CC385A;
}

.input[data-error='false']:hover, .input[data-error='false']:focus {
  border-color: #474e66;
}

.error {
  color: #CC385A;
  font-size: 12px;
}

@media (max-width: 768px) {
  .input{
    max-height: 36px;
  }
}
