.container {
  width: calc(100% - 32px);
  display: grid;
  grid-template: auto/1fr 1fr;
  gap: 16px;
  background: white;
  padding: 16px;
  border-radius: 6px;
}

.title {
  grid-row: 1;
  grid-column: 1/span 2;
  font-weight: 700;
  font-size: 14px;
  color: #222222;
}

.cancel {
  color: var(--secondary-contrast-text-2);
  grid-row: 1;
  grid-column: 2;
  margin: -8px -8px 0 0;
  cursor: pointer;
  justify-self: flex-end;
}

.check {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #00a699;
  color: white;
}

.eligibilitySuccess {
  grid-column: span 2;
  font-weight: 500;
  font-size: 12px;
  color: #00a699;
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 12px;
}

.warning {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #ca3b6d;
  color: white;
}

.eligibilityFailure {
  grid-column: span 2;
  font-weight: 500;
  font-size: 12px;
  color: #ca3b6d;
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 12px;
}

.content {
  grid-column: span 2;
  white-space: pre-wrap;
  font-size: 12px;
  color: var(--secondary-contrast-text);
}

.negativeIntent {
  background: transparent;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  user-select: none;
  appearance: none;
  line-height: 1.75;
  letter-spacing: 0.5px;
  padding: 6px 16px;
  border-radius: 4px;
  border: 1px solid #1f2c38;
  color: #1f2c38;
  font-family: var(--font-family);
}

.positiveIntent {
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;
  user-select: none;
  appearance: none;
  line-height: 1.75;
  letter-spacing: 0.5px;
  padding: 6px 16px;
  border-radius: 4px;
  background: var(--primary-main);
  color: var(--primary-contrast-text);
  font-family: var(--font-family);
}
