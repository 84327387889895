.container {
  background: #F5F5F7;
  color: var(--secondary-contrast-text);
  display: grid;
}

.title {
  font-weight: 800;
}

.testimonials {
  display: grid;
}

.testimonialItem {
  background: white;
  box-shadow: 0px 2px 5px rgba(39, 39, 39, 0.13);
  display: grid;
  grid-template: 1fr/auto;
}

.divider {
  border: 1px solid #E8E8E8;
}

.feedbackContainer {
  display: grid;
  align-items: center;
}

.companyName {
  font-weight: 600;
}

/* .feedbackContainer img {
  grid-row: 1/span 2;
} */

.ratingContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.ratingContainer .rating {
  font-weight: 600;
}

@media(max-width: 768px) {
  .container {
    padding-top: 24px;
    padding-bottom: 24px;
    gap: 16px;
  }

  .title {
    font-size: 18px;
    line-height: 22px;
  }

  .description {
    font-size: 12px;
    line-height: 18px;
  }

  .divider {
    margin: 8px 0;
  }

  .testimonials {
    grid-template: 1fr/repeat(3, 1fr);
    gap: 12px;
    overflow-x: scroll;
  }

  .testimonialItem {
    width: 296px;
    border-radius: 6px;
    padding: 16px 16px 8px;
  }

  .feedbackContainer {
    padding-bottom: 8px;
    padding-right: 8px;
  }

  /* .feedbackContainer img {
    width: 38px;
    height: 20px;
  } */

  .companyName {
    font-size: 12px;
    line-height: 15px;
  }

  .ratingContainer {
    gap: 4px;
  }

  .ratingContainer .rating {
    font-size: 10px;
    line-height: 12px;
  }

  .ratingIcon {
    width: 56px;
  }
}

@media(min-width: 768px) {
  .container {
    padding-top: 72px;
    padding-bottom: 104px;
    gap: 24px;
  }

  .description {
    font-size: 16px;
    line-height: 25px;
    padding-bottom: 16px;
  }

  .testimonials {
    gap: 24px;
    grid-template: auto/repeat(3, 1fr);
  }

  .testimonialItem {
    min-height: 310px;
    max-width: 390px;
    padding: 24px 16px;
    border-radius: 8px;
  }

  .title {
    font-size: 32px;
    line-height: 39px;
  }

  .companyName {
    font-size: 14px;
    line-height: 17px;
  }

  .divider {
    width: 100%;
    justify-self: center;
  }

  .feedbackContainer {
    padding-top: 8px;
  }

  .ratingContainer .rating {
    font-size: 14px;
    line-height: 17px;
  }
}