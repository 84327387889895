.scroller {
  display: inline-grid;
  grid-template: auto/auto auto;
  gap: 8px;
}

.scrollIcon {
  border-radius: 50%;
  border: 1px solid gray;
  cursor: pointer;
  width: 40px;
}

.similarPropIcon{
  border-radius: 50%;
  border: 1px solid gray;
  cursor: pointer;
  width: 24px;
  height: 24px;
}