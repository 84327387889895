.container {
  display: grid;
}

.titleContainer {
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 16px;
  background: rgba(183, 55, 53, 0.1);
  border-radius: 6px;
  padding: 32px 16px;
}

.titleIcon {
  width: 96px;
  height: 72px;
  object-fit: contain;
  background: transparent;
}

.title {
  color: #b73735;
  font-weight: 700;
  margin: 0;
}

.checklist {
  color: var(--secondary-contrast-text);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 28px;
  list-style: none;
  padding-left: 32px;
}

.checklistTitle {
  color: var(--secondary-contrast-text);
  font-weight: 700;
  letter-spacing: 0.7px;
  margin: 0 0 8px -24px;
}

.bullet {
  position: relative;
  margin-bottom: 16px;
}

.bulletDecoration {
  position: absolute;
  right: calc(100% + 10px);
  top: 8px;
  height: 12px;
}

.sendRequestContainer {
  display: grid;
  grid-template: auto/1fr;
  align-self: flex-start;
}

.sendRequestTitle {
  color: #474e66;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.submit {
  grid-column: span 2;
  height: 54px;
  font-size: 16px;
  margin-top: 16px;
  border-radius: 6px;
}

.quickLinks {
  display: grid;
  grid-template: auto/repeat(3, auto);
  gap: 8px;
  margin-top: 16px;
  border-radius: 4px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
  background: white;
  padding: 16px;
  color: #626366;
  font-size: 14px;
  font-weight: 500;
}

.quickLinksTitle {
  grid-column: span 2;
  color: #474e66;
  font-weight: 700;
}

.quickLinksSubtitle {
  grid-column: span 3;
}

.quickLink {
  position: relative;
  height: 128px;
  display: flex;
  align-items: flex-end;
}

.quickLinkThumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  z-index: 0;
  position: absolute;
  left: 0;
  right: 0;
}

.quickLinkTitle {
  color: white;
  background: rgba(0,0,0,.4);
  padding: 8px 4px;
  border-radius: 0 0 4px 4px;
  position: relative;
  width: 100%;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr;
    gap: 16px;
    padding: 32px 0;
  }

  .title {
    font-size: 14px;
  }

  .checklistTitle {
    font-size: 16px;
  }

  .sendRequestContainer {
    gap: 16px;
    grid-row: 3;
  }

  .sendRequestTitle {
    font-size: 16px;
    height: 32px;
  }

  .quickLinksTitle {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: auto/1fr minmax(auto, 450px);
    gap: 32px 64px;
    padding: 64px 0;
  }

  .title {
    font-size: 18px;
    align-self: center;
  }

  .checklistTitle {
    margin: 20px;
  }

  .sendRequestContainer {
    gap: 32px;
    grid-row: span 2;
    position: sticky;
    top: 84px;
  }

  .sendRequest {
    border-radius: 6px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    background: white;
    padding: 16px;
    max-width: 460px;
  }

  .sendRequestTitle {
    font-size: 24px;
    height: 48px;
  }

  .quickLinksTitle {
    font-size: 24px;
  }
}