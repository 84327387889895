.container {
  display: grid;
  gap: 16px;
  grid-column: 1/ span 2;
}

.reviews {
  display: grid;
  grid-template: auto / auto 1fr;
  gap: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  border-radius: 4px;
}

.nameInitials {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #c46f6f;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-transform: uppercase;
  text-align: -webkit-center;
  background: #eed6d6;
  align-content: center;
  margin-right: -2px;
}

.name {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;  
  text-transform: capitalize;
  color: var(--secondary-contrast-text);
  display: grid;
  grid-template: auto auto / auto;
  gap: 8px;
}

.guestName{
  display: flex;
  align-items: center;
}

.membershipStatus {
  margin-left: 12px;
}

.stayDate {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--secondary-contrast-text-2);
  display: flex;
  flex-direction: column;
  grid-row: 2;
}

.rating {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  color: var(--secondary-contrast-text);
  display: flex;
}

.ratingIcon {
  gap: 4px;
  align-self: center;
}

.ratingIcon{
  width: 10px !important;
  height: 10px !important;
}

.starsContainer{
  align-content: center;
  margin-left: 4px;
}

.reviewContent {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;  
  color: var(--secondary-contrast-text);
  grid-row: 2;
  grid-column: 1 / span 2;
  text-align: justify;
}

.divider {
  border: 0.5px solid #91a3a74d;
  margin: 0;
  grid-column: 1 / span 2;
}

.hideDivider {
  display: none;
}

.ratingNumber{
font-weight: 600;
}

.guestsLikedContainer {
  background-color: rgba(170, 49, 49, 0.04);
  padding: 16px;
  border-radius: 4px;
  grid-column: 1;
}

.guestsLikedTitle {
  font-weight: 500;
  color: var(--secondary-contrast-text-3);
  font-size: 14px;
  margin-bottom: 6px;
}

.likedItems {
  display: flex;
  gap: 8px;
  flex-wrap: wrap; 
}

.likedItem {
  background-color: rgba(170, 49, 49, 0.08);
  padding: 6px 12px;
  border-radius: 18px; 
  font-size: 14px;
  color: var(--secondary-contrast-text);
  font-weight: 500;
  white-space: nowrap; 
}

@media (max-width: 768px) {

    .container{
      display: grid;
        gap: 16px;
    }

  .nameInitials {
    width: 32px;
    height: 32px;
    font-size: 14px;
    line-height: 16px;
    margin-right: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .name {
    font-size: 14px;
    line-height: 16.94px;
    gap: 6px;
  }

  .reviews{
    border: none;
    padding: 0;
    gap: 10px;
  }

  .membershipStatus {
    font-size: 8px;
    line-height: 9.75px;
  }

  .stayDate {
    font-size: 12px;
    line-height: 14px;
    gap: 4px;
  }

  .rating {
    font-size: 12px;
    align-items: center;
    line-height: 14.52px;
  }

  .reviewContent {
    font-size: 12px;
    line-height: 20px;
  }

  .divider {
    margin-top: 6px;
  }

  .membershipStatus {
    margin-left: 8px;
  }

  .guestName{
    display: grid;
    grid-template: auto / 1fr auto;
  }
}
