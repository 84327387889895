.container {
  display: flex;
  gap: 16px;
  background: rgba(31, 44, 56, 0.06);
  border-radius: 4px;
  align-items: center;
}

.mealPlanContainer {
  display: grid;
  margin: 8px 0;
}

.mealIcon {
  width: 22px;
}

.content {
  flex: 1 1 0;
  color: var(--secondary-contrast-text-3);
  font-weight: 500;
  font-size: 14px;
}

.mealContent {
  display: grid;
  grid-template: auto / auto 150px;
  gap: 24px;
  font-weight: 500;
  font-size: 14px;
  Line-height: 24px;
  color: var(--secondary-contrast-text);
}

.mealContent p {
  margin: 0;
  max-width: 556px;
  align-content: center;
}

.mealPlanLink {
  color: #1F2C38;
  font-weight: 500;
  cursor: pointer;
}

.viewMenu {
  background-color: #FFFFFF;
  border: 1px solid #1F2C38;
  color: #1F2C38;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
  line-height: 12px;
  padding: 18px 30px;
  border-radius: 4px;
  text-align: center;
}

.veg {
  color: #2DA40E;
}

.nonVeg {
  color: #C21717;
}

.gst {
  font-weight: 500;
  font-size: 12px;
  border-radius: 6px;
  color: var(--secondary-contrast-text-2);
}

@media (max-width: 768px) {
  .mealPlanContainer {
    margin: 0;
  }

  .mealPlanLink {
    text-decoration: underline;
    margin: 8px;
  }

  .mealContent {
    display: grid;
    grid-template: 1fr / 1fr;
    gap: 16px;
    font-size: 14px;
    Line-height: 20px;
  }

  .viewMenu {
    font-size: 14px;
    padding: 15px;
  }

  .gst {
    font-weight: 400;
    padding-top: 8px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 12px 18px;
    margin-top: -8px;
  }
}

@media (min-width: 768px) {
  .container {
    padding: 16px;
    margin-top: -8px;
  }

  .content {
    display: flex;
    align-items: center;
  }

  .mealPlan {
    flex: 1 1 0;
  }

  .mealPlanLink {
    background-color: #FFFFFF;
    border: 1px solid #1F2C38;
    font-size: 12px;
    padding: 12px;
    border-radius: 4px;
    text-align: center;
  }
}