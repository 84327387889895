.container {
  position: absolute;
  padding: 16px 32px 24px;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  background: white;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  display: grid;
  grid-template: auto/1fr auto;
  gap: 16px;
  font-size: 14px;
  font-weight: 500;
  color: #626366;
}

.separator {
  height: 1px;
  background: rgba(0,0,0,0.2);
  grid-column: span 2;
}

.title {
  font-size: 16px;
  font-weight: 700;
  padding-top: 8px;
  grid-column: span 2;
}

.label {
  padding: 6px 0;
}

.amount {
  padding: 6px 0;
  text-align: right;
}

.close {
  cursor: pointer;
  position: absolute;
  top: calc(100% + 32px);
  left: 50%;
  transform: translate(-50%,-50%);
  background: white;
  padding: 8px;
  border-radius: 50%;
}
