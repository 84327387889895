.container {
  display: grid;
  grid-template: auto/1fr repeat(3, auto);
  gap: 16px 0;
  align-items: center;
  color: var(--secondary-contrast-text);
}

.title {
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0%;
}

.button {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: white;
  background: #1F2C38;
  /* background: transparent; */
  min-width: unset;
  padding: 2px;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
}

.button[ data-count="2"]{
  opacity: 20% !important;
  cursor: not-allowed;
  pointer-events: none;
  /* This prevents the click from happening */
}

.button[data-maxCount="true"]{
  opacity: 20% !important;
  cursor: not-allowed;
  pointer-events: none;
  /* This prevents the click from happening */
}

.childButton[data-maxCount="true"]{
  opacity: 20% !important;
  cursor: not-allowed;
  pointer-events: none;
  /* This prevents the click from happening */
}

.button:hover {
  background: rgba(0, 0, 0, 0.1);
}

.button:active {
  background: transparent;
}

.button img {
  width: 100%;
  height: 100%;
}

.childButton {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: white;
  background: #1F2C38;
  /* background: transparent; */
  min-width: unset;
  padding: 2px;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
}

.childButton[ data-count="0"] {
  opacity: 20% !important;
  cursor: not-allowed;
  pointer-events: none;
  /* This prevents the click from happening */
}

.childButton:hover {
  background: rgba(0, 0, 0, 0.1);
}

.childButton:active {
  background: transparent;
}

.childButton img {
  width: 100%;
  height: 100%;
}

.number {
  color: var(--secondary-contrast-text-3);
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0%;
  text-align: center;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number[ data-count="0"] {
  font-weight: 400 !important;
}

.separator {
  background: #979797;
  height: 1px;
  opacity: 0.4;
  grid-column: span 4;
}

.error {
  grid-column: span 4;
  color: #CC385A;
  font-size: 12px;
}

.childrenSubtitle {
  color: var(--secondary-contrast-text-2);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0%;

}

@media (max-width: 768px) {
  .title {
    font-size: 14px;
  }

  .container {
    padding: 0;
    gap: 16px 0;
  }

  .childTitle {
    display: grid;
    gap: 4px
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 16px;
  }
}