.container {
  display: grid;
  grid-template: 1fr / 1fr 22% 22% auto;
  padding: 14px 8px;
  gap: 4px;
  background-color: #F0F8F3;
  border: 1px solid #E6F2EB;
  border-radius: 2px;
}

.redContainer{
  display: grid;
  grid-template: 1fr / 1fr 22% 22% auto;
  padding: 14px 8px;
  gap: 4px;
  background: #F8F0F0;
  border: 1px solid #F2E6E6;
  border-radius: 2px;
}

.vegNonVegContainer{
  display: grid;
  grid-template: 1fr / 1fr 20% 20% auto;
  padding: 14px 8px;
  gap: 4px;
  background: linear-gradient(90deg, #F0F8F3 10%, #F8F0F0 100%);
  border-radius: 2px;
  position: relative;
}

.vegNonVegContainer::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 2px; 
  padding: 1px; 
  background:linear-gradient(90deg, #E6F2EB 0%, #F2E6E6 100%);
  mask: linear-gradient(#fff 0 0) content-box, 
  linear-gradient(#fff 0 0);
  mask-composite: exclude; /* Standard property */
  -webkit-mask-composite: xor;
}

.mealName {
  font-weight: 500;
  color: var(--secondary-contrast-text);
  text-overflow: ellipsis;
  overflow: hidden;
}

.mealType {
  font-weight: 400;
  color: var(--secondary-contrast-text-2);
  font-size: 12px;
}

.mealPrice {
  font-weight: 500;
  color: var(--secondary-contrast-text);
  text-align: end;
}

.arrowIcon{
    align-self: center;
    padding: 2px 4px;
}

@media (max-width: 768px) {

  .container{
    grid-template: 1fr / 1fr 23% 23% auto;
    padding: 12px 8px ;
  }

  .redContainer{
    grid-template: 1fr / 1fr 23% 23% auto;
    padding: 12px 8px ;
  }

  .vegNonVegContainer{
    grid-template: 1fr / 1fr 23% 23% auto;
    padding: 12px 8px ;
  }
  .mealPrice {
    font-size: 14px;
    align-content: center;
  }

  .mealName {
    font-size: 14px;
    line-height: 18px;
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .mealPrice {
    font-size: 14px;
    align-content: center;
  }

  .mealName {
    font-size: 14px;
    align-content: center;
  }
}
