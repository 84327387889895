.villaTagClassName {
    display: grid;
    grid-template: auto / 1fr auto ;
    gap: 4px;
}

.tagIconContainer{
    width: 14px;
    height: 14px;
    background: #0000001A;
    border-radius: 50%;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.tagIcon{
    width: 10px;
    height: 10px;
}

