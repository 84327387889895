.container {
  display: grid;
  grid-template: auto/1fr;
  color: white;
  position: relative;
}

.background {
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.title {
  font-weight: bold;
  margin: 0;
}

@media (max-width: 768px) {
  .container {
    height: 70vh;
    gap: 8px;
    align-content: flex-start;
    padding-top: 32px;
  }

  .title {
    font-size: 32px;
    letter-spacing: 0.3px;
  }

  .description {
    font-size: 14px;
    letter-spacing: 0.6px;
    line-height: 20px;
  }
}

@media (min-width: 768px) {
  .container {
    height: 80vh;
    gap: 32px;
    align-content: center;
    justify-items: center;
  }

  .title {
    font-size: 52px;
    letter-spacing: 1.7px;
    text-align: center;
  }

  .description {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-align: center;
    max-width: 895px;
    line-height: 30px;
  }
}
