.reviewRatings {
  cursor: pointer;
  display: grid;
  grid-template: 1fr / 1fr auto;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.avgRating {
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  padding-right: 16px;
  font-size: 20px;
  line-height: 16px;
  font-weight: 600;
  color: var(--secondary-contrast-text-3);
}

.layoutStars {
  gap: 2px;
}

.reviewText {
  font-size: 12px;
  line-height: 16px;
  color: #1f2c38;
  text-decoration: underline;
}

.reviewCount {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 20px;
  margin-left: 16px;
  font-weight: 600;
  color: var(--secondary-contrast-text);
  line-height: 16px;
}

@media (max-width: 768px) {
  .reviewRatings {
    display: none;
  }

  .responsiveReviewRatings {
    display: grid;
    padding: 0 0 24px 0;
    margin: 0;
    grid-template: auto / auto 1fr;
    align-items: center;
    width: fit-content;
    text-align: start;
  }

  .avgRating {
    gap: 8px;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    color: var(--secondary-contrast-text);
    display: flex;
    border: none;
    padding-right: 0;
    margin-right: 0;
    align-items: center;
  }

  .reviewCount {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: var(--secondary-contrast-text);
    margin: 0px;
    flex-direction: row;
  }

  .reviewCount::before {
    content: "•";
    color: var(--secondary-contrast-text);
    margin: 0px 4px 0px 8px;
    font-size: 14px;
    vertical-align: middle;
  }

  .ratingIcon {
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }

  .reviewText {
    text-decoration: underline;
  }

  .layoutStarsIcon {
    width: 10px !important;
    height: 10px !important;
  }
}

@media (min-width: 768px) {
  .ratingIcon {
    display: none;
  }

  .layoutStarsIcon {
    width: 14px !important;
    height: 14px !important;
  }

  .responsiveReviewRatings {
    display: none;
  }
}