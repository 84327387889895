.button {
  color: var(--secondary-contrast-text);
  font-weight: 500;
  border: 1px solid #b7b7b7;
  font-family: var(--font-family);
  min-width: 165px;
  background: white;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  user-select: none;
  appearance: none;
  text-transform: uppercase;
  line-height: 1.75;
  letter-spacing: 0.5px;
  padding: 6px 16px;
}

.button :global .MuiButton-label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  transition: all 0.3s;
  margin-right: 6px;
  height: 12px;
}

.iconFlipped {
  transform: rotate(180deg);
}

.button {
  background: #ffffff;
  border: 1px solid #F0F0F0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.3px;
  color: var(--secondary-contrast-text);
  position: relative;
}

.icon {
  margin-left: 6px;
}

.sortIcon{
  width: 12px;
  height: 12px;
}

.menu {
  grid-template: auto/1fr;
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  display: none;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #F0F0F0;
  padding: 8px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
}

.menu[data-visible='true'] {
  display: grid;
  width: 160px;
  z-index: 1;
}

.item {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 16px;
  font-weight: 500;
}

.item[data-selected='true'] {
  background: #f7f7f7;
}

.sortByContainer{
  display: flex;
  align-items: center;
  gap: 5px
}

.sortByText{
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: var(--secondary-contrast-text-2);
}


@media (max-width: 768px) {
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .button {
    font-size: 12px;
  }
}