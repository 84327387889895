.container{
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.form {
  display: grid;
  grid-template: auto/repeat(2, calc(50% - 4px));
  gap: 8px;
  align-content: flex-start;
}

.twoColumn {
  grid-column: span 2;
}

.twoColumn input , .twoColumn input::placeholder{
  font-family: var(--font-family) !important;
  font-weight: 420;
}

.twoColumn input::placeholder{
  font-size: 14px;
}

.msgColumn textarea , .msgColumn input::placeholder{
  font-weight: 420;
  font-family: var(--font-family) !important;
 
}

.phoneNo input , .phoneNo input::placeholder{
  font-weight: 420;
  font-family: var(--font-family) !important;
}

.phoneNo input{
  color: var(--secondary-contrast-text);
}

.twoColumn input{
  color: var(--secondary-contrast-text);
}

.title {
  /* color: var(--secondary-contrast-text-3); */
  margin: 12px;
  font-weight: 700;
  text-align: center;
}

.verify {
  cursor: pointer;
  border: none;
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
  margin: 0 0 8px;
  background: var(--primary-main);
  color: var(--primary-contrast-text);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  height: 45px;
  margin-top: 8px;
  align-self: flex-end;
}

.successTitle{
  margin-top: 16px;
  font-size: 14px;
}

.callUs {
  padding: 12px 28px;
  border-radius: 6px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: var(--primary-main);
  border: 1px solid var(--primary-main);
  font-weight: 500;
  margin-top: 8px;
}

.callIcon {
  margin-right: 4px;
  margin-top: -1px;
}

.collapseClassName{
  grid-column: span 2 ;
}

/* .contentClassName{
  display: none;
} */

.submit {
  grid-column: span 2;
  border: none;
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
  margin: 0 0 8px;
  text-transform: capitalize;
  background: var(--primary-main);
  color: var(--primary-contrast-text);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  height: 45px;
  margin-top: 8px;
  align-self: flex-end;
}

@media (min-width: 768px) {
  .verify {
    align-self: flex-start;
  }

  .container{
    padding: 20px 16px;
  }

  .phoneExt {
    height: 44px;
  }

  .respCall {
    display: none;
  }
}


@media (max-width: 768px) {

  .container{
    padding: 16px;
  }

  .webCall {
    display: none;
  }

  .callUs{
    display: none;
  }

  .successTitle{
    margin-top: 8px;
  }

  .twoColumn input{
    font-size: 14px;
  }

  .msgColumn textarea{
    font-size: 14px;
  }

  .phoneNo input{
    font-size: 14px;
  }

  .phoneExt input::placeholder{
    font-size: 14px;
  }

  .twoColumn input::placeholder{
    font-size: 14px;
  }

}

