@font-face {
  font-family: 'Smithen Script';
  font-style: normal;
  font-weight: 400;
  src: local("sans-serif"), url(../../assets/fonts/Smithen-Script.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Tantinotes';
  font-style: normal;
  font-weight: 400;
  src: local("sans-serif"), url(../../assets/fonts/Tantinotes.otf) format('opentype'), url(../../assets/fonts/Tantinotes.ttf) format('truetype');
  font-display: swap;
}

:root {
  --font-family: 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --primary-main: #b73735;
  --primary-contrast-text: #ffffff;
  --primary-light: #B737357F;
  --primary-dark: #b737351a;

  --secondary-main: #ffffff;
  --secondary-contrast-text: #484848;
  --secondary-contrast-text-2: #787878;
  --secondary-contrast-text-3: #232323;

  --root-background: #ffffff;
  --header-background: #ffffff;

  --border-color: var(--secondary-contrast-text-2);
}

html {
  background: var(--root-background);
  color: var(--secondary-contrast-text);
  opacity: 1;
}

body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body[data-scrollable='false'] {
  overflow: hidden;
}

:global .portal {
  overflow: auto;
}

a,
a:visited {
  text-decoration: unset;
  color: unset;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  -webkit-appearance: none;
}

* {
  box-sizing: border-box;
  letter-spacing: 0px;
}

@media (max-width: 768px) {
  :root {
    --navbar-height: 54px;
  }

  body {
    max-width: 100%;
    padding: 0 16px;
    margin: 0;
  }
}

@media (min-width: 768px) {
  :root {
    --navbar-height: 80px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  body {
    max-width: 712px;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  body {
    max-width: 960px;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  body {
    max-width: 1100px;
    margin: 0 auto;
  }
}

@media (min-width: 1440px) {
  body {
    max-width: 1200px;
    margin: 0 auto;
  }
}