.loginButton {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 16px 28px;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--secondary-contrast-text);
  margin-bottom: 8px;
}

.referralName {
  font-weight: 600;
  font-size: 16px;
}

.points {
  font-weight: 700;
}

.referralContainer {
  text-align: center;
  font-size: 16px;
  display: grid;
  grid-template: auto / 1fr;
  gap: 8px;
}

.referralContainer .icon {
  height: 48px;
  width: 48px;
  margin-right: 8px;
}

.referralDetails {
  display: grid;
  justify-items: center;
  grid-template: 1fr 1fr/ 1fr;
  gap: 8px;
  text-align: center;
}

.tier {
  font-size: 12px;
  font-weight: 500;
  color: #a7a7a7;
}

@media (max-width: 768px) {
  .referralContainer {
    text-align: left;
  }

  .icon {
    display: inline-block;
  }

  .referralName {
    display: inline-block;
  }

  .referralDetails {
    grid-template: 1fr / auto 1fr;
    justify-items: left;
    text-align: left;
  }

  .availText {
    display: inline;
  }
}
