.banner {
  position: relative;
  display: grid;
  grid-template: auto/1fr;
  color: white;
  align-items: center;
  align-content: center;
  justify-items: center;
  background: #992828;
  overflow: hidden;
  text-align: center;
}

.title {
  position: relative;
}

.subtitle {
  position: relative;
  max-width: 800px;
}

.bannerCta {
  position: relative;
  background: white;
  padding: 24px 28px;
  font-weight: 700;
  letter-spacing: 1.65px;
  margin-top: 32px;
  border-radius: 6px;
  text-transform: uppercase;
}

a.bannerCta {
  color: var(--primary-main);
}

.bannerImage {
  position: absolute;
  width: 100%;
  left: 0;
  object-fit: cover;
}

.intro {
  background: white;
  display: grid;
  color: var(--secondary-contrast-text-3);
}

.introTitle {
  align-self: flex-end;
}

.introSubtitle {
  align-self: flex-start;
}

.introIllustration {
  grid-row: span 2;
  width: 100%;
  object-fit: contain;
}

.sectionTitle {
  text-align: center;
  letter-spacing: 1px;
  font-weight: 700;
}

.sectionSubtitle {
  text-align: center;
  font-size: 16px;
  color: #050614;
}

.sectionSubtitle b {
  color: var(--primary-main);
}

.sectionCta {
  justify-self: center;
  padding: 20px 32px;
  letter-spacing: 1.6px;
  border-radius: 6px;
  font-weight: 700;
  margin-top: 32px;
}

a.sectionCta {
  color: white;
}

.incentiveContainer {
  display: grid;
}

.incentive {
  display: grid;
  gap: 24px;
  background: white;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.incentiveIcon {
  height: 32px;
  justify-self: center;
}

.incentiveTitle {
  text-align: center;
  color: #050614;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  font-weight: 500;
}

.benefitContainer {
  display: grid;
  grid-template: auto/1fr;
  overflow: hidden;
  background: white;
}

.benefitCarousel {
  display: grid;
  grid-template: auto/repeat(5, auto);
  overflow: auto;
  padding: 16px;
}

.benefit {
  background: white;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, .12);
  padding: 32px 20px;
  color: #050614;
  border-radius: 10px;
  font-size: 16px;
}

.benefit :global .title {
  font-size: 18px;
  letter-spacing: 0.67px;
  font-weight: 700;
}

.benefit ul {
  letter-spacing: 0.45px;
  list-style: "-  " outside;
  outline: 28px;
  font-weight: 500;
  line-height: 28px;
}

.benefit li {
  margin-bottom: 16px;
}

.locationContainer {
  display: grid;
  grid-template: auto/1fr;
}

.locations {
  display: grid;
  gap: 24px;
}

.location {
  display: grid;
  grid-template: auto/1fr;
  gap: 1px;
  color: var(--secondary-contrast-text);
  letter-spacing: 1.1px;
}

.locationThumbnail {
  border-radius: 5px;
  object-fit: cover;
}

.locationTitle {
  font-weight: 700;
  text-align: center;
  margin-top: 8px;
}

.locationSubtitle {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
}

.featuresContainer {
  display: grid;
  grid-template: auto/1fr;
  background: white;
}

.property {
  display: grid;
  grid-template: auto/1fr;
  gap: 4px;
  padding: 16px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, .12);
}

.propertyThumbnail {
  width: calc(100% + 32px);
  margin: -16px 16px 0;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
}

.propertyTitle {
  color: #050614;
  font-size: 16px;
  letter-spacing: 0.9px;
  line-height: 21px;
  font-weight: 700;
  margin-top: 12px;
}

.propertySubtitle {
  color: #050614;
  font-size: 12px;
  letter-spacing: 0.67px;
  line-height: 16px;
  margin-bottom: 8px;
}

.propertyCarousel {
  gap: 16px;
  padding: 16px;
  margin: -16px 0;
}

.propertyFeature {
  display: inline-flex;
  flex-direction: column;
  justify-self: flex-start;
  position: relative;
  padding: 0 16px;
}

.propertyFeature :global .subtitle {
  opacity: 0.7;
  font-size: 8px;
  letter-spacing: 0.06px;
  text-align: center;
  color: var(--secondary-contrast-text);
}

.propertyFeature :global .title {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.07px;
  text-align: center;
  color: #7d5715;
}

.propertyFeature::before {
  content: url("../../assets/featured-leaf-left.svg");
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-40%);
}

.propertyFeature::after {
  content: url("../../assets/featured-leaf-right.svg");
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-40%);
}

.propertyScroller {
  justify-self: flex-end;
}

.sendRequestContainer {
  position: relative;
  display: grid;
}

.sendRequestIllustration {
  opacity: 0.3;
  width: 100%;
}

.sendRequest {
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}

.sendRequestAnchor {
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  top: calc(var(--navbar-height) + 2px);
}

@media (max-width: 768px) {
  .section {
    margin-bottom: 24px;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .banner {
    height: 65vh;
  }

  .title {
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 34px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
  }

  .bannerCta {
    margin-bottom: 48px;
  }

  .bannerImage {
    bottom: 32px;
  }

  .intro {
    text-align: center;
    grid-template: auto/1fr;
    gap: 24px;
    font-size: 16px;
    padding-top: 48px;
    padding-bottom: 48px;
    margin-top: -24px;
  }

  .introIllustration {
    display: none;
  }

  .sectionTitle {
    font-size: 20px;
  }

  .sectionSubtitle {
    margin-bottom: 24px;
    margin-top: 8px;
  }

  .sectionCta {
    display: none;
  }

  .incentiveContainer {
    grid-template: auto/1fr;
    gap: 8px;
  }

  .incentiveContainer b {
    display: block;
  }

  .incentiveContainer .sectionCta {
    display: none;
  }

  .incentive {
    grid-template: auto/280px;
    padding: 32px 16px;
    align-items: center;
    width: 360px;
    justify-content: center;
    margin: 0 auto 16px;
  }

  .incentiveIcon {
    display: none;
  }

  .benefitContainer {
    gap: 8px;
  }

  .benefitCarousel {
    gap: 16px;
    margin: -16px -16px -8px -28px;
  }

  .benefit {
    width: 300px;
  }

  .locationContainer {
    gap: 8px;
  }

  .locations {
    grid-template: auto/repeat(auto-fit, 110px);
    justify-content: space-evenly;
  }

  .locationThumbnail {
    width: 110px;
    height: 110px;
  }

  .locationTitle {
    font-size: 14px;
  }

  .featuresContainer {
    gap: 8px;
  }

  .property {
    width: 260px;
  }

  .propertyThumbnail {
    height: 200px;
  }

  .sendRequestContainer {
    grid-template: auto/1fr;
    gap: 8px;
  }

  .sendRequestIllustration {
    display: none;
  }

  .sendRequest {
    padding: 16px;
  }
}

@media (min-width: 768px) {
  .section {
    margin-bottom: 48px;
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .banner {
    height: 80vh;
  }

  .title {
    font-size: 40px;
    letter-spacing: 2px;
    line-height: 58px;
    max-width: 800px;
  }

  .subtitle {
    font-size: 20px;
    letter-spacing: 1.11px;
    line-height: 30px;
    font-weight: 500;
  }

  .bannerImage {
    top: 0;
    height: 100%;
  }

  .intro {
    grid-template: auto/1fr minmax(450px, 35%);
    gap: 32px;
    font-size: 20px;
    padding-top: 72px;
    padding-bottom: 72px;
    margin-top: -48px;
  }

  .sectionTitle {
    font-size: 28px;
  }

  .sectionSubtitle {
    margin-bottom: 32px;
  }

  .incentiveContainer {
    grid-template: auto/repeat(4, 1fr);
    gap: 16px;
  }

  .incentiveContainer .sectionTitle,
  .incentiveContainer .sectionSubtitle,
  .incentiveContainer .sectionCta {
    grid-column: span 4;
  }

  .incentive {
    grid-template: auto 1fr/auto;
    padding: 48px 16px 32px;
  }

  .benefitContainer {
    gap: 16px;
  }

  .benefitCarousel {
    gap: 32px;
    margin: -16px 0;
  }

  .locationContainer {
    gap: 16px;
  }

  .locations {
    grid-template: auto/repeat(auto-fit, 230px);
    justify-content: center;
  }

  .locationThumbnail {
    width: 230px;
    height: 230px;
  }

  .locationTitle {
    font-size: 18px;
  }

  .featuresContainer {
    gap: 16px;
  }

  .property {
    width: 320px;
  }

  .propertyThumbnail {
    height: 260px;
  }

  .sendRequestContainer {
    grid-template: auto/1fr 400px;
    gap: 16px 48px;
  }

  .sendRequestContainer .sectionTitle,
  .sendRequestContainer .sectionSubtitle {
    grid-column: span 2;
  }

  .sendRequest {
    background: white;
    padding: 20px 16px;
    margin-top: 32px;
  }
}