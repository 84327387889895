.gimmick {
  width: 100%;
  display: grid;
  grid-template: 1fr auto auto/1fr;
  gap: 6px;
  align-items: flex-end;
  justify-items: center;
  font-weight: 500;
}

.gimmick[data-tier="silver"] {
  --llp-gimmick-progress-background-color: #3a3b49;
  --llp-gimmick-progress-background-text-color: #9a9caf;
  --llp-gimmick-progress-foreground-color: #c6c7d9;
  --llp-gimmick-progress-foreground-text-color: #3a3b49;

  --llp-gimmick-inner-circle-color: rgba(255, 255, 255, 0.12);
  --llp-gimmick-tier-color: linear-gradient(180deg, #f7f7f7 20%, #f9de99 86.67%);
}

.gimmick[data-tier="gold"] {
  --llp-gimmick-progress-background-color: #49433a;
  --llp-gimmick-progress-background-text-color: #CB9647;
  --llp-gimmick-progress-foreground-color: #f0e8d2;
  --llp-gimmick-progress-foreground-text-color: #3a3b49;

  --llp-gimmick-inner-circle-color: rgba(255, 255, 255, 0.12);
  --llp-gimmick-tier-color: linear-gradient(180deg, #f7f7f7 20%, #595151 86.67%);
}

.gimmick[data-tier="platinum"] {
  --llp-gimmick-progress-background-color: rgba(255, 255, 255, 0.12);
  --llp-gimmick-progress-background-text-color: #CB9647;
  --llp-gimmick-progress-foreground-color: #f0e8d2;
  --llp-gimmick-progress-foreground-text-color: #3a3b49;

  --llp-gimmick-inner-circle-color: rgba(255, 255, 255, 0.12);
  --llp-gimmick-tier-color: linear-gradient(180deg, #f7f7f7 20%, #595151 86.67%);
}

.gimmickProgressMarker {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}

.gimmick[data-tier="platinum"] .gimmickProgressMarker,
.gimmick[data-tier="platinum"] .gimmickProgressMarkerText,
.gimmick[data-tier="platinum"] .gimmickProgressForeground {
  display: none;
}

.nightIcon {
  margin-bottom: 8px;
  grid-row: 1;
  grid-column: 1;
}

.gimmick[data-tier="platinum"] .nightIcon {
  display: none;
}

.unlock {
  color: #e3e4f9;
  grid-row: 2;
  grid-column: 1;
}

.gimmick[data-tier="platinum"] .unlock {
  display: none;
}

.nextTier {
  grid-column: 1;
  grid-row: 3;
  font-weight: 500;
  letter-spacing: 0.23em;
  text-transform: uppercase;
  background: var(--llp-gimmick-tier-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0 0.508898px 1.0178px rgba(0, 0, 0, 0.25);
}

.speedometer {
  grid-row: 1/span 3;
  grid-column: 1;
  width: 100%;
}

.trophy,
.trophyBackground {
  display: none;
}

.gimmick[data-tier="platinum"] .trophy,
.gimmick[data-tier="platinum"] .trophyBackground {
  display: initial;
}

.nightsCompletedPre {
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1px;
  color: rgba(255, 255, 255, 0.7);
  grid-row: 1;
  grid-column: 1;
  display: none;
  white-space: pre;
}

.nightsCompleted {
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.1px;
  background: linear-gradient(180deg, #F7F7F7 20%, #A3A3A3 85.97%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0 0.508898px 1.0178px rgba(0, 0, 0, 0.25);
  grid-row: 2;
  grid-column: 1;
  display: none;
}

.gimmick[data-tier="platinum"] .nightsCompletedPre,
.gimmick[data-tier="platinum"] .nightsCompleted {
  display: initial;
}

@media (max-width: 768px) {
  .gimmick {
    font-size: 14px;
  }

  .speedometer {
    margin-bottom: -50px;
    margin-top: -60px;
  }

  .nightsCompletedPre {
    font-size: 14px;
  }

  .nightsCompleted {
    font-size: 24px;
  }

  .nextTier {
    font-size: 10px;
  }
}

@media (min-width: 768px) {
  .points {
    display: none;
  }

  .gimmick {
    font-size: 18px;
    align-self: flex-end;
  }

  .speedometer {
    width: 400px;
    margin-bottom: -50px;
  }

  .nightsCompletedPre {
    font-size: 18px;
  }

  .nightsCompleted {
    font-size: 32px;
  }

  .nextTier {
    font-size: 14px;
  }
}
