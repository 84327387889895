.modalContainer {
  overflow: auto;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container{
  background-color: white;
  overflow: auto;
  border-radius: 6px;
  display: grid;
  gap: 20px;
  padding: 16px 16px 12px;
  max-width: 450px;
  position: relative;
}

.headerContainer {
  display: grid;
  grid-template: auto/auto 1fr;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  padding: 0;
  height: var(--navbar-height);
}

.logo {
  grid-column: 2 / span 2;
  grid-row: 1;
  justify-self: center;
}

.close {
  grid-row: 1;
  grid-column: 3;
  cursor: pointer;
  align-self: flex-start;
}

.closeHidden{
  display: none;
}

.userName {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary-contrast-text);
}

.tncContent {
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  color: var(--secondary-contrast-text-2);
  text-align: center;
  margin-top: -5px;
}

.tncContent a {
  font-weight: 700;
  text-decoration: underline;
  margin-right: 0px;
}

@media (max-width: 768px) {
  .container {
    height: 100vh;
    align-content: flex-start;
  }

  .modalContent {
    background: white;
    width: 100vw;
  }

  .container {
    width: 100vw;
  }

  .tncContent {
    border-top: 1px solid #dddddd;
    padding: 16px 12px;
  }

  .logo {
    height: 32px;
  }
}

@media (min-width: 768px) {
  .tncContent {
    text-align: center;
  }

  .logo {
    height: 52px;
  }
}
