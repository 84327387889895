.container {
  display: grid;
  grid-template: auto 1fr/1fr;
  z-index: 0;

  --llp-card-overlay: 100px;
}

.container[data-tier="silver"] {
  --llp-benefit-background: linear-gradient(
    180deg,
    #b8b9c5 10.08%,
    #f9f9ff 26.24%
  );

  --llp-benefit-card-background: linear-gradient(
    254.44deg,
    #d5d5db 25.79%,
    #888aa0 72.65%
  );
  --llp-benefit-card-border: linear-gradient(
    270.44deg,
    rgba(212, 212, 212, 0) 0.34%,
    #d6d6d6 47.9%,
    rgba(214, 214, 214, 0) 99.59%
  );
  --llp-benefit-card-title-color: linear-gradient(
    180deg,
    #f7f7f7 20%,
    #898aa1 86.67%
  );
  --llp-benefit-card-title-shadow-color: 0px 0.508898px 1.0178px
    rgba(0, 0, 0, 0.25);
  --llp-benefit-card-subtitle-color: #404251;

  --llp-benefit-content-title-color: #3d3e51;
  --llp-benefit-content-text-color: #5f6077;

  --llp-membership-color: #888aa0;
}

.container[data-tier="gold"] {
  --llp-benefit-background: linear-gradient(
    180deg,
    #ffdda9 8.69%,
    #fffcf9 25.11%
  );

  --llp-benefit-card-background: linear-gradient(
    252.28deg,
    #fccd87 2.32%,
    #dca24c 71.95%
  );
  --llp-benefit-card-border: linear-gradient(
    269.5deg,
    rgba(245, 237, 209, 0) 0.36%,
    #eee5c5 46.84%,
    rgba(225, 219, 198, 0) 99.52%
  );
  --llp-benefit-card-title-color: linear-gradient(
    180deg,
    #f7f7f7 14.85%,
    #dda34d 112.98%
  );
  --llp-benefit-card-title-shadow-color: 0px 1.01222px 2.02444px
    rgba(0, 0, 0, 0.25);
  --llp-benefit-card-subtitle-color: #3e3f4a;

  --llp-benefit-content-title-color: #493d2a;
  --llp-benefit-content-text-color: #665741;

  --llp-membership-color: #dca24c;
}

.container[data-tier="platinum"] {
  --llp-benefit-background: linear-gradient(
    180.86deg,
    #a9a9a9 8.64%,
    #f7f7f7 24.93%
  );

  --llp-benefit-card-background: linear-gradient(
    254.44deg,
    #292828 25.79%,
    #211e1e 72.65%
  );
  --llp-benefit-card-border: linear-gradient(
    90deg,
    rgba(109, 102, 102, 0) 0%,
    #6d6666 48.44%,
    rgba(109, 102, 102, 0) 100%
  );
  --llp-benefit-card-title-color: linear-gradient(
    180deg,
    #f7f7f7 20%,
    #595151 86.67%
  );
  --llp-benefit-card-title-shadow-color: 0px 0.508898px 1.0178px
    rgba(0, 0, 0, 0.25);
  --llp-benefit-card-subtitle-color: #606060;

  --llp-benefit-content-title-color: #493d2a;
  --llp-benefit-content-text-color: #191717;

  --llp-membership-color: #211e1e;
}

.card {
  width: 280px;
  height: 170px;
  justify-self: center;
  border-radius: 10px;
  background: var(--llp-benefit-card-background);
  /*
  border: 2px solid;
  border-image-source: var(--llp-benefit-card-border);
  */
  overflow: hidden;
  align-self: center;
  display: grid;
  grid-template: 1fr auto auto/1fr auto;
  gap: 8px;
  padding: 12px;
  margin-bottom: calc(-1 * var(--llp-card-overlay) + 0px);
  z-index: 1;
  grid-column: 1;
  grid-row: 1;
}

.logo {
  grid-column: 1 / span 2;
  grid-row: 1;
}

.cardTitle {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.23em;
  text-transform: uppercase;
  background: var(--llp-benefit-card-title-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: var(--llp-benefit-card-title-shadow-color);
  grid-column: 1;
  grid-row: 2;
}

.cardLock {
  grid-column: 2;
  grid-row: 2 / span 2;
}

.cardSubtitle {
  font-weight: 500;
  font-size: 8px;
  letter-spacing: 0.22em;
  text-transform: uppercase;
  color: var(--llp-benefit-card-subtitle-color);
  grid-column: 1;
  grid-row: 3;
}

.cardOverlay {
  grid-column: 1 / span 2;
  grid-row: 1 / span 3;
  align-self: flex-end;
  width: calc(100% + 24px);
  margin-left: -12px;
  margin-bottom: -12px;
}

.benefitsContainer {
  display: grid;
  grid-template: 1fr auto/1fr;
  gap: 20px;
  background: var(--llp-benefit-background);
  border-radius: 8px;
  box-shadow: 0 4.60465px 9.2093px rgba(0, 0, 0, 0.08);
  grid-column: 1;
  grid-row: 2;
  padding: 16px;
  width: 312px;
  height: auto;
  justify-self: center;
}

.tiermodule {
  align-content: flex-start;
  justify-self: center;
  background: var(--llp-benefit-background);
  border-radius: 8px;
  box-shadow: 0 4.60465px 9.2093px rgba(0, 0, 0, 0.08);
  padding: 24px;
}

.tiermodulebenefits {
  display: grid;
  grid-template: auto/1fr 1fr;
  gap: 18px;
  width: 450px;
  height: auto;
  align-content: flex-start;
  justify-self: center;
}

.benefits {
  display: grid;
  grid-template: auto/1fr;
  gap: 18px;
  align-content: flex-start;
}

.cardGap {
  height: calc(var(--llp-card-overlay) + -24px);
}

.title {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: var(--llp-benefit-content-title-color);
  opacity: 0.84;
}

.tiermoduletitle {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: var(--llp-benefit-content-title-color);
  opacity: 0.84;
  grid-column: span 2;
  justify-self: center;
}
.benefit {
  display: grid;
  grid-template: auto/24px 1fr;
  gap: 12px;
  color: var(--llp-benefit-content-text-color);
  font-size: 14px;
  font-weight: 500;
}

.tiermodulebenefit{
  display: grid;
  grid-template: auto/18px 1fr;
  gap: 6px;
  color: var(--llp-benefit-content-text-color);
  font-size: 14px;
  font-weight: 500;
}

.benefit img {
  align-self: center;
  justify-self: center;
}

.tiermodulebenefit img{
  align-self: auto;
  justify-self: center;
  margin: 6px;
}



.benefit[data-included="false"] {
  opacity: 0.2;
}

.explore {
  height: 44px;
  border: 1px solid var(--primary-main);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  letter-spacing: 0.1px;
  border-radius: 4px;
  font-weight: 500;
}

.explore,
.explore:visited {
  color: var(--primary-main);
}

.benefitContainerOverlay {
  grid-column: 1;
  grid-row: 1 / span 2;
  /* background: #151515e6; */
  backdrop-filter: blur(2px);
  background: rgba(38, 38, 38, 0.9);
  backdrop-filter: blur(2px);
  display: none;
  grid-template: auto/auto;
  align-content: center;
  justify-content: center;
  justify-items: center;
  gap: 12px;
  z-index: 1;
  white-space: pre;
  text-align: center;
}

.benefitContainerOverlay[data-visible="true"] {
  display: grid;
}

.overlayContent {
  max-width: 300px;
  text-align: center;
  white-space: pre-wrap;
}

.membership {
  font-size: 16px;
  font-weight: 700;
}

.membership[data-color="true"] {
  color: var(--llp-membership-color);
}

.progressbarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressbar {
  margin: 16px;
  position: relative;
  width: 124px;
  height: 8px;
  background-color: #D7D7D7;
  border-radius: 15px;
  overflow: hidden;
}

.progressbarfill {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  background-color:#67A891;
  transform: translateX(calc(100%)); 
  transition: transform ease-in 500ms; 
}

.nextStay {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #ffffff;
  margin: 0;
}

.book{
  color: #ffffff;
  height: 44px;
  width: 280px;
  justify-self: center;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .tiermodule {
    display: grid;
    grid-template: 1fr auto/1fr;
    gap: 20px;
    background: var(--llp-benefit-background);
    border-radius: 8px;
    box-shadow: 0 4.60465px 9.2093px rgba(0, 0, 0, 0.08);
    grid-column: 1;
    grid-row: 2;
    padding: 16px;
    width: 312px;
    height: auto;
    justify-self: center;
  }
  .tiermodulebenefits {
    display: grid;
    grid-template: auto/1fr;
    gap: 18px;
    align-content: flex-start;
    width: auto;
  }
  .tiermoduletitle {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: var(--llp-benefit-content-title-color);
    opacity: 0.84;
    grid-column: span 1;
  }

}

@media (min-width: 768px) {
  .book{
    display: none;
  }
}
