.container {
  display: inline-grid;
  grid-template: auto/1fr;
  align-content: flex-start;
  gap: 4px;
  font-weight: 400;
}

.label {
  text-transform: capitalize;
  color: #626366;
  opacity: 0.8;
  font-weight: 500;
  letter-spacing: 0.36px;
  font-size: 14px;
}

.input {
  display: none;
}

.input:checked+.inputLabel {
  background: var(--primary-main);
  color: var(--primary-contrast-text);
}

.inputLabel {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.inputLabel[data-disabled="true"] {
  cursor: not-allowed;
  opacity: 0.5;
}

.error {
  color: #CC385A;
  font-size: 12px;
}

.freeText {
  font-family: var(--font-family);
  background: white;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 12px;
  color: #474e66;
  font-size: 16px;
  outline: none;
}

.freeText[data-error='true']:hover,
.freeText[data-error='true']:focus {
  background-color: #CC385A;
}

.freeText[data-error='false']:hover,
.freeText[data-error='false']:focus {
  border-color: #474e66;
}

.naContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 14px 0 0;
}

.noneOptions {
  cursor: pointer;
  text-decoration: underline;
}

.noneOptions[data-active='true']{
  font-weight: bold;
}

.crossIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
}

.crossIcon[data-visible='false'] {
  display: none;
}
