.container {
  display: grid;
  background: white;
  border-radius: 8px;
}

.title{
  color: var(--secondary-contrast-text-3);
  font-weight: 600;

}

.otherAmenities {
  display: flex;
  gap: 8px;
  align-items: center;
  height: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--secondary-contrast-text);

}

.close {
  cursor: pointer;
  grid-row: 1;
  grid-column: 1;
  justify-self: self-end;
}

.icon {
  height: 28px;
  border: 1px solid rgba(120, 120, 120, 0.2);
  padding: 5px;
  border-radius: 50%;
}

.textContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.carouselContainer {
  display: grid;
  grid-template: auto/1fr;
  grid-row: span 5;
  position: relative;
}

.carousel {
  scroll-snap-type: x mandatory;
  grid-column: 1;
  grid-row: 1;
  height: 256px;
}

.imageItem {
  scroll-snap-align: start;
  overflow: hidden;
}

.imgSelect {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
  cursor: pointer;
}

.imgSelect img {
  object-fit: cover;
}

.scrollCrumb {
  grid-column: 1;
  grid-row: 1;
  align-self: flex-end;
  justify-self: center;
}

.amenitiesContainer{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}


@media (max-width: 768px) {
  .container {
    gap: 8px;
    padding: 12px;
    width: 92%;
    grid-template: auto / 1fr;
    align-self: center;
  }


  .close{
    border: 1px solid #787878;
    width: 20px;
    height: 20px;
    background-color: none;
    border-radius: 50%;
    z-index: 1;
    padding: 2px;
  }

  .otherAmenities{
    gap:4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .amenitiesName{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .imageItem{
    width: calc(100vw - 57px);    
    border-radius: 4px;
  }

  .carousel{
    height: 200px;
  }

  .title{
    font-size: 16px;
    line-height: 20px;
  }

  .textContainer{
    grid-row: 1;
    grid-column: 1;
  }

  .configSize{
    color: var(--secondary-contrast-text);
    justify-self: flex-start;
    font-weight: 600;
    font-size: 14px;
    margin-top: 2px;
    line-height: 22px;
  }

  .description{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--secondary-contrast-text);
    margin-top: -2px;
  }

  .otherAmenitiesContainer{
    margin-top: 2px;
    display: grid;
    gap: 10px;
    height: fit-content;
  }

  .icon{
    height: 24px;
    border: 1px solid rgba(120, 120, 120, 0.2);
    padding: 4px;
    border-radius: 50%;
  }

  
}


@media (min-width: 768px) {
  .container {
    gap: 12px;
    padding: 16px;
    width: 488px;
    grid-template: auto/1fr;
  }

  .imageItem{
    width: 456px;
    border-radius: 4px;
  }

  .close{
    width: 24px;
    height: 24px;
    padding: 4px;
    background-color: #1F2C381A;
    border-radius: 50%;
    z-index: 1;
  }

  .textContainer{
    grid-row: 1;
    grid-column: 1;
  }

  .title{
    font-size: 18px;
    line-height: 24px;
    color: var(--secondary-contrast-text-3);
  }

  .configSize{
    color: var(--secondary-contrast-text);
    justify-self: flex-start;
    font-weight: 600;
    font-size: 16px;
    margin-top: 2px;
    line-height: 22px;
  }

  .description{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: -2px;
    color: var(--secondary-contrast-text);
  }

  .otherAmenitiesContainer{
    margin-top: 6px;
    display: grid;
    gap: 16px;
    height: fit-content;
  }
}