.container {
  color: #565453;
  border-radius: 8px;
  display: grid;
  grid-template: auto/1fr auto;
  gap: 16px;
  border-radius: 8px;
  color: rgba(241, 241, 241, 0.9);
}

.banner {
  grid-column: 1/span 2;
  grid-row: 1/span 3;
  border-radius: 8px;
}

.infinityImageHome {
  grid-column: 1;
  grid-row: 1;
}

.header {
  font-weight: 700;
  font-size: 32px;
  grid-column: 1;
  grid-row: 2;
}

.bottomtext {
  font-weight: 400;
  font-size: 24px;
  grid-column: 1;
  grid-row: 3;
  white-space: pre-wrap;
}

.details {
  grid-column: 2;
  grid-row: 3/span 2;
  align-self: start;
  font-size: 16px;
  padding: 14px 20px;
}

.details,
.details:visited {
  color: white
}

@media (max-width: 768px) {
  .container {
    color: #565453;
    display: grid;
    grid-template: auto/1fr;
    padding: 24px;
    border-radius: 8px;
    color: rgba(241, 241, 241, 0.9);
    gap: 8px;
  }

  .banner {
    grid-column: 1;
    grid-row: 1/span 4;
    border-radius: 8px;
    margin: -24px;
  }

  .infinityImageHome {
    grid-column: 1;
    grid-row: 1;
    margin-bottom: 10px;
  }

  .header {
    font-weight: 700;
    font-size: 16px;
    grid-column: 1;
    grid-row: 2;
  }

  .bottomtext {
    font-weight: 400;
    font-size: 12px;
    grid-column: 1;
    grid-row: 3;
    white-space: pre-wrap;
  }

  .details {
    grid-column: 1;
    grid-row: 4;
    font-size: 12px;
    font-weight: 600;
    padding: 12px 20px;
  }

  .details,
  .details:visited {
    color: white
  }
}

@media (min-width: 768px) {
  .container {
    color: #565453;
    display: grid;
    grid-template: auto/1.5fr 1fr;
    padding: 66px 60px 50px 60px ;
    border-radius: 8px;
    color: rgba(241, 241, 241, 0.9);
    gap: 16px;
  }

  .banner {
    border-radius: 8px;
    margin: -66px -60px;
  }

  .header {
    font-weight: 700;
    font-size: 32px;
  }

  .bottomtext {
    font-weight: 400;
    font-size: 20px;
    white-space: pre-wrap;
  }

  .details {
    align-self: start;
    font-size: 16px;
    font-weight: 600;
    padding: 14px 20px;
    width: 240px;
    justify-self: end
  }

  .details,
  .details:visited {
    color: white
  }
}
