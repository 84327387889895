.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 24px;
  color: var(--secondary-contrast-text);
}

.title {
  font-weight: 800;
  color: #464E68;
}

.items {
  display: grid;
}

.item {
  display: flex;
  flex-direction: column;
}

.itemThumbnail {
  object-fit: cover;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24));
  border-radius: 4px;
}

.itemTitle {
  font-weight: 600;
}

@media(max-width: 768px) {
  .container {
    gap: 16px;
  }

  .title {
    font-size: 18px;
    line-height: 22px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 17px;
  }

  .items {
    display: flex;
    overflow-x: scroll;
    gap: 16px;
  }

  .itemTitle {
    margin-top: 8px;
    text-align: initial;
    font-size: 16px;
    line-height: 19px;
  }

  .itemThumbnail {
    max-width: 160px;
  }
}

@media (min-width: 768px) {
  .title {
    margin-bottom: -20px;
    font-size: 32px;
    line-height: 39px;
  }

  .subtitle {
    font-size: 18px;
    line-height: 28px;
  }

  .items {
    grid-template: auto/repeat(auto-fill, 282px);
    gap: 48px 24px;
  }

  .itemTitle {
    margin-top: 16px;
    font-size: 18px;
    line-height: 22px;
  }

  .itemThumbnail {
    width: 100%; 
  }
}