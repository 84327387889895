.container {
  display: grid;
  grid-template: auto / auto 1fr auto auto;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.15);
  background-color: var(--header-background);
  height: var(--navbar-height);
  gap: 24px;
  /* opacity: 0; */
  /* -webkit-transition: position 1000ms linear; */
  /* -moz-transition: position 1000ms linear; */
  /* -o-transition: position 1000ms linear; */
  /* -ms-transition: position 1000ms linear; */
  /* transition: top 0.3s ease-in-out, position 0.3s ease-in-out; */
  /* opacity: 0; */
  /* animation: fadeIn 0.3s forwards; */
  /* transition: position 1000ms linear; */
}

@media (min-width: 768px) {
  .solid {
    background-color: var(--header-background);
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.15);
    position: sticky;
    opacity: 1;
    /* transition: background-color 0.4s ease-in-out, box-shadow 0.4s ease-in-out, opacity 0.4s ease-in-out; */
}

.transparent {
    background-color: transparent !important;
    box-shadow: none !important;
    position: absolute !important;
    width: 100% !important;
    opacity: 1;
    /* transition: background-color 0.4s ease-in-out, box-shadow 0.4s ease-in-out, opacity 0.4s ease-in-out; */
}
}

.hideBar {
  display: none;
}

.hideShadow {
  box-shadow: none !important;
  border-bottom: 1px solid #dddddd !important;
  padding: 0px 15vw;
}

.headerDropdownMenu {
  --menu-margin-left: -250px;
}

.headerDropdown {
  display: none;
  color: var(--secondary-contrast-text);
}

.headerDropdownMenu {
  --menu-margin-top: calc(var(--navbar-height) / 2 - 14px);

  color: var(--secondary-contrast-text);
  font-size: 14px;
  padding: 16px 20px;
  font-weight: 500;
  min-width: 300px;
  border-bottom: 4px solid var(--primary-main);
  box-shadow: 0 16.47px 66.4222px rgba(167, 174, 186, 0.16);
  top: var(--navbar-height);
}

.headerDropdownMenu[data-open="true"] {
  display: block;
}

.propertySearchHeaderContainer {
  background: rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
  padding: 8px 8px 8px 24px;
  width: fit-content;
  justify-self: center;
}

.logoContainer {
  width: 160px;
  justify-content: flex-start;
}

.logo {
  max-width: 160px;
}

.login {
  width: fit-content;
  justify-self: flex-end;
}

.loginTransparent {
  width: fit-content;
  justify-self: flex-end;
}

@media (min-width: 768px) {
  .loginTransparent button {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0%;
    color: white;
    background: transparent;
    border: 1px solid #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.drawerLink {
  display: flex;
  align-items: center;
  padding: 16px 0;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto / auto 1fr auto;
    height: 65px;
    gap: 8px;
    align-content: center;
  }

  .logoContainer {
    width: fit-content;
  }

  .login {
    display: none;
  }

  .logo {
    max-height: 32px;
  }

  .logoContainer {
    display: flex;
    flex-direction: row;
  }

  .propertySearchHeaderContainer {
    display: none;
  }

  .headerDropdown {
    display: none;
  }
}

@media (min-width: 768px) {
  .logo {
    max-height: 52px;
  }

  .leftChevron {
    display: none;
  }

  .propertySearchMobile {
    display: none;
  }

  .headerDropdown {
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
  }

  .mobileMenu {
    display: none;
  }
}
