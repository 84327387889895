.container {
  position: relative;
  overflow: hidden;
  display: grid;
  gap: 16px;
}

.carousel {
  gap: 16px;
  padding: 16px 0;
}

.placeholder {
  margin-right: 100px;
}

.item {
  width: 276px;
  position: relative;
  row-gap: 16px;
  padding: 4px 8px 8px 8px;
  border-radius: 4px;
  display: grid;
  grid-template: auto /1fr 1fr;
  height: 100%;
  border: 1px solid #1F2C3814;
  align-content: flex-start;
}

.itemNoImageBedroom {
  width: 276px;
  position: relative;
  row-gap: 16px;
  padding: 4px 8px 8px 8px;
  border-radius: 4px;
  display: grid;
  grid-template: auto/1fr 1fr;
  height: 100%;
  background: #FFFFFF;
  border: 1px solid #1F2C3814;
  align-content: flex-start;
}

.itemNoImageBedroom .bedroomTitle {
  margin-top: 0px;
}

.itemNoImageBedroom .floor {
  margin-top: 0px;
}

.itemNoImageBedroom .amenities {
  margin-left: -28px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.itemNoImageBedroom .amenities li:first-child {
  margin-top: 0px;
  padding-top: 0px;
}

.thumbnail {
  grid-column: span 2;
  width: 76px;
  height: 76px;
  margin: 0 4px;
  align-self: center;
  justify-self: left;
}

.imgContainer{
  height: 158px;
  margin: 0 -4px 0 -4px;
  border-radius: 4px;
  grid-column: span 2;
}

.bedroomImage {
  width: 100%;
  border-radius: 4px;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.scroller {
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
}

.title {
  font-size: 20px;
  font-weight: 700;
  margin-top: 16px;
  color: var(--secondary-contrast-text-3);
}

.bedroomTitle {
  color: var(--secondary-contrast-text-3);
  font-weight: 600;
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: 1%;
  white-space: pre;
}

.floor {
  justify-self: flex-end;
  align-self: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.52px;
  letter-spacing: 1%;
  text-align: center;
  color: var(--secondary-contrast-text-3);
}

.amenities {
  margin: 0 0 0 -24px;
  color: var(--secondary-contrast-text-2);
  font-weight: 400;
  font-size: 14px;
  grid-column: span 2;
}

@media (max-width: 768px) {
  .carousel {
    margin: -16px -8px -16px -8px;
    gap: 8px;
  }

  .item{
    padding: 8px;
    row-gap: 8px;
    width: 220px;
    background: #F6F7F7;
    border: none;
  }

  .floor{
    font-weight: 500;
    font-size: 10px;
    line-height: 12.1px;
    letter-spacing: 1%;
    text-align: center;
    color: var(--secondary-contrast-text-2);

  }

  .imgContainer{
    margin: -8px -8px 0;
    height: 120px;
    display: flex;
    justify-content: center;
  }

  .bedroomImage{
    border-radius: 4px 4px 0 0;
  }

  .scroller {
    display: none;
  }

  .bedroomTitle, .floor, .amenities {
    font-size: 12px;
  }

  .itemNoImageBedroom {
    gap: 8px;
    width: 220px;
    border-radius: 4px;
    background: #F6F7F7;
    border: none;
  }

  .thumbnail {
    margin: 12px 8px;
    height: 24px;
    width: 28px;
  }

  .itemNoImageBedroom .amenities li {
    padding-top: 4px;
  }

  .item .amenities li {
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    letter-spacing: 0%;
    color: var(--secondary-contrast-text-2);
  }
}

@media (min-width: 768px) {
  .carousel {
    margin: -16px -16px -16px -16px;
  }

  .imgContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F5F5;
  }
}