.banner {
  display: grid;
  grid-template: 1fr auto 1fr/1fr;
  color: #ffffff;
  gap: 16px;
}

.bannerImage {
  grid-row: 1/span 3;
  grid-column: 1;
}

.bannerImage {
  object-fit: cover;
}

.bannerTitle {
  margin: 0;
  grid-row: 1;
  grid-column: 1;
  align-self: flex-end;
  font-weight: bold;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
}

.bannerTitle2 {
  font-weight: 500;
}

.bannerSubtitle {
  grid-row: 2;
  grid-column: 1;
  display: flex;
  align-items: center;
}

.bannerSubtitle img {
  margin-right: 4px;
}

.bannerEnquire {
  grid-row: 3;
  grid-column: 1;
  justify-self: flex-start;
  align-self: flex-start;
}

.wellness {
  background: white;
  display: grid;
  gap: 16px 24px;
}

.wellnessTitle {
  font-weight: bold;
  color: var(--secondary-contrast-text-3);
}

.wellnessDescription {
  font-size: 24px;
  color: var(--secondary-contrast-text);
}

.wellnessIllustration {
  grid-row: span 2;
  width: 100%;
  object-fit: contain;
}

.venue {
  display: grid;
}

.venueTitle {
  font-weight: bold;
  color: var(--secondary-contrast-text-3);
}

.venueItem {
  background: white;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  display: grid;
  grid-template: auto 1fr auto/1fr;
}

.venueItemTitle {
  font-weight: bold;
  color: var(--secondary-contrast-text);
  border-bottom: 1px solid #dddddd;
}

.venueItemDescription {
  color: var(--secondary-contrast-text);
}

.venueItemDirection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-main);
  border: 1px solid var(--primary-main);
  font-weight: 500;
  border-radius: 4px;
}

.venueItemEnquire {
  width: 100%;
}

.earlyBirdContainer {
  font-weight: bold;
  background: rgba(216, 112, 54, 0.1);
  color: #d87036;
  display: grid;
}

.earlyBirdPriceContainer {
  background: white;
  color: #00a699;
  font-weight: bold;
  display: grid;
}

.earlyBirdStrike {
  font-weight: 500;
  color: var(--secondary-contrast-text-2);
  text-decoration: line-through;
}

.inclusion {
  display: grid;
  background: white;
  color: var(--secondary-contrast-text);
}

.inclusionContent {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
  align-content: flex-start;
}

.inclusionTitle {
  font-weight: bold;
  color: var(--secondary-contrast-text-3);
}

.inclusionItem {
  display: grid;
  grid-template: auto/auto 1fr;
  align-items: center;
  gap: 16px;
  font-weight: 500;
}

.inclusionBanner {
  width: 100%;
  object-fit: cover;
}

.inclusionEnquire {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-main);
  border: 1px solid var(--primary-main);
  font-weight: 500;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
}

.villaAzul {
  display: grid;
  grid-template: auto/1fr;
}

.villaAzulTitle {
  color: var(--secondary-contrast-text-3);
  font-weight: bold;
}

.villaAzulImages {
  display: grid;
}

.villaAzulImage {
  width: 100%;
  object-fit: cover;
}

.tnc {
  background: white;
  display: grid;
  grid-template: auto/1fr;
  letter-spacing: 0.005em;
  color: var(--secondary-contrast-text);
  gap: 8px;
}

.tncTitle {
  font-weight: bold;
  color: var(--secondary-contrast-text-3);
}

@media (max-width: 768px) {
  .banner {
    height: calc(75vh - var(--navbar-height));
  }

  .bannerTitle {
    font-size: 32px;
    white-space: pre-wrap;
  }

  .bannerTitle2 {
    font-weight: 500;
    font-size: 16px;
  }

  .bannerSubtitle {
    font-size: 16px;
  }

  .bannerEnquire {
    padding: 16px 24px;
  }

  .wellness {
    grid-template: auto/1fr;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .wellnessTitle {
    font-size: 24px;
  }

  .wellnessDescription {
    font-size: 14px;
    line-height: 26px;
  }

  .wellnessIllustration {
    display: none;
  }

  .venue {
    grid-template: auto/1fr;
    gap: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .venueTitle {
    font-size: 16px;
  }

  .venueItem {
    padding: 16px;
    border-radius: 6px;
    gap: 16px;
  }

  .venueItemTitle {
    font-size: 16px;
    padding: 4px 0 8px;
  }

  .venueItemDescription {
    font-size: 14px;
    line-height: 26px;
  }

  .venueItemDirection {
    padding: 12px 16px;
  }

  .earlyBirdContainer {
    border-radius: 4px;
    gap: 4px;
    padding: 16px;
    text-align: center;
    font-size: 12px;
  }

  .earlyBirdTitle {
    font-size: 16px;
  }

  .earlyBirdContainerTitle {
    font-size: 24px;
  }

  .earlyBirdPriceContainer {
    grid-row: 3;
    font-size: 14px;
    line-height: 19px;
    margin-top: 16px;
    padding: 8px;
    border-radius: 4px;
    gap: 8px;
    grid-template: auto/auto auto;
    justify-content: center;
  }

  .earlyBirdStrike {
    font-size: 12px;
    line-height: 19px;
  }

  .venueItemEnquire {
    padding: 12px 16px;
  }

  .inclusion {
    grid-template: auto/1fr;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-top: 24px;
  }

  .inclusionTitle {
    font-size: 18px;
  }

  .inclusionItem {
    font-size: 14px;
  }

  .inclusionBanner {
    display: none;
  }

  .inclusionEnquire {
    width: 100%;
    padding: 12px 16px;
  }

  .villaAzul {
    gap: 8px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .villaAzulTitle {
    font-size: 18px;
  }

  .villaAzulImages {
    grid-template: auto/repeat(4, 60vw);
    gap: 16px;
    overflow: auto;
  }

  .tnc {
    gap: 8px;
    font-size: 12px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .tncTitle {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .banner {
    height: calc(100vh - var(--navbar-height));
  }

  .bannerTitle {
    font-size: 42px;
  }

  .bannerTitle2 {
    font-weight: 600;
    font-size: 38px;
  }

  .bannerSubtitle {
    font-size: 24px;
  }

  .bannerEnquire {
    padding: 16px 32px;
  }

  .wellness {
    grid-template: auto/1fr 35vw;
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .wellnessTitle {
    font-size: 24px;
  }

  .wellnessDescription {
    font-size: 18px;
    line-height: 36px;
  }

  .venue {
    grid-template: auto/1fr 1fr;
    gap: 32px;
  }

  .venueTitle {
    grid-column: span 2;
    font-size: 24px;
  }

  .venueItem {
    padding: 32px;
    border-radius: 10px;
    gap: 24px;
  }

  .venueItemTitle {
    font-size: 24px;
    padding: 8px 0 16px;
  }

  .venueItemDescription {
    font-size: 16px;
    line-height: 24px;
  }

  .venueItemDirection {
    padding: 16px 32px;
  }

  .venueItemEnquire {
    padding: 16px 32px;
  }

  .earlyBirdContainer {
    padding: 32px 16px;
    border-radius: 8px;
    font-size: 16px;
    grid-template: auto/1fr 1fr;
    align-items: flex-start;
  }

  .earlyBirdTitle {
    font-size: 24px;
    align-self: flex-end;
  }

  .earlyBirdContainerTitle {
    font-size: 16px;
  }

  .earlyBirdPriceContainer {
    grid-row: span 2;
    border-radius: 6px;
    padding: 16px;
    font-size: 20px;
    line-height: 19px;
    gap: 8px;
    grid-template: auto/1fr;
  }

  .earlyBirdStrike {
    font-size: 16px;
    line-height: 19px;
  }

  .inclusion {
    grid-template: auto/1fr 35vw;
    padding-top: 64px;
    padding-bottom: 64px;
    margin-top: 48px;
  }

  .inclusionTitle {
    font-size: 18px;
  }

  .inclusionItem {
    font-size: 16px;
  }

  .inclusionEnquire {
    padding: 16px 32px;
    width: 60%;
  }

  .villaAzul {
    gap: 16px;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .villaAzulTitle {
    font-size: 24px;
  }

  .villaAzulImages {
    grid-template: auto/repeat(4, 1fr);
    gap: 16px;
  }

  .tnc {
    font-size: 16px;
    text-align: center;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .tncTitle {
    font-size: 24px;
    margin-bottom: 16px;
  }
}
