.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 24px;
}

.title {
  font-weight: 700;
}

.mobileTitle {
  font-weight: 700;
}

.tabs {
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
}

.tab {
  transition: all 0.3s ease-in-out;
  display: flex;
  gap: 8px;
  font-weight: 500;
  justify-content: center;
  position: unset;
  outline: none;
  border: none;
  cursor: pointer;
  user-select: none;
  appearance: none;
  padding: 12px 16px;
  border-radius: 4px;
}

.activeTab {
  background-color: #1F2C38 !important;
  color: white !important;
}

.tab:hover {
  background: rgba(0, 0, 0, 0.05);
  color: #1F2C38;
}

.tabIconTitle {
  height: 16px;
  width: 14px;
}

.tabIcon {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.map {
  background: dodgerblue;
  overflow: hidden;
  border-radius: 8px;
}

.places {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  scroll-behavior: smooth;
  padding-bottom: 8px;
  white-space: nowrap;
}

.place {
  font-size: 12px;
  border-right: 1px solid rgba(151, 151, 151, 0.2);
  padding-right: 15px;
  white-space: nowrap;
  width: fit-content;
}

.place:last-child {
  border-right: none;
  padding-right: 0;
}

.placesTitle {
  font-size: 15px;
  position: sticky;
  top: 0;
  padding: 16px 0 12px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expandIcon {
  width: 20px;
  height: 20px;
}

.placeName {
  font-size: 16px;
  font-weight: 600;
}

.distance {
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary-contrast-text-2);
}

.pin {
  width: 32px;
  height: 32px;
}

.pin :global .title {
  color: var(--primary-main);
  font-size: 16px;
}

@media (max-width: 768px) {
  .container {
    gap: 16px;
  }

  .title {
    display: none;
  }

  .mobileTitle {
    font-size: 16px;
  }

  .tabs {
    grid-column: 1;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;
    scroll-behavior: smooth;
    white-space: nowrap;
  }

  .tab {
    font-size: 11px;
    width: 110px;
  }

  .map {
    grid-column: 1;
    height: 300px;
  }

  .placeName {
    font-size: 12px;
    font-weight: 600;
  }

  .distance {
    font-size: 12px;
    font-weight: 400;
    color: var(--secondary-contrast-text);
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 24px;
  }

  .mobileTitle {
    display: none;
  }

  .tabs {
    grid-template: auto/auto;
    gap: 24px;
    grid-column: 1;
    justify-content: flex-start;
  }

  .tab {
    max-width: 120px;
  }

  .map {
    grid-column: 1;
    height: 400px;
  }

  .expandIcon {
    display: none;
  }
}