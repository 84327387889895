.container {
  display: grid;
  grid-template: auto/auto;
  justify-content: flex-start;
  grid-auto-flow: column;
  overflow: auto;
}

.adjust {
  width: 0;
}