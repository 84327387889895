.searchIcon {
  background: rgba(170, 49, 49, 1);
  border: 1px solid rgba(170, 49, 49, 1);
  border-radius: 8px;
  padding: 11px;
}

.hideBar{
  display: none;
}

.propertySearchHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.friendly[data-populated="false"] {
  font-weight: 500;
  color: var(--secondary-contrast-text-2);
}

.friendly[data-populated="true"] {
  font-weight: 600;
  color: var(--secondary-contrast-text);
}

.friendly {
  display: flex;
  align-items: center;
  padding: 0 17px;
  justify-content: center;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
}

.icons{
  width: 16px;
  height: 16px;
}

.destination {
  border-left: none !important;
  padding-left: 0;
  padding-right: 25px;
  text-transform: capitalize;
}

.propertySearchMobile {
  display: grid;
  grid-template: auto / 1fr auto;
  gap: 9px;
}
.guests{
  padding-left: 25px;
  padding-right: 27px;
}

@media (max-width: 768px) {

  .propertySearchHeader{
    display: grid;
    grid-template: auto auto / auto 1fr auto;
    row-gap: 9px;
    column-gap: 2px
  }


  .destinationFriendlyConatiner {
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    text-align: left;
    color: var(--secondary-contrast-text-3);
  }

  .friendly {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    line-height: 9px;
    text-align: left;
    color: var(--secondary-contrast-text-2);
    padding: 0;
    justify-content: flex-start;
    border-left:none
  }

  .icons{
    display: none;
  }

  .Dates{
    grid-column: 1;
  }
}


@media (min-width: 768px) {
   .friendly{
    display: flex;
    gap: 2px;
    align-items: center;
   }
}
