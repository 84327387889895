.container {
  display: grid;
  grid-template: auto/1fr;
  align-content: flex-start;
}

.header {
  font-weight: 500;
  color: #000000;
}

.backIcon {
  align-self: center;
  margin-top: 8px;
}

.tabs {
  display: grid;
  grid-template: auto/auto;
  grid-auto-flow: column;
  gap: 8px;
  letter-spacing: 0.005em;
  border-bottom: 1px solid #dddddd;
  justify-content: flex-start;
}

.tab {
  font-weight: 500;
  color: var(--secondary-contrast-text-2);
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
}

.tab[data-active='true'] {
  color: #1f2c38;
  border-color: #1f2c38;
}

.trips {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
  align-items: flex-start;
  margin-top: 16px;
}

.noTrips {
  display: grid;
  grid-template: auto/1fr;
  justify-items: center;
  padding: 24px 12px;
  text-align: center;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: var(--secondary-contrast-text);
}

a.noTripsCta {
  color: white;
  width: 200px;
  font-size: 14px;
  padding: 12px 0;
}

@media (max-width: 768px) {
  .header {
    height: var(--navbar-height);
    display: grid;
    grid-template: auto/auto 1fr;
    gap: 16px;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    font-size: 16px;
    padding-right: 16px;
    padding-left: 16px;
    margin-left: -16px;
    margin-right: -16px;
  }

  .tabs {
    font-size: 16px;
    margin-top: 16px;
  }

  .trips {
    padding-bottom: 32px;
  }

  .noTrips {
    gap: 16px;
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .header {
    font-size: 24px;
    padding: 12px 8px;
  }

  .backIcon {
    display: none;
  }

  .header::before {
    content: '';
    border-right: 4px solid #dddddd;
    margin: 0 12px 0 -8px;
  }

  .tabs {
    font-size: 14px;
    margin-right: 64px;
    margin-left: 16px;
  }

  .trips {
    padding: 0 16px 16px;
  }

  .noTrips {
    gap: 32px;
    font-size: 21px;
  }
}
