.container {
  display: grid;
  grid-template: auto/1fr repeat(3, auto);
  gap: 4px 0px;
  align-items: center;
  color: var(--secondary-contrast-text-3);
  padding: 0 0 16px 0;
}

.title {
  align-self: flex-end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--secondary-contrast-text);

}

.subtitle {
  align-self: flex-start;
  color: var(--secondary-contrast-text-2);
}

.button {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #6d6d6d;
  background: transparent;
  min-width: unset;
  padding: 2px;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
  grid-row: span 2;
}

.button[ data-count = "0" ]{
  opacity: 20% !important;
  cursor: not-allowed;
}

.button:hover {
  background: rgba(0, 0, 0, 0.1);
}

.button:active {
  background: transparent;
}

.button img {
  width: 100%;
  height: 100%;
}

.number {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0%;
  text-align: center;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row: span 2;
}

.separator {
  background: #979797;
  height: 1px;
  opacity: 0.4;
  grid-column: span 4;
  margin: 12px 0;
}

.separator:last-child {
  display: none;
}

.error {
  grid-column: span 4;
  color: #CC385A;
  font-size: 12px;
}

@media (max-width: 768px) {
  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0%;
    
  }
  .subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0%;
    
  }

  .container{
    padding: 16px 0;
  }
}

@media (min-width: 768px) {
  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0%;
  }

  .subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0%;
  }
}
