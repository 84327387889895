.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
  justify-items: center;
  align-content: center;
  transition: all 0.3s linear;
}

.container[data-visible='false'] {
  opacity: 0;
}

.banner {
  max-height: 250px;
  object-fit: contain;
  width: 100%;
}

.title {
  font-size: 28px;
  font-weight: 700;
  color: #626366;
  text-align: center;
}

.subtitle {
  font-size: 24px;
  font-weight: 500;
  color: #626366;
  text-align: center;
}

.back {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding: 16px 24px;
  text-transform: uppercase;
  background: var(--primary-main);
  color: var(--primary-contrast-text);
  border-radius: 6px;
}

.back:visited {
  color: var(--primary-contrast-text);
}

@media (max-width: 768px) {
  .container {
    min-height: calc(100vh - 54px);
  }
}

@media (min-width: 768px) {
  .container {
    min-height: calc(100vh - 80px);
  }
}
