.container {
  display: grid;
  grid-template: auto/1fr;
  background: #ffffff;
  border: 1px solid #d87036;
  border-radius: 4px;
  padding: 24px 32px;
  gap: 16px;
  scroll-margin: 100px;
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 21.78px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-3);
  grid-row: 1;
  grid-column: 1;
  margin-bottom: -12px;
}

.description {
  font-weight: 400;
  color: var(--secondary-contrast-text);
  font-size: 14px;
  line-height: 23px;
  grid-row: 2;
  grid-column: 1;
}

.description p {
  margin: 0;
}

.preBookMeals {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #824320;
  padding: 8px 16px;
  margin: 0;
  height: 56px;
}

.illustration {
  object-fit: contain;
  grid-row: 1 / span 2;
  grid-column: 1;
  margin-top: -24px;
  margin-right: -24px;
  opacity: 0.4;
  /* align-self: flex-end; */
  justify-self: flex-end;
  height: 125px;
}

.gst {
  background-color: rgba(244, 248, 254, 0.7);
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  padding: 12px;
}

.menuContainer {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 16px;
}

.vegMenuContainer {
  display: grid;
}

.vegNonVegMenuContainer {
  display: grid;
}

.vegMenu {
  background-color: rgba(246, 251, 248, 1);
  border-radius: 4px;
}

.vegNonVegMenu {
  background: linear-gradient(98.77deg, #F6FBF8 10%, #FEF9F9 100%);
  border-radius: 4px;
}

.vegNonMenu {
  grid-column: 2;
  background-color: rgba(254, 249, 249, 1);
  border-radius: 4px;
}


@media (min-width: 800px) and (max-width: 1300px) {

  .container {
    padding: 16px;
    scroll-margin: 60px;
  }

  .menuContainer {
    display: grid;
    grid-template: auto auto / 1fr;
    gap: 16px;
    grid-row-start: 3;
    grid-column: 1;
  }

  .vegMenuContainer {
    grid-row: 3;
    grid-column: 1;
  }

  .vegNonVegMenuContainer {
    grid-row: 3;
    grid-column: 1;
  }

  .vegNonVegMenu {
    position: relative;
  }

  .vegNonVegMenu::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 4px;
    padding: 1px;
    background: linear-gradient(180deg, #D6EDD2 0%, #F4D5D5 100%);
    mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: xor;
  }

  .illustration {
    margin: -16px;
  }

  .vegMenu {
    position: relative;
    border: 1px solid #D6EDD2;
  }

  .vegNonMenu {
    grid-column: 1;
    grid-row: 2;
    border: 1px solid #F4D5D5;
  }

  .preBookMeals {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #824320;
    padding: 8px 16px;
    margin: 0;
    height: 56px;
  }

}

@media (min-width: 800px) and (max-width: 950px) {

  .title {
    font-size: 16px;
    font-weight: 600;
    color: rgba(35, 35, 35, 1);
    line-height: 22px;
  }

  .description {
    font-size: 12px;
    line-height: 19px;
  }

}


@media (max-width: 768px) {
  .container {
    padding: 16px 9px 0px 9px;
    scroll-margin: 60px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(31, 44, 56, 0.12);
  }

  .menuContainer {
    display: grid;
    grid-template: auto auto / 1fr;
    gap: 16px;
    grid-row-start: 3;
    grid-column: 1;
  }

  .vegMenuContainer {
    grid-row: 3;
    grid-column: 1;
  }

  .vegNonVegMenuContainer {
    grid-row: 3;
    grid-column: 1;
  }

  .vegNonVegMenu {
    position: relative;
  }


  .vegNonVegMenu::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 4px;
    padding: 1px;
    background: linear-gradient(180deg, #D6EDD2 0%, #F4D5D5 100%);
    mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    /* Standard property */
    -webkit-mask-composite: xor;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    color: rgba(35, 35, 35, 1);
    line-height: 22px;
  }

  .description {
    font-size: 12px;
    line-height: 19px;
    margin-bottom: -8px;
  }

  .illustration {
    grid-row: 1 / span 3;
    margin: -16px;
    height: 33vh;
  }

  .vegMenu {
    position: relative;
    border: 1px solid #D6EDD2;
  }

  .vegNonMenu {
    grid-column: 1;
    grid-row: 2;
    border: 1px solid #F4D5D5;
  }

  .preBookMeals {
    margin: 0px -9px;
    border-radius: 0px;
  }
}