.container {
  display: grid;
  grid-template: auto/1fr;
  background: white;
  border-radius: 4px;
  padding: 16px;
  border: 1px solid rgba(151, 151, 151, 0.12);
}

.header {
  display: grid;
  grid-template: auto/1fr auto;
  cursor: pointer;
  gap: 4px;
}

.subtitle {
  font-weight: 500;
  color: #707070;
}

.title {
  font-weight: bold;
  letter-spacing: 0.5px;
  color: var(--secondary-contrast-text);
}

.expand {
  grid-row: span 2;
  align-self: center;
  justify-self: center;
  width: 32px;
  height: 32px;
}

.policies {
  grid-column: span 2;
  display: grid;
  grid-template: auto/1fr;
  gap: 12px;
  padding-top: 20px;
}

.policy {
  color: #707070;
  font-weight: 500;
}

@media (max-width: 768px) {
  .title {
    font-size: 14px;
  }

  .subtitle {
    font-size: 12px;
  }

  .policy {
    font-size: 12px;
    padding-left: 22px;
    text-indent: -18px;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 16px;
  }

  .subtitle {
    font-size: 14px;
  }

  .policy {
    font-size: 14px;
    padding-left: 31px;
    text-indent: -23px;
  }
}
