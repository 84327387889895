.container {
    display: grid;
    gap: 16px;
    scroll-margin: 100px;
    grid-template: auto / auto 1fr;
  }
  
  .header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29.05px;
    color: var(--secondary-contrast-text-3);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .titleContainer,
  .stickyContainer {
    font-weight: 700;
    color: var(--secondary-contrast-text-3);
  }
  
  .reviewsCollaps {
    display: grid;
    grid-template: auto / auto 1fr;
    gap: 16px;
  }
  
  .nameInitials {
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    color: #c46f6f;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    text-align: -webkit-center;
    background: #eed6d6;
    align-content: center;
    margin-right: 8px;
  }
  
  .viewAll {
    color: #1f2c38;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    margin: -8px 0 8px auto;
    letter-spacing: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-self: flex-start;
    border: 1px solid #313131;
    border-radius: 6px;
    margin: 0;
    padding: 12px;
    margin-top: -24px;
  }
  
  .viewAllCollaps {
    color: #1f2c38;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    /* margin: -8px 0 8px auto; */
    letter-spacing: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-self: flex-start;
    border: 1px solid #313131;
    border-radius: 6px;
    margin: 0;
    padding: 12px;
    grid-column: 1;
    margin-top: -16px;
  }
  
  .guestRatings {
    display: grid;
    grid-template: auto / auto 1fr;
    align-items: center;
    gap: 8px;
    margin-top: -8px;
  }
  
  .avgRating {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: var(--secondary-contrast-text);
  }
  
  .ratingCount {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: var(--secondary-contrast-text);
    margin-left: 6px;
  }
  
  .stars {
    gap: 4px;
  }
  
  .readMoreIcon {
    justify-self: flex-end;
    grid-row: 1;
    align-self: center;
  }
  
  @media (min-width: 768px) {

    .guestRatingsCollaps {
      display: grid;
      grid-template: auto / auto 1fr;
      align-items: center;
      gap: 8px;
      padding: 8px 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      place-self: end;
      border-radius: 4px;
    }

    .readMoreIcon {
      display: none;
    }

    .collapse {
      display: none;
    }

    .guestReviewIcon{
      width: 16px !important;
      height: 16px !important;
    }

  }
  
  @media (max-width: 768px) {
    .container {
      display: block;
      scroll-margin: 70px;
    }

    .guestRatingsCollaps {
      display: grid;
      grid-template: auto / auto auto 1fr;
      align-items: center;
      gap: 8px;
      margin-top: -16px;
    }
  
    .reviewItem{
      display: none !important;
    }

    .guestReviewIcon{
      width: 12px !important;
      height: 12px !important;
    }
  
    .guestRatings {
      display: none;
    }
  
    .viewAll {
      display: none;
    }
  
    .titleContainer,
    .stickyContainer {
      font-size: 16px;
      grid-column: span 2;
      grid-row: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }
  
    .stickyContainer {
      position: sticky;
      top: calc(var(--navbar-height) - 4px);
      z-index: 0;
      background: var(--root-background);
      padding: 24px 16px;
      margin: 0 -16px;
    }
  
    .header {
      margin-bottom: -24px;
      line-height: 19.36px;
    }
  
    .guestRatingsCollaps {
      margin-top: 8px;
      gap: 4px;
      position: relative;
      margin-bottom: 16px;
    }
  
    .reviewsCollaps {
      margin-top: 16px;
    }
  
    .avgRating {
      font-size: 16px;
      line-height: 19.36px;
    }
  
    .ratingCount {
      font-size: 12px;
      line-height: 14.52px;
      text-decoration: none;
      margin-left: 4px;
    }
    .stars {
      gap: 4px;
    }
  
    .viewAllCollaps {
      margin-top: 16px;
      justify-content: center;
      font-size: 12px;
      line-height: 14px;
    }
  }
  