/* Not logged In Container*/
.container {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  max-width: 250px;
}

/* Silver Container */
.silverContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  max-width: 250px;
  position: relative;
  z-index: 1;
}

/* Pseudo-element for the gradient border */
.silverContainer::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1.5px;
  border-radius: 8px;
  background: linear-gradient(186.35deg, #939393 11.3%, #CFCFCF 37.6%, #838383 88.1%);
  -webkit-mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  mask-composite: exclude;
  z-index: -1;
}

/* Gold Container */
.goldContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  max-width: 250px;
  position: relative;
  z-index: 1;
}

/* Pseudo-element for the gradient border */
.goldContainer::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1.5px;
  border-radius: 8px;
  background: linear-gradient(197.35deg, #D6AB1F 11.3%, #FFDD6B 37.6%, #EDCB5A 88.1%);
  -webkit-mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  mask-composite: exclude;
  z-index: -1;
}

/* Platinum Container */
.platinumContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  max-width: 250px;
  position: relative;
  z-index: 1;
}

/* Pseudo-element for the gradient border */
.platinumContainer::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1.5px;
  border-radius: 8px;
  background: linear-gradient(197.35deg, #313033 11.3%, #807F81 37.6%, #514F54 88.1%);
  -webkit-mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  mask-composite: exclude;
  z-index: -1;
}

/* No tier Container*/
.noTierContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  max-width: 250px;
  border: 1px solid #AA3131;
}

.headerContainer {
  display: grid;
  grid-template: auto/auto 1fr;
  align-items: center;
  border-bottom: 1px solid #838383;
  padding: 0;
  height: var(--navbar-height);
}

.close {
  grid-row: 1;
  grid-column: 3;
}

.userName {
  color: var(--secondary-contrast-text);
  text-transform: capitalize;
  display: grid;
  align-items: center;
  grid-template: auto/1fr auto;
  font-weight: 600;
  font-size: 12px;
  color: #313131;
  gap: 8px;
}

/* .userAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: linear-gradient(97.92deg, #ACACAC 1.94%, rgba(227, 227, 227, 0.8) 49.86%),linear-gradient(166.48deg, #F8F8F8 31.71%, #9D9D9D 93.99%);
  color: #444444;
} */

/* .container[data-discount-type="gold"] {
  background:linear-gradient(272.49deg, #F2CE54 2.09%, rgba(208, 182, 93, 0.8) 50%),linear-gradient(0deg, #B7902A, #B7902A);
  color: #FFFFF7;
}

.container[data-discount-type="platinum"] {
  background: linear-gradient(97.92deg, rgba(123, 119, 127, 0.25) 40.28%, #7B777F 97.78%),linear-gradient(0deg, #18181A, #18181A);
  color: #FFFAED;
} */

.userAvatarImage {
  width: 24px;
  height: 24px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}


.userStatus {
  font-size: 8px;
  font-weight: 500;
  /* color: #898989; */
  text-transform: uppercase;
  /* margin-top: 6px; */
  letter-spacing: 2px;
}

.silverUserText {
  background: linear-gradient(90deg, #898989, #646464CC 80%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.goldUserText {
  background: linear-gradient(#E0B72F 9.31%, #FFDD6B 106.86%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: var(--llp-benefit-card-title-shadow-color);
}

.platinumUserText {
  background: linear-gradient(90deg, #1A191C, #57555A);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.userMenu {
  --menu-margin-top: calc(var(--navbar-height) / 2 - 14px);

  color: var(--secondary-contrast-text);
  font-size: 14px;
  padding: 16px 20px;
  font-weight: 500;
  min-width: 300px;
  border-bottom: 4px solid var(--primary-main);
  box-shadow: 0 16.47px 66.4222px rgba(167, 174, 186, 0.16);
}

.userMenu[data-open="true"] {
  display: block;
}

.userOption {
  display: flex;
  align-items: center;
  padding: 16px 0;
  cursor: pointer;
}

.rewards {
  display: grid;
  grid-template: auto/1fr auto;
  gap: 16px;
  align-items: center;
  padding: 16px 0;
  cursor: pointer;
}

.loginButton {
  display: flex;
  font-weight: 500;
  border: 1px solid;
  border-radius: 4px;
  background-color: var(--primary-contrast-text);
  /* color: var(--primary-main); */
  font-size: 14px;
  padding: 8px 16px;
  /* white-space: pre; */
  cursor: pointer;
  width: 150px;
}

.loginIcon {
  margin-right: 4px;
  width: 12px;
  height: 12px;
}

@media (max-width: 768px) {
  .container {
    display: none;
  }

  .mobileContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 8px 0px 16px;
  }

  .userAvatarImage {
    width: 32px;
    height: 32px;
  }

  .loginButton {
    width: 100%;
    padding: 8px 12px;
    height: 40px;
    align-items: center;
  }

  .loginIcon {
    margin-right: 8px;
  }

  /* .accountIcon,
  .userAvatar {
    position: absolute;
    top: 50%;
    transform: translateY(-52%);
    right: 20px;
    width: 30px;
  } */

  /* .userName {
    display: none;
  } */

  .logo {
    height: 32px;
  }

  .userName {
    font-weight: 600;
    font-size: 14px;
    color: #313131;
  }

  .userStatus {
    font-size: 10px;
    /* margin-top: 0px; */
    letter-spacing: 4px;
  }

  .rightForward {
    height: 18px;
    width: 18px;
    margin-left: auto;
  }

}

@media (min-width: 768px) {

  .mobileContainer {
    display: none;
  }

  .accountIcon {
    display: none;
  }

  /* .userAvatar {
    width: 40px;
  } */


  .isWhite {
    background: white;
  }

  /* .isWhiteName{
    color: white;
  } */

  .loginButton {
    grid-column: span 2;
    align-items: center;
    height: 40px;
  }

  .tncContent {
    text-align: center;
  }

  .logo {
    height: 52px;
  }
}