.bannerContainer {
  display: grid;
  grid-template: auto/1fr;
}

.title {
  grid-row: 1;
  grid-column: 1;
  color: #be4a48;
  margin: 0;
  align-self: center;
  justify-self: center;
}

.banner {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  height: 100%;
}

.container {
  box-shadow: 0 2px 10px 0 rgba(48, 48, 48, 0.15);
  border-radius: 6px;
  max-width: 1000px;
  background: white;
  z-index: 0;
}

.item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

@media (max-width: 768px) {
  .bannerContainer {
    height: 200px;
  }

  .title {
    font-size: 20px;
    margin-bottom: 50px;
  }

  .container {
    padding: 16px;
    margin: -50px auto 100px;
  }
}

@media (min-width: 768px) {
  .bannerContainer {
    height: 300px;
  }

  .title {
    font-size: 24px;
    margin-bottom: 100px;
  }

  .container {
    padding: 32px;
    margin: -32px auto 100px;
  }
}
