.container {
  display: grid;
  grid-gap: 0 16px;
  align-items: center;
  font-size: 14px;
  color: var(--secondary-contrast-text-2);
  font-weight: 500;
  letter-spacing: 0.5px;
  position: sticky;
  top: 0;
  transition: all 0.3s;
  z-index: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.13);
  background: var(--header-background);
}

.container[data-hidden='true'] {
  display: none;
}

.logoContainer {
  width: 160px;
}

.logo {
  max-width: 160px;
}

.header,
.header:visited {
  display: flex;
  align-self: center;
  white-space: nowrap;
}

.header[data-highlight = "true"]{
  font-weight: 600;
  color: var(--secondary-contrast-text);
}

.headerDropdown[data-highlight="true"]{
  font-weight: 600;
  color: var(--secondary-contrast-text);
}

.headerLoyaltyPoints {
  /* composes: header; */
  gap: 8px;
}

.menu {
  user-select: none;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.close {
  user-select: none;
  cursor: pointer;
  position: absolute;
  left: 250px;
  top: 20px;
  width: 20px;
  height: 20px;
}

.drawer {
  font-size: 16px;
  font-weight: 500;
  overflow: auto;
  color: #484848;
  width: 75%;
  padding: 48px 16px 24px 16px;
  display: grid;
  grid-template: auto/1fr;
  align-content: flex-start;
}

.drawerLink {
  display: flex;
  align-items: center;
  padding-top: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.drawerLinkLoyaltyPoints {
  /* composes: drawerLink; */
  gap: 8px;
  display: flex;
  align-items: center;
  padding-top: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.headerDropdown {
  display: none;
  color: var(--secondary-contrast-text-2);
}

.headerDropdownMenu,
.headerDropdownRewardsMenu {
  --menu-margin-top: calc(var(--navbar-height) / 2 - 14px);

  color: #484848;
  font-size: 14px;
  padding: 16px 20px;
  font-weight: 500;
  min-width: 300px;
  border-bottom: 4px solid var(--primary-main);
  box-shadow: 0 16.47px 66.4222px rgba(167, 174, 186, 0.16);
}

.headerDropdownCollectionsMenu {
  --menu-margin-top: calc(var(--navbar-height) / 2 - 14px);

  color: var(--secondary-contrast-text);
  font-size: 14px;
  padding: 16px 20px;
  font-weight: 500;
  min-width: 202px;
  border-bottom: 4px solid var(--primary-main);
  box-shadow: 0 16.47px 66.4222px rgba(167, 174, 186, 0.16);
}

.headerDropdownMenu[data-open="true"],
.headerDropdownRewardsMenu[data-open="true"],
.headerDropdownCollectionsMenu[data-open="true"] {
  display: block;
}

.anchor {
  /* font-family: 'Red Hat Display', sans-serif; */
  font-family: var(--font-family);
  display: block;
  padding: 12px 18px 12px 0;
  grid-column: 2;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.015em;
  color: #484848 !important;
}

.propertyGroup {
  display: grid;
  grid-template: auto/1fr;
  gap: 32px;
}

.locationTitle {
  color: var(--secondary-contrast-text-2);
  grid-column: 2;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  padding: 8px 0 8px 20px;
  position: sticky;
  top: 0;
}

.domesticProperties,
.internationalProperties {
  display: grid;
  grid-template: auto/1fr;
  max-height: 80vh;
  overflow: auto;
}

.domesticProperties {
  grid-column: 1;
  border-radius: 16px;
  background: #fafbfd;
  padding: 0 8px 8px;
}

.domesticProperties .locationTitle {
  background: #fafbfd;
  grid-column: 1/span 2;
  margin-left: -8px;
}

.groupedDomesticProperties {
  display: grid;
  grid-template: auto/1fr;
  align-content: flex-start;
}

.internationalProperties {
  padding: 0 16px 8px;
  grid-column: 2;
  align-self: flex-start;
}

.internationalProperties .locationTitle {
  background: #ffffff;
  margin: 0 -8px 0 -44px;
}

.location {
  grid-column: 1;
  align-self: center;
  padding: 8px 12px;
}

.collapsibleContainer {
  display: grid;
  grid-template: auto/1fr;
  gap: 0 8px;
  padding-top: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.content {
  position: relative;
  margin-bottom: -500%;
  padding: 8px 20px 0;
}

div.content[data-expanded="true"] {
  margin: 20px 0;
  margin-bottom: 0;
}

.decoration {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: var(--primary-main);
}

.collapsibleDivider {
  border-top: 2px solid #97979750;
  margin-bottom: -16px;
}

.expand {
  align-self: center;
}

.link {
  font-size: 12px;
  padding: 8px 0 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jump {
  align-self: center;
  height: 14px;
  margin-left: 16px;
}

.group {
  display: grid;
  grid-template: auto/1fr;
  gap: 8px;
  margin-right: -20px;
}

.divider {
  border-top: 1px solid #E6E6E6;
  margin-left: -32px;
  margin-right: -32px;
}

@media (max-width: 768px) {
  .container {
    height: 52px;
    grid-template: 1fr/1fr;
  }

  /* .logoContainer {
    padding-left: 32px;
  } */

  .logo {
    max-height: 40px;
  }

  .header {
    display: none;
  }

  .getAppMobile {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-52%);
    right: 64px;
    display: none;
  }

  .getAppDesktop {
    display: none;
  }

  .paddingdrawerLink{
    padding-top: 16px !important;
  }

  div.dropdownHeader {
    display: none;
  }

  .headerLoyaltyPoints {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-52%);
    right: 64px;
  }
}

@media (min-width: 768px) {
  .container {
    height: 80px;
    grid-template: 1fr/1fr repeat(9, auto);
  }

  .logoContainer {
    justify-self: flex-start;
  }

  .logo {
    max-height: 52px;
  }

  .menu {
    display: none;
  }

  .getAppMobile {
    display: none;
  }

  .getAppDesktop {
    cursor: pointer;
  }

  .headerDropdown {
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
  }

  .headerDropdownMenu {
    --menu-margin-left: -250px;
  }

  .headerDropdownRewardsMenu {
    --menu-margin-left: -60px;
  }

  .headerDropdownCollectionsMenu {
    --menu-margin-left: -60px;
  }

  .anchor {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* .login {
    margin-left: -16px;
    margin-right: -24px;
  } */

  .headerLoyaltyPoints {
    margin-left: -8px;
  }
}