.container {
  display: grid;
  grid-template: auto/1fr 1fr;
  height: 54px;
  background: #18262a;
  color: #cfa181;
  position: sticky;
  bottom: 0;
  margin: 0 -16px;
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
}

.action:not(:last-child) {
  border-right: 1px solid #52686e;
}

.icon {
  margin-right: 8px;
  width: 18px;
  height: 18px;
}

@media (min-width: 768px) {
  .container {
    display: none;
  }
}