.title {
  color: #121212;
  font-weight: 500;
}

.itemContainer {
  display: grid;
  grid-template: auto/1fr;
}

.re {
  margin: 48px 0;
}

.reTitle {
  color: #121212;
  font-size: 24px;
  margin-bottom: 18px;
}

@media (max-width: 768px) {
  .title {
    font-size: 16px;
    letter-spacing: 0.5px;
    margin: 21px 0;
  }

  .itemContainer {
    gap: 32px;
    margin-bottom: 32px;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 32px;
    letter-spacing: 0.5px;
    text-align: center;
    margin: 42px 0;
  }

  .itemContainer {
    gap: 48px;
    margin-bottom: 48px;
  }
}
