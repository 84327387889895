.contentContainer {
  grid-row: 1;
  grid-column: 1;
}

.title {
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  color: #464E68;
}

.description {
  margin-top: 24px;
  color: var(--secondary-contrast-text);
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.imageContainer {
  grid-column: 2;
  display: grid;
  grid-template: auto/1fr 1fr;
  gap: 24px;
}

.imageContainer img:first-child{
  margin-top: 48px;
}

@media (max-width: 768px) {
  .title {
    font-size: 18px;
    line-height: 22px;
  }

  .description {
    font-size: 14px;
    line-height: 24px;
    margin-top: 16px;
  }

  .imageContainer {
    margin-top: 16px;
    gap: 8px;
  }

  .imageContainer img{
    height: 180px;
    width: 100%;
    object-fit: cover;
  }

}

@media (min-width: 768px) {
  .corporateContainer {
    display: grid;
    grid-template: 1fr/auto 1fr;
    gap: 24px;
    margin-bottom: -8px;
  }

  .title {
    white-space: pre;
  }
}