.container {
  background: white;
  max-width: 800px;
  border-radius: 6px;
  display: grid;
  grid-template: auto/1fr auto;
  gap: 8px 32px;
  padding: 20px;
}

.close {
  grid-row: 1;
  grid-column: 2;
  align-self: center;
  justify-self: flex-end;
  margin-bottom: 18px;
  cursor: pointer;
}

.title {
  color: #474e66;
  font-weight: 700;
  font-size: 20px;
  grid-row: 1;
  grid-column: 1/span 2;
  border-bottom: 1px solid #97979740;
  padding: 8px 4px 22px;
}

.howItWorks {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: var(--secondary-contrast-text);
  line-height: 20px;
  display: grid;
  grid-template: auto/1fr;
  gap: 12px;
  padding-left: 24px;
}

.howItWorksTitle {
  color: #474e66;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.3px;
  margin-left: -12px;
}

.qrCode {
  width: 160px;
  border: 1px solid lightgray;
  border-radius: 4px;
  align-self: flex-end;
}

.appStoreContainer {
  display: grid;
  grid-template: auto/auto auto;
  gap: 12px 16px;
  justify-content: center;
  grid-column: span 2;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #97979740;
}

.downloadFrom {
  grid-column: span 2;
  text-align: center;
  font-weight: 700;
  color: #474e66;
}
