.container {
  display: grid;
}

.points {
  align-self: flex-start;
  justify-self: flex-start;
  justify-items: center;
}

.gimmick {
  margin-bottom: 20px;
}

.book {
  color: #ffffff;
  height: 44px;
  width: 280px;
  justify-self: center;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr;
    gap: 16px;
    padding: 22px 0;
  }

  .book {
    display: none;
  }

  .tierBenefit {
    justify-self: center;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: auto auto 1fr / auto auto;
    justify-content: space-evenly;
    gap: 16px;
    padding: 18px 0;
    min-height: 510px;
  }

  .gimmick {
    margin-top: -28px;
  }

  .tierBenefit {
    grid-row: 1 / span 3;
    grid-column: 2;
  }

  .tierBenefit :global .cta {
    display: none;
  }
}
