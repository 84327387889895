.container {
  display: grid;
  grid-template: auto/1fr 1fr;
}

.icon {
  width: 40px;
  object-fit: contain;
  border-radius: 50%;
  border: 1px solid rgba(116, 93, 77, 0.2);
  padding: 8px
}

.generic {
  width: 20px;
  object-fit: contain;
}

.amenity {
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 16px;
  align-items: center;
  font-weight: 500;
  color: var(--secondary-contrast-text);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.viewAll {
  color: #1F2C38;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  margin: -8px 0 8px auto;
  letter-spacing: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-self: flex-start;
  border: 1px solid #313131;
  border-radius: 6px;
  margin: 0;
  padding: 12px;
}

.viewAll.expanded {
  display: none;
}

/* .viewAllIcon {
  margin-left: 4px;
} */

.collapse {
  grid-column: span 2;
}

.collapseInner {
  display: grid;
  grid-template: auto/1fr 1fr;
}

.viewAllExpanded {
  color: var(--primary-main);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  margin: 8px 0;
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-self: flex-start;
}

@media (max-width: 768px) {
  .container {
    background: #F6F7F7;
    border-radius: 4px;
    padding: 16px;
    gap: 0 32px;
  }

  .title {
    display: none;
  }

  .responsiveTitle {
    font-weight: 600;
    color: var(--secondary-contrast-text-3);
    font-size: 16px;
    line-height: 19px;
    grid-column: span 2;
    gap: 18px;
    margin-bottom: 16px;
  }

  .icon {
    display: none;
  }

  .viewAll {
    display: block;
    grid-column: span 2;
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    margin-top: 16px;
  }

  .amenity {
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    padding: 9px 0px;
  }

  .collapseInner {
    gap: 0 32px;
  }

  .centerBorder {
    border-right: 1px solid #EFEFEF;
  }
}

@media (min-width: 768px) {
  .container {
    gap: 16px;
  }

  .title {
    font-weight: 700;
    color: var(--secondary-contrast-text-3);
    font-size: 24px;
    grid-column: span 2;
  }

  .responsiveTitle {
    display: none;
  }

  .amenity {
    font-size: 18px;
    margin-bottom: 8px;
    height: 40px;
  }

  .collapseInner {
    gap: 16px;
    padding-bottom: 16px;
  }

  .viewAll {
    grid-column: 1;
    justify-self: flex-start;
    padding: 12px 24px;
    height: 42px;
    margin-top: -16px;
  }
}