.background {
  position: absolute;
  left: 0;
  width: 100%;
  object-fit: cover;
}

.container {
  position: relative;
  overflow-y: scroll;
}

.title {
  color: white;
  text-align: center;
  z-index: 0;
  align-self: flex-end;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
}

.reports {
  display: grid;
  justify-items: center;
  grid-template: auto/repeat(auto-fill, 350px);
  gap: 16px;
  height: calc(100vh - 80px);
  align-content: flex-start;
  justify-content: center;
}

.item {
  min-height: 150px;
  display: grid;
  grid-template: 1fr auto/1fr;
  gap: 16px;
  padding: 20px 24px;
  background: white;
  border-radius: 5px;
  color: var(--secondary-contrast-text-3);
  font-size: 16px;
  text-align: center;
  align-self: flex-start;
  z-index: 0;
  font-weight: 500;
  width: 100%;
}

.download {
  justify-self: center;
}

a.download {
  color: white;
}

.downloadIcon {
  margin-right: 8px;
}

@media (max-width: 768px) {
  .background {
    top: 54px;
    min-height: calc(100vh + 60px);
  }

  .title {
    font-size: 22px;
    margin: 32px 0;
  }
}

@media (min-width: 768px) {
  .background {
    top: 80px;
    min-height: calc(100vh + 150px);
  }

  .title {
    font-size: 28px;
    margin: 64px 0;
  }
}
