.placeholder {
  min-height: calc(100vh - var(--navbar-height));
}

.container {
  display: grid;
  align-items: flex-start;
}

.secureDesktop {
  margin-top: 24px;
}

.title {
  color: var(--secondary-contrast-text);
  font-weight: bold;
  letter-spacing: 0.5px;
}

.details {
  display: grid;
  grid-template: auto/1fr 1fr;
  gap: 16px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid rgba(151,151,151,0.2);
  background: white;
}

.detailsTitle {
  color: var(--secondary-contrast-text);
  font-weight: bold;
  letter-spacing: 0.5px;
  grid-column: span 2;
}

.detailsSubtitle {
  color: #626366;
  grid-column: span 2;
}

.phoneNoContainer {
  display: grid;
  grid-template: auto/120px 1fr;
  gap: 16px
}

.note {
  grid-column: span 2;
}

.tnc {
  grid-column: span 2;
}

.tncContent {
  color: #707070;
  margin: 4px 0 0 8px;
}

.tncContent a {
  font-weight: 500;
  text-decoration: underline;
  margin: 0 0.1ch;
}

.submitDesktop {
  grid-column: span 2;
}

.earningsDesktop {
  background: #ffffff;
  border: 1px solid rgba(151, 151, 151, 0.119045);
  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #1f2c38;
  padding: 16px;
  margin-bottom: 16px;
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 16px;
  align-items: center;
}

.earningsMobile {
  background: #ffffff;
  border: 1px solid rgba(151, 151, 151, 0.119045);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #1f2c38;
  padding: 12px 16px 12px 8px;
  display: grid;
  grid-template: auto/auto 1fr;
  gap: 12px;
  align-items: center;
}

.protectContainer {
  border: 1px solid rgba(151, 151, 151, 0.25);
  border-radius: 4px;
}

.phoneNo input{
  font-family: var(--font-family) !important;
  color: var(--secondary-contrast-text) !important;
  font-weight: 420;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr;
    gap: 16px;
    padding: 32px 0;
  }

  .secureDesktop {
    display: none;
  }

  .secureMobile {
    grid-row: 1;
  }

  .stickyContainer {
    margin: 0 -16px;
    border-radius: 0;
    border: none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06);
    grid-row: 5;
  }

  .cancellation {
    margin: 0 -16px;
    border-radius: 0;
    border: none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06);
  }

  .title {
    font-size: 12px;
  }

  .summary {
    margin: 0 -16px;
    border-radius: 0;
    border: none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06);
    grid-row: 4;
  }

  .protectContainer {
    border: none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06);
    margin: 0 -16px;
    grid-row: 2;
  }

  .details {
    margin: 0 -16px;
    border-radius: 0;
    border: none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06);
    grid-row: 3;
  }

  .detailsTitle {
    font-size: 14px;
  }

  .detailsSubtitle {
    display: none;
  }

  .fullName {
    grid-column: span 2;
  }

  .phoneNoContainer {
    grid-column: span 2;
  }

  .tncContent {
    font-size: 10px;
    letter-spacing: 0.3px;
  }

  .submitDesktop {
    display: none;
  }

  .breakdown {
    border-radius: 0;
    border: none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06);
  }

  .earningsDesktop {
    display: none;
  }

  .phoneNo input{
    font-size: 14px;
  }

  .textField label{
    font-size: 12px;
  }

  .fullName label{
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: auto auto 1fr/1fr minmax(40%,300px);
    gap: 16px 32px;
    padding: 28px 0 64px;
    align-items: first baseline;
  }

  .secureMobile {
    display: none;
  }

  .stickyContainer {
    grid-row: span 4;
    align-items: start;
    top: 0;
    position: sticky;
  }

  .title {
    font-size: 14px;
  }

  .detailsTitle {
    font-size: 18px;
  }

  .detailsSubtitle {
    font-size: 14px;
  }

  .tncContent {
    font-size: 12px;
    letter-spacing: 0.4px;
  }

  .earningsMobile {
    display: none;
  }

}
