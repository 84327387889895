.container {
  display: grid;
  grid-template: auto/1fr;
  background: rgba(170, 49, 49, 0.1);
  border-radius: 4px;
  padding: 32px 0 32px 32px;
  gap: 16px;
}

.title {
  font-weight: 700;
  color: #701F1F;
  font-size: 24px;
  line-height: 29px;
  grid-row: 1;
  grid-column: 1;
}

.description {
  font-weight: 500;
  color: var(--secondary-contrast-text);
  font-size: 20px;
  line-height: 28px;
  grid-row: 2;
  grid-column: 1;
  max-width: 500px;
}

.illustration {
  object-fit: contain;
  grid-row: 1/span 2;
  grid-column: 1;
  margin: -32px 0 -32px -32px;
  opacity: 0.2;
  align-self: flex-end;
  justify-self: flex-end;
  height: calc(100% + 32px);
}

@media (max-width: 768px) {
  .container {
    padding: 16px 0 16px 16px;
  }

  .title {
    font-size: 16px;
    line-height: 19px;
  }

  .description {
    font-size: 14px;
    line-height: 17px;
    width: 260px;
  }

  .illustration {
    margin: -16px 0 -16px -16px;
    height: calc(100% + 16px);
  }
}