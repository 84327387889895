.container {
  background: white;
  width: 100%;
  padding: 0 16px 16px;
  height: 100%;
  display: grid;
  gap: 16px;
  overflow: auto;
}

.title {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  background: white;
  margin: 0 -16px;
  padding: 0 16px;
  width: 100vw;
}

.close {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.imageContainer {
  display: grid;
  margin: 0 auto;
  gap: 16px;
}

.image {
  object-fit: cover;
  border-radius: 4px;
}

.imageLocation{
  width: 100%;
  grid-column: span 3 ;
}

.image:global.landscape {
  aspect-ratio: 2/1;
}

@media (max-width: 768px) {
  .imageContainer {
    grid-template: auto/1fr;
    width: 100%;
  }

  .image {
    width: calc(100vw - 32px);
  }
}

@media (min-width: 768px) {
  .imageContainer {
    grid-template: auto/repeat(3, 1fr);
  }

  .image:global.portrait {
    height: 80vh;
    width: 100%;
    justify-self: center;
  }

  .image:global.landscape {
    width: 100%;
    grid-column: span 3;
  }
}

@media (min-width: 768px) and (max-width: 1032px) {
  .imageContainer {
    width: 760px;
  }
}

@media (min-width: 1032px) and (max-width: 1632px) {
  .imageContainer {
    width: 1000px;
  }
}

@media (min-width: 1632px) and (max-width: 2032px) {
  .imageContainer {
    width: 1600px;
  }
}

@media (min-width: 2032px) {
  .imageContainer {
    width: 2000px;
  }
}