/* Used desktop first approach */
.container {
  display: grid;
  padding-top: 54px;
  padding-bottom: 54px;
  gap: 32px;
  color: var(--secondary-contrast-text);
  background: #F5F5F7;
}

.title {
  justify-self: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.brands {
  display: grid;
  gap: 24px;
}

@media (max-width: 768px) {
  .container {
    padding-top: 24px;
    padding-bottom: 24px;
    gap: 16px;
    margin-top: -32px;
  }

  .title {
    font-size: 16px;
    line-height: 19px;
  }

  .brands {
    grid-template: 1fr/repeat(12, 1fr);
    overflow-x: scroll;
    gap: 8px;
  }

  .brand {
    height: 56px;
  }
}

@media (min-width: 768px) {
  .container {
    margin-top: -72px;
    justify-content: center;
  }

  .brands {
    grid-template-columns: repeat(6, minmax(60px, 110px));
  }

  .brand {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
