.locationContainer {
  background: #1B1B1B;
  padding-bottom: 32px;
  color: #FFFFFF99;
}

.propertyTitle {
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #FFFFFF1F;
  padding-bottom: 16px;
  margin-bottom: 20px;
}

.locationLinks {
  display: grid;
}

.location {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.container {
  display: grid;
  gap: 24px;
  align-items: flex-start;
  color: rgba(193, 193, 193, 0.6);
  background: #100e0d;
  padding-top: 32px;
  padding-bottom: 32px;
}

.hideFooter[data-hidden='true'] {
  display: none;
}

.brand {
  color: #FFFFFF99;
  font-size: 12px;
}

.logo {
  margin: 16px 0;
  width: 230px;
}

.title {
  color: #D1D1D1;
}

.quickLinks {
  display: grid;
  grid-template: auto/1fr 1fr;
}

.quickLinks .title {
  grid-column: span 2;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #FFFFFF14;
}

a.quickLink {
  white-space: nowrap;
  justify-self: flex-start;
  text-align: left;
  font-weight: 500;
  color: #FFFFFF99;
}

.address {
  display: grid;
  grid-template: auto/1fr;
}

.addressContent {
  color: #FFFFFF99;
}

.address > .title {
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #FFFFFF14;
}

.callUs {
  display: grid;
  gap: 16px;
}

.phoneNo {
  font-weight: 500;
  color: #FFFFFFCC;
}

.enquireNow {
  padding: 14px;
  border: 1px solid white;
  border-radius: 4px;
  color: #FFFFFFCC;
  background: transparent;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  width: 160px;
  cursor: pointer;
}

.followUs {
  display: grid;
  grid-template: auto/repeat(6, auto);
  align-items: center;
}

.followUs .title {
  white-space: pre;
  font-weight: 600;
  color: #FFFFFF99;
}

.socialIcon {
  display: flex;
  align-items: center;
}

.declaration {
  border-top: 1px solid #FFFFFF14;
  color: #FFFFFF99;
}

.tnc {
  display: inline-flex;
  flex-direction: row;
}

.tnc *:first-child {
  padding-right: 32px;
  border-right: 1px solid rgba(193, 193, 193, 0.6);
}

.tnc *:last-child {
  padding-left: 32px;
}

.copyright {
  display: inline-block;
  font-size: 12px;
}

@media (max-width: 768px) {
  .propertyTitle {
    padding-top: 24px;
  }

  .locationContainer {
    padding-bottom: 24px;
  }

  .locationLinks {
    grid-template: auto/repeat(auto-fit, minmax(150px, 1fr));
    gap: 16px;
  }

  .location {
    font-size: 12px;
    line-height: 15px;
  }

  .container {
    grid-template: auto/1fr;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .brand {
    line-height: 18px;
  }

  .quickLinks .title {
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 12px;
  }

  .quickLinks {
    gap: 16px;
  }

  .quickLink {
    font-size: 12px;
    line-height: 15px;
  }

  .address {
    gap: 16px;
    line-height: 17px;
  }

  .address > .title {
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 12px;
  }

  .addressContent {
    font-size: 12px;
    line-height: 20px;
  }

  .callUs {
    grid-template: auto/auto auto;
    justify-content: flex-start;
  }

  .phoneIcon {
    display: none;
  }

  .phoneNo {
    padding: 14px;
    border: 1px solid white;
    border-radius: 4px;
    width: 160px;
    white-space: nowrap;
    font-size: 14px;
    line-height: 16px;
  }

  .enquireNow {
    font-size: 14px;
    line-height: 16px;
  }

  .followUs {
    margin-top: 24px;
    gap: 12px;
  }

  .followUs .title {
    font-size: 12px;
    line-height: 15px;
    grid-column: span 6;
  }

  .socialIcon {
    margin-right: -36px;
  }

  .declaration {
    padding-top: 24px;
    gap: 8px;
  }

  .tnc {
    font-size: 10px;
    line-height: 12px;
  }

  .tnc *:first-child {
    padding-right: 12px;
  }

  .tnc *:last-child {
    padding-left: 12px;
  }

  .copyright {
    font-size: 10px;
    line-height: 12px;
  }
}

@media (min-width: 768px) {
  .propertyTitle {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding-top: 32px;
  }

  .location {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
  }

  .locationLinks {
    grid-template: auto/repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
  }

  .container {
    grid-template: auto/repeat(3, 1fr);
  }

  .brand {
    line-height: 24px;
  }

  .quickLinks {
    gap: 20px 48px;
  }

  .quickLinks .title {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 16px;
  }

  .quickLink {
    font-size: 14px;
    line-height: 17px;
  }

  .address {
    line-height: 24px;
    margin-left: 64px;
    gap: 20px;
  }

  .addressContent {
    margin-right: 50px;
    letter-spacing: 0.5;
    font-size: 14px;
    line-height: 22px;
  }

  .address > .title {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 16px;
  }

  .callUs {
    grid-template: auto/1fr;
  }

  .phoneNo {
    grid-row: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  .enquireNow {
    font-size: 14px;
    grid-row: 1;
  }

  .followUs {
    margin-top: 32px;
    gap: 16px;
  }

  .followUs .title {
    font-size: 14px;
    line-height: 26px;
  }

  .declaration {
    margin: 16px 0 0 0;
    font-size: 12px;
    padding-top: 24px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    grid-template: auto/1fr 1fr;
  }

  .brand {
    grid-column: span 2;
  }

  .quickLinks {
    gap: 20px;
  }

  .address {
    margin-left: 0;
  }

  .phoneNo {
    padding: 14px 10px;
  }

  .declaration {
    grid-column: span 2;
    justify-items: flex-start;
    gap: 16px;
  }
}

@media (max-width: 1024px) {
  .declaration {
    display: grid;
    grid-template: auto/1fr;
  }
}

@media (min-width: 1024px) {
  .address {
    margin-left: 0;
  }

  .declaration {
    grid-column: span 4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}