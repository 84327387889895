.container {
  display: grid;
  max-height: 680px;
  color:#FFFFFF;
}

.background {
  grid-column: 1;
}

.background :global .image {
  object-fit: cover;
}

.backdrop {
  grid-column: 1;
}

.title {
  grid-row: 1;
  grid-column: 1;
  font-weight: 800;
  align-self: flex-end;
  margin: 0;
}

.subtitle {
  grid-row: 2;
  grid-column: 1;
  font-weight: 400;
}

.enquireNow {
  grid-row: 3;
  grid-column: 1;
  justify-self: flex-start;
  font-weight: 500;
  color: #FCF5F0;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .container {
    grid-template: 70% auto 1fr/1fr;
    gap: 16px;
    max-height: 494px;
  }

  .background {
    grid-row: 1/span 3;
  }

  .backdrop {
    grid-row: 1/span 3;
  }

  .title {
    font-size: 20px;
    line-height: 24px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 17px;
    grid-row: 2;
    margin-top: -8px;
  }

  .enquireNow {
    font-size: 12px;
    line-height: 15px;
    width: 138px;
    height: 38px;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: 50% auto 1fr/1fr;
    gap: 24px;
  }

  .background {
    grid-row: 1/span 3;
  }

  .backdrop {
    grid-row: 1/span 3;
  }

  .title {
    font-size: 42px;
    line-height: 52px;
    white-space: pre;
  }

  .subtitle {
    margin-top: -8px;
    font-size: 24px;
    line-height: 28px;
    white-space: pre;
  }

  .enquireNow {
    font-size: 14px;
    line-height: 16px;
    width: 180px;
    height: 54px;
  }
}