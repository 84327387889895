.form {
  display: grid;
  grid-template: auto/repeat(2, calc(50% - 4px));
  gap: 8px;
  align-content: flex-start;
}

.twoColumn input{
  font-family: var(--font-family) !important;
  color: var(--secondary-contrast-text) !important;
  font-weight: 420;
}

.twoColumn{
  grid-column: span 2;
}

.msgColumn {
  grid-column: span 2;
}

.msgColumn textarea{
  font-weight: 420;
  font-family: var(--font-family) !important;
  color: var(--secondary-contrast-text) !important;
}

.phoneNo input{
  font-weight: 420;
  font-family: var(--font-family) !important;
  color: var(--secondary-contrast-text) !important;
}


.phoneNoMinimal input{
  font-weight: 420;
  font-family: var(--font-family) !important;
  color: var(--secondary-contrast-text) !important;
}

@media (max-width: 768px) {
  .twoColumn input{
    font-size: 14px;
  }

  .msgColumn textarea{
    font-size: 14px;
  }

  .phoneNo input{
    font-size: 14px;
  }

  .phoneNoMinimal input{
    font-size: 14px;
  }
}

