.container {
  display: grid;
  grid-template: repeat(5, auto) 1fr/1fr;
  gap: 8px;
  justify-items: center;
  height: 100vh;
}

.close {
  justify-self: flex-start;
  margin: 20px 0 0 0;
}

.logo {
  width: 120px;
  margin-bottom: 20px;
}

.title {
  color: var(--secondary-contrast-text-3);
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.description {
  color: var(--secondary-contrast-text);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  white-space: pre;
  line-height: 22px;
}

.verify {
  cursor: pointer;
  border: none;
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
  margin: 0 0 8px;
  background: var(--primary-main);
  color: var(--primary-contrast-text);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  height: 45px;
  align-self: flex-end;
}

@media (min-width: 768px) {
  .verify {
    align-self: flex-start;
    margin-top: 80px;
  }
}
