.container {
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 6px;
  width: 70px;
  overflow: hidden;
  scroll-behavior: smooth;
}

.CenteredContainer {
  display: inline-flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 6px;
  width: 70px;
  overflow: hidden;
  scroll-behavior: smooth;
}

.item {
  width: fit-content;
  height: 24px;
  display: grid;
  grid-template: auto/1fr;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  /* margin-right: 3px;
  margin-left: 3px; */
  padding: 0 4px;
}

.item > * {
  grid-column: 1;
  grid-row: 1;
}

.itemActive .inner {
  opacity: 1;
  transform: scale(1);
}

.itemActive .outer {
  opacity: 1;
  transform: scale(2);
}

.itemActive .innerBlack {
  opacity: 1;
  transform: scale(1);
}

.itemActive .outerBlack {
  opacity: 1;
  /* transform: scale(2); */
}

.inner {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background: white;
  opacity: 0.5;
  transition: all 0.3s;
}

.outer {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  transform: scale(1);
  opacity: 0;
  transition: all 0.3s;
}

.innerBlack {
  width: 6px;
  height: 6px;
  background: #1F2C38;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
  transition: all 0.3s;
}

.outerBlack {
  width: 6px;
  height: 6px;
  background: #1F2C38;
  border-radius: 50%;
  display: inline-block;
  transform: scale(1);
  opacity: 0;
  transition: all 0.3s;
}

.rightArrowIcon {
  position: absolute;
  top: 46%;
  right: 0;
}

.leftArrowIcon {
  position: absolute;
  top: 46%;
  left: 0;
}

@media (max-width: 768px) {
  .rightArrowIcon {
    top: calc(50% - 24px);
    right: -15px;
  }

  .filledRightArrowIcon{
    display: none;
  }

  .filledLeftArrowIcon{
    display: none;
  }
  
  .leftArrowIcon {
    top: calc(50% - 24px);
    left: -15px;
  }

}

@media (min-width: 768px) {


  .filledRightArrowIcon{
    right: 16px;
  }
  
  .filledLeftArrowIcon{
    left: 16px;
  }
  
}