.container {
  display: grid;
  gap: 0 32px;
  align-items: center;
  font-size: 15px;
  color: white;
  font-weight: 500;
  letter-spacing: 0.5px;
  background: rgba(36, 48, 60, 0.9);
  box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.1);
}

.logoContainer {
  width: 160px;
}

.logo {
  max-width: 160px;
}

.header {
  display: flex;
  align-self: center;
  white-space: nowrap;
  padding: 16px 0;
}

.menu {
  user-select: none;
  cursor: pointer;
}

.drawer {
  font-size: 16px;
  font-weight: 500;
  overflow: auto;
  background: #24303c;
  color: #d6d6d6;
  padding: 80px 32px 0;
  display: grid;
  grid-template: auto/1fr;
  align-content: flex-start;
  width: 300px;
}

.close {
  user-select: none;
  cursor: pointer;
  position: absolute;
  left: 24px;
  top: 24px;
  width: 24px;
  height: 24px;
}

.drawerLink {
  padding: 16px 0;
}

@media (max-width: 768px) {
  .container {
    height: 64px;
    grid-template: 1fr/1fr;
  }

  .logoContainer {
    justify-self: center;
    display: flex;
    justify-content: center;
    grid-row: 1;
    grid-column: 1;
  }

  .logo {
    max-height: 40px;
  }

  .header {
    display: none;
  }

  .menu {
    grid-row: 1;
    grid-column: 1;
    justify-self: flex-start;
    align-self: center;
  }
}

@media (min-width: 768px) {
  .container {
    height: 92px;
    grid-template: 1fr/1fr repeat(3, auto);
  }

  .logoContainer {
    justify-self: flex-start;
  }

  .logo {
    max-height: 52px;
  }

  .menu {
    display: none;
  }
}
