.button {
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;
  user-select: none;
  appearance: none;
  text-transform: uppercase;
  line-height: 1.75;
  letter-spacing: 0.5px;
  padding: 6px 16px;
  border-radius: 4px;
  background: var(--primary-main);
  color: var(--primary-contrast-text);
  box-shadow: rgb(0 0 0 / 20%) 0 3px 1px -2px, rgb(0 0 0 / 14%) 0 2px 2px 0, rgb(0 0 0 / 12%) 0 1px 5px 0;
  font-family: var(--font-family);
}
