.history {
  background: #1B1B1B;
  box-shadow: 0px 4.604650974273682px 9.209301948547363px 0px #00000014;
  border-radius: 6px;
  font-weight: 500;
  padding: 16px;
  display: grid;
  grid-template: auto/1fr 1fr 1fr;
  gap: 2px;
  margin: 16px 0;
}

.dateContainer {
  color: #EEEEEE;
  background: #151515;
  opacity: 0.6;
  grid-row: 1;
  grid-column: span 3;
  justify-self: center;
  border-radius: 4px;
  padding: 6px;
  margin: 0 0 10px;
}

.amount {
  grid-row: 2;
  grid-column: 3;
  justify-self: flex-end;
  letter-spacing: 0.1px;
}

.comment {
  grid-row: 3;
  grid-column: 3;
  white-space: pre;
  justify-self: flex-end;
  text-transform: uppercase;
}

.propertyName {
  grid-row: 2;
  grid-column: 1/span 2;
  color: #FFFFFFCC;
}

.location {
  grid-row: 3;
  grid-column: 1/span 2;
  color: #FFFFFF99;
  font-weight: 400;
}

.calendarIcon {
  padding: 0 6px;
}

.amount[data-transaction="credit"],
.comment[data-transaction="credit"] {
  color: #00A699;
}

.amount[data-transaction="debit"],
.comment[data-transaction="debit"] {
  color: #DA4B4B;
}

.amount[data-transaction="pending"],
.comment[data-transaction="pending"] {
  color: #ED932A;
}

@media(max-width: 768px) {
  .dateContainer {
    font-size: 12px;
    line-height: 16px;
  }

  .propertyName {
    font-size: 14px;
    line-height: 22px;
  }

  .location {
    font-size: 12px;
    line-height: 16px;
  }

  .amount {
    font-size: 14px;
    line-height: 22px;
  }

  .comment {
    font-weight: 500;
    font-size: 10px;
  }
}

@media(min-width: 768px) {
  .dateContainer {
    font-size: 14px;
    line-height: 17px;
  }

  .propertyName {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
  }

  .location {
    font-size: 14px;
    line-height: 16px;
  }

  .amount {
    font-size: 16px;
    line-height: 19px;
  }

  .comment {
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }
}