.container {
  background: white;
  display: grid;
  border: 1px solid #F2F2F2;
  padding: 24px;
  position: relative;
}

.container a {
  color: var(--secondary-contrast-text);
}

a{
  margin-right: 0 !important;
}

.thumbnail :global .image {
  object-fit: cover;
}

.title {
  font-weight: 600;
  margin: 0;
  /* grid-column: span 2; */
}

.full_title{
  font-weight: 600;
  margin: 0;
}

.subtitle {
  grid-column: span 3;
}

.specContainer {
  display: flex;
  flex-direction: row;
  grid-column: span 2;
}

.spec {
  padding: 0 8px;
  border-right: 1px solid rgba(53,58,60,0.3);
}

.line{
  border-bottom: 1px solid rgba(215, 215, 215, 1);
}

.spec:first-child {
  padding-left: 0;
}

.spec:last-child {
  padding-right: 0;
  border-right: none;
}

.datesContainer {
  background:white;
  align-self: flex-end;
  color: var(--secondary-contrast-text-2);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.sold{
  background: #F8E0D4;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 10px;
  color: #DA6528;
}
.dates {
  color: var(--secondary-contrast-text);
  line-height: 14px;
  font-weight: 600;
}

.pricingContainer {
  display: grid;
}

.excTax {
  color: #a2a2a2;
}

.strike {
  text-decoration: line-through;
}

.amount {
  font-weight: 600;
  line-height: 22px;
  margin-right: 4px;
}

.view {
  text-transform: uppercase;
  color: var(--primary-main);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin-right: -8px;
}

.soldOut {
  border-radius: 4px;
  font-weight: bold;
  letter-spacing: 0.88px;
  color: #da6528;
  text-transform: capitalize;
  float: right;
}

.upcoming {
  border-radius: 4px;
  background-color: rgba(250, 100, 0, 0.1);
  font-weight: 500;
  letter-spacing: 1.21px;
  color: #cc580b;
  text-transform: uppercase;
  position: absolute;
  font-size: 10px;
  padding: 8px 14px;
}

.unavailable {
  border-radius: 4px;
  background-color: rgba(183,55,53,0.08);
  font-weight: 500;
  letter-spacing: 0.88px;
  color: #b73735;
  text-transform: capitalize;
  position: absolute;
}

.black {
  background: #24303c;
  box-shadow: 0 1px 24px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid #3a5157;
}

a.black {
  color: white;
}

.black .spec {
  border-color: #3a5157;
}

.black .view {
  color: #c7a68e;
}

.petFriendly {
  background: rgba(0,0,0,0.7);
  display: grid;
  grid-template: auto/auto auto;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 0 100px 100px 0;
  position: absolute;
  color: #dbdbdb;
  font-size: 12px;
  align-items: center;
  font-weight: 500;
  backdrop-filter: blur(2px);
}

.ratingCounts{
  background: rgba(18, 18, 18, 0.62);
  justify-self: end;
  padding: 10px 8px;
  position: absolute;
  border-radius: 6px;
  font-size: 12px;
  align-items: center;
  font-weight: 500;
  backdrop-filter: blur(2px);
  top: 270px;
  margin-right: 16px;
}

.wellness {
  position: absolute;
  height: 32px;
}

.llpTicker {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 4px 10px;
}

.customDiscount{
  font-size: 12px;
  font-weight: 500;
  max-width:40px;
  max-height: 40px;
  grid-row: 1 / span2;
  display: flex;
  place-self: center;
  margin-right: 4px;
}

.arrowIcon{
  margin-top: -175px;
  grid-column-end: none;
  margin-right: 16px;
}


@media (max-width: 768px) {
  .container {
    grid-template: auto/1fr;
    gap: 16px;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
    -webkit-tap-highlight-color: transparent; 
  }

  .thumbnail {
    width: calc(100% + 32px);
    height: 188px;
    margin: -16px -16px 0 -16px;
    border-radius: 8px 8px 0 0;
    grid-column: span 3;
    scroll-snap-type: x mandatory;
  }

  .thumbnailContainer{
    border-radius: 8px 8px 0 0;
    grid-column: span 3;
    display: grid;
    grid-template: auto/1fr;
    position: relative;
  }

  .thumbnailSkeleton{
    height: 188px;
    margin: -16px -16px 0 -16px;
  }

  .imgSelect {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
    cursor: default;
  }
  .strike{
    margin-right: 4px;
  }
  
  .imgSelect img {
    object-fit: cover;
  }

  .scrollCrumb {
    grid-column: 1;
    grid-row: 1;
    align-self: flex-end;
    justify-self: center;
    margin-bottom: -170px;
  }

  .customDiscount{
    font-size: 10px;
    font-weight: 500;
    grid-row: 1 / span2;
    margin-right: 4px;
    display: flex;
    place-self: center;
  }
  
  .datesContainer{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;   
    color: var(--secondary-contrast-text);
    display: flex;
    background: none;
    justify-content: center;
    align-items: center;
  }

  .sold{
    background: transparent;
    font-size: 12px;
    align-self: center;
  }

  .soldOut{
    font-size: 12px;
    line-height: 10px;
    grid-column: 1 / span 3;
    justify-content: center;
    grid-row: 6;
    gap: 2px;
    background-color: #F8E0D499;
    border: 1px solid #F4D1BF;
    display: flex;
    padding: 10px;   
  }

  .soloSoldOut{
    font-size: 12px;
    line-height: 10px;
    text-align: center;
    grid-column: span 3;
    background-color: #F8E0D499;
    border: 1px solid #F4D1BF;
    padding: 10px;
    color: #da6528;
    border-radius: 4px;
  }

  .item {
    scroll-snap-align: start;
    overflow: hidden;
    width: calc(100vw - 32px);
    background-color: black;
  }

  .title {
    font-size: 14px;
    font-weight: 700;
    max-width: 235px;
  }

  .full_title {
    font-size: 14px;
    font-weight: 700;
  }

  .subtitle {
    font-size: 10px;
    margin-top: -12px;
    font-weight: 500;
  }

  .spec {
    font-size: 10px;
  }

  .specContainer {
    grid-column: span 3;
    margin-top: -4px;
  }

  .line{
    grid-column: span 3;
  }

  .llpTicker{
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 4px;
    margin-right: 4px;
  }
  
  .dates {
    font-size: 12px;
    margin-left: 2px;
  }

  .subscript{
    font-size: 10px;
    color: var(--secondary-contrast-text-2);
    line-height: 16px;
  }

  .arrowIcon{
    margin-top: -106px;
    margin-right: -8px;
    width: 16px;
    height: 16px;
  }
  .pricingContainer {
    font-size: 12px;
    margin-top: 0;
    padding-top: 0;
    grid-column: 1/span 3;
    grid-template: auto / auto 1fr;
    grid-row: 5 ; 
    border-top: 1px solid rgba(215, 215, 215, 1);
    padding-top: 16px;
  }

  .pricingSkeleton {
    grid-row: 5 ; 
    grid-column: 1/span 3;
    border-top: 1px solid rgba(215, 215, 215, 1);
    padding-top: 16px;
  }

  .price {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    align-items: center;
  }

  .excTax {
    font-size: 8px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    grid-column: 2;
    line-height: 12px;
  }

  .view[data-sold-out='false'] span{
    display: none;
  }

  .view[data-sold-out='true'] span{
    display: none;
  }

  .view{
    grid-row: 1 / span 2;
    grid-column: 3;
    margin-right: 0;
  }

  .view img{
    width: 30px;
  }

  .amount {
    font-size: 14px;
    font-weight: 700;
    margin-right: 2px;
  }

  .scrollCrumb .rightArrowIcon{
    right: -7px;
    width: 16px;
  }

  .upcoming {
    right: 48px;
    bottom: 16px;
  }

  .unavailable {
    font-size: 10px;
    padding: 8px 14px;
    right: 16px;
    bottom: 16px;
  }

  .petFriendly {
    top: 12px;
    max-height: 28px;
    font-size: 10px;
    gap: 4px;
    padding: 8px;
  }

  .ratingCounts{
    top: 0;
    background: none;
    grid-row: 2;
    padding: 6px 4px;
    border: 1px solid #1F2C3829;
  }

  .wellness {
    top: 152px;
    left: 8px;
    width: 75px;
    height: 28px;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: repeat(3, auto) 1fr auto/280px auto 1fr;
    gap: 16px 16px;
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);

  }

  .imgSelect {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
    cursor: default;
  }

  .sold{
    display: flex;
    justify-content: center;
    padding: 7px 40px;
  }

  .soloSoldOut .sold{
    border-radius: 4px;
  }

  .scrollCrumb .rightArrowIcon{
    right: 16px;
  }

  .scrollCrumb .leftArrowIcon{
    left: 16px;
  }
  
  .strike {
    margin-right: 6px;
  }
  
  .thumbnail {
    height: 326px;
    scroll-snap-type: x mandatory;
    border-radius: 11px 11px 0 0;
    grid-row: span 1;
    grid-column: span 3;
  }

  .thumbnailContainer{
    height: 326px;
    width: calc(100% + 48px);
    margin: -24px -24px 0 -24px;
    border-radius: 11px 11px 0 0;
    grid-column: span 3;
    display: grid;
    grid-template: auto/1fr;
    position: relative;
  }

  .llpTicker{
    margin-right: 4px;
  }

  .item {
    scroll-snap-align: start;
    overflow: hidden;
    width: 580px;
    background-color: black;
  }

  .scrollCrumb {
    grid-column: 1;
    grid-row: 1;
    align-self: flex-end;
    justify-self: center;
    margin-bottom: -320px;
  }

  a {
     margin-right: 0; 
  }

  .title {
    font-size: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 4px;
  }

  .full_title{
    font-size: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 4px;
    grid-column: span 2;
  }

  .subscript{
    font-size: 14px;
    color: var(--secondary-contrast-text-2);
  }

  .subtitle {
    font-size: 16px;
    margin-top: -12px;
    font-weight: 500;
    line-height: 20px;
    grid-column: 1 / span 2;
    grid-row: 3;
  }

  .spec {
    font-size: 14px;
    line-height: 16px;
  }

  .specContainer{
    grid-row: 4;
    grid-column: 1 / span 3;
  }

  .datesContainer {
    text-align: center;
    grid-column: 3;
    grid-row: 3;
    margin-bottom: -8px;
    margin: 8px 12px 8px 12px;
  }

  .dates {
    font-size: 16px;
    display: flex;
    place-content: center;
    margin-top: 4px;
  }

  .price{
    display: flex;
    align-items: center;
  }

  .pricingContainer {
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    grid-column: span 3;
    grid-row: 5 / span 1; 
    grid-template: auto / auto 1fr;
    border-top: 1px solid rgba(215, 215, 215, 1);
    padding-top: 16px;
  }

  .pricingSkeleton{
    grid-column: span 3;
    grid-row: 5 / span 1; 
    border-top: 1px solid rgba(215, 215, 215, 1);
    padding-top: 16px;
  }

  .excTax {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin-top: 4px;
    grid-row: 2;
  }

  .amount {
    font-size: 24px;
  }

  .view {
    justify-self: flex-end;
    grid-row: 1 / span 2 ;
    grid-column: 3;
  }

  .soldOut {
    margin-bottom: 16px;
    place-self: end;
    font-size: 14px;
    border: 1px solid #F8E0D4;
    margin-right: -8px;
    grid-row: 2 / span 3;
    grid-column: 3;
    margin-left: 15px;
    max-height: 75px;
    width: 150px;
  }

  .soloSoldOut{
    font-size: 12px;
    line-height: 10px;
    grid-column: 2 / span 1;
    grid-row: 2;
    margin-left: 104px;
    margin-right: -30px;
    color: #da6528;
  }

  .upcoming {
    top: 16px;
    right: 16px;
  }

  .unavailable {
    top: 16px;
    right: 16px;
    font-size: 14px;
    padding: 12px 18px;
  }

  .petFriendly {
    top: 24px;
    height: 32px;
    max-width: 150px;
  }

  .wellness {
    top:278px;
    left: 16px;
  }

  .amenities{
    grid-column: span 2;
  }
}
