.container {
  background: white;
  padding: 8px 32px 32px;
  position: absolute;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
  min-width: 500px;
  max-width: 600px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #0000001a;
  color: #00a699;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.close {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: 50px;
}

.footer .lohonoLogo {
  max-height: 40px;
  max-width: 110px;
  margin-top: 16px;
  margin-bottom: 5px;
}

.footer p {
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
}

.footer .teamLohonoText {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
	color: var(--secondary-contrast-text-2);
	;
}

.review{
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center; 
}

.img {
  grid-column: 1 / 2; 
}

.content{
  grid-column: 2/ 3; 
  margin-left: 24px;
  align-items: center;  
}

.content h2{
  margin: 0px;
  font-size: 25px;
  font-weight: 500;
  color: #D87036;
  margin-bottom: 16px;
  line-height: 30.26px;
}

.content p{
  margin: 0px;
  font-weight: 400;
  color: var(--secondary-contrast-text-2);
  font-size: 14px;
  line-height: 20px;
}

.content .referralp {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  margin: 0px;
  color: var(--secondary-contrast-text-3);
}

.review a, .review button {
  grid-column: 1 / 3;
  justify-self: auto;
  height: 56px;
  width: 100%;
  font-size: 16px;
  margin-top: 30px;
  margin-right: 0px;
  color: white;  
}

.review button {
  color: #DEC190 !important;
}

.refer{
  grid-column: 1 / 3;
  margin: 0;
  margin-bottom: 24px;
  font-size: 25px;
  font-weight: 500;
}

.points{
  color: #D87036;
  ;
}

.generateBtn, .generateCopyBtn{
  background: #232323;
  color: #DEC190;
}

.generateIconBtn {
  display: none;
}

.referralUrlContainer {
  grid-column: 1/-1;
  margin-top: 32px;
  display: grid;
  grid-template: 1fr/auto auto;
  column-gap: 8px;
}

.referralUrlContainer .urlDiv {
  border: 1px solid #bababa;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
  text-wrap: nowrap;
  padding: 18px 16px;
}

.referralUrlContainer .urlDiv span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.referralUrlContainer button {
  grid-column: 2;
  margin-top: 0px;
}

.googlereview{
  grid-column: 1 / 3;
  display: flex;
  max-width: 265px;
  padding: 8px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-top: 32px;
  color: var(--secondary-contrast-text-3) !important;
}

.googlereview p{
  margin: 0;
  font-size: 14px;
  margin-left: 12px;
}

@media (max-width: 768px) {

  .container {
    padding: 8px 16px 16px;
    min-width: 300px;
    max-width: 350px;
    transform: translateY(-50%);
    top: 50%;
  }

  .title {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
  }

  .close {
    margin-left: 15px;
  }
 
  .review img {
    grid-column: 2; 
    max-width: 140px;
    max-height: 120px;
  }

  .content{
    grid-column: 1/4; 
    margin-left: 0 ;
    text-align: center;  
    margin-top: 12px;
  }

  .content h2{
    font-size: 18px; 
  }

  .content p{
    font-size: 14px;
    margin-top: 8px 
  }

  .content .referralp {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    margin: 0px;
    color: var(--secondary-contrast-text-3);
  }

  .review a, .review button {
    grid-column: 1 / -1;
    height: 48px;
    font-size: 16px;
    margin-top: 24px;
    color: white;
  }

  .referralUrlContainer {
    align-items: center;
  }

  .generateCopyBtn {
    display: none;
  }

  .generateIconBtn{
    background: #232323;
    color: #DEC190;
    display: unset;
    grid-column: 2 !important;
    margin-top: 0px !important;
  }

  .footer{
    margin-top: 8px;
    display: flex;
    flex-direction: column;
  }
  
  .footer p {
    text-align: center;
  }

  .footer .lohonoLogo{
    align-self: center;
  }

  .refer{
    grid-column: 1 / 4;
    grid-row: 2;
    font-size: 18px;
    margin: 0;
    margin-top: 24px;
  }

  .googlereview{
    grid-column: 1 / 4;
    display: flex;
    max-width: 100%;
    justify-content: center;
    margin-top: 24px;
  }

} 