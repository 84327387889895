.container {
  display: grid;
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
  min-height: 40px;
}

.container[data-discount-type="coupon"] {
  background: #edf7ed;
  color: #1e4620;
}

.container[data-discount-type="silver"] {
  background: linear-gradient(254.44deg, #d5d5db 25.79%, #888aa0 72.65%);
  color: white;
}

.container[data-discount-type="gold"] {
  background: linear-gradient(252.28deg, #fccd87 2.32%, #dca24c 71.95%);
  color: white;
}

.container[data-discount-type="platinum"] {
  background: linear-gradient(255.48deg, #292828 5.57%, #211E1E 73.01%);
  color: white;
}

.offerIcon {
  width: 24px;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/auto 1fr;
    gap: 8px;
    font-size: 12px;
    padding: 8px;
  }
}

@media (min-width: 768px) {
  .container {
    font-size: 14px;
    padding: 8px 8px 8px 12px;
  }

  .container[data-single-child='false'] {
    grid-template: auto/auto 1fr auto;
    gap: 12px;
  }

  .container[data-single-child='true'] {
    grid-template: auto/auto 1fr;
    gap: 16px;
  }
}
