.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
}

.title {
  color: var(--primary-main);
  /* font-family: "Tantinotes", sans-serif; */
  font-family: var(--font-family);
}

.previews {
  display: grid;
  gap: 16px;
}

.first {
  aspect-ratio: 2/1;
  grid-column: span 2;
  width: 100%;
  cursor: pointer;
  border-radius: 6px;
  object-fit: cover;
}

.first :global .image {
  object-fit: cover;
}

.rest {
  aspect-ratio: 1/1;
  width: 100%;
  cursor: pointer;
  border-radius: 6px;
  object-fit: cover;
}

.rest :global .image {
  object-fit: cover;
}

.viewAll {
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary-main);
  justify-self: flex-start;
  text-transform: uppercase;
  letter-spacing: 1.12px;
  cursor: pointer;
}

.viewAllIcon {
  margin-left: 4px;
}

@media (max-width: 768px) {
  .title {
    font-size: 32px;
    letter-spacing: 0.67px;
  }

  .previews {
    grid-template: auto/1fr 1fr;
  }

  .viewAll {
    font-size: 12px;
    align-self: flex-end;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 74px;
    letter-spacing: 1.5px;
  }

  .previews {
    grid-template: auto/repeat(3, 1fr);
  }

  .viewAll {
    font-size: 14px;
  }
}
