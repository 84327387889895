.container {
  display: inline-grid;
  grid-template: auto/1fr;
  gap: 4px;
  align-content: flex-start;
  font-weight: 400;
  position: relative;
}

.container[data-disabled='true'] .input {
  background: rgba(31, 44, 56, 0.04);
  cursor: not-allowed;
  border: none;
  color: #bcbcbc;
}

.container[data-focus='true'][data-highlight='true'] {
  /* background: #F5F5F7 !important; */
  border: 1px solid var(--border-color) !important;
}

.label {
  text-transform: capitalize;
  grid-row: 1;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(120, 120, 120, 1);
  text-transform: capitalize;
  opacity: 80%;
  cursor: pointer;
}

.label~.expandIcon {
  top: 46px;
}

.input {
  background: white;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 12px;
  color: var(--secondary-contrast-text);
  font-size: 16px;
  font-weight: 600;
  outline: none;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--font-family);
}

.homeInput {
  max-height: unset !important;
}

.input::placeholder {
  color: var(--secondary-contrast-text-2);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input[data-error='true']:hover,
.input[data-error='true']:focus {
  border-color: #CC385A;
}

.input[data-error='false']:hover,
.input[data-error='false']:focus {
  border-color: #474e66;
}

.error {
  color: #CC385A;
  font-size: 12px;
}

.expandIcon {
  position: absolute;
  top: 22px;
  transform: translateY(-50%);
  right: 12px;
  fill: #cccccc;
  pointer-events: none;
}

.expandIcon:hover {
  fill: #474e66;
}

/* .showOptions {
  display: block;
}

.hideOptions {
  display: none;
} */

/* .recentSearches {
  padding: 4px 0px 20px 0px;
} */

.searchTags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.searchTag {
  display: flex;
  justify-content: center;
  gap: 8px;
  background: rgba(31, 44, 56, 0.05);
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 14px;
  line-height: 22px;
  color: var(--secondary-contrast-text);
  font-weight: 500;
  cursor: pointer;
}

.searchTag:hover {
  background: #e0e0e0;
}

.searchHeaders {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: var(--secondary-contrast-text-2);
}

.optionsContainer {
  display: grid;
  position: absolute;
  left: 0;
  width: 100%;
  top: calc(100% + 1px);
  max-height: 300px;
  background: white;
  color: var(--secondary-contrast-text);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.57px;
  user-select: none;
  overflow-y: auto;
  box-shadow: 0px 4px 4px 1px #00000026;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  z-index: 1;
  padding: 11px 12px;
  /* Increased padding */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  /* Divider line */
}

.optionText {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.searchIcon {
  height: 32px;
  width: 32px;
}

.optionTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.optionSubtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: var(--secondary-contrast-text-2);
}

.optionsContainer::-webkit-scrollbar {
  width: 4px;
  /* Scrollbar width */
}

.optionsContainer::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  /* Track background color */
  border-radius: 10px;
}

.optionsContainer::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  /* Scrollbar thumb color */
  border-radius: 10px;
}

.optionsContainer::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
  /* Thumb color on hover */
}


.option::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.option::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

/* .option[data-hover='false'] .option[data-focused='true'] {
  background: rgba(71, 78, 102, 0.1);
} */

.option[data-hover='true'] .option[data-focused='false'] {
  display: none;
}

.option[data-focused="true"] {
  background: linear-gradient(90deg, rgba(170, 78, 49, 0.1) 2.97%, rgba(170, 78, 49, 0.02) 97.96%);
  margin: 0px -12px;
  padding: 12px;
  border: none;
}

.option {
  padding: 12px 0;
  gap: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
}

.option:last-child {
  border: none;
}

.option .iconDesktop {
  margin-right: 8px;
  margin-left: -12px;
  width: 18px;
  height: 18px;
}

.option .iconMobile {
  margin-right: 8px;
  margin-left: -12px;
  width: 24px;
  height: 24px;
}


.option:hover {
  background: rgba(71, 78, 102, 0.1);
  margin: 0px -12px;
  padding: 12px;
}

.noOption {
  padding: 8px 12px 8px 24px;
  opacity: 0.75;
}

@media (max-width: 768px) {
  .input {
    max-height: 36px;
  }

  .label~.expandIcon {
    top: 40px;
  }

  .label {
    font-size: 12px;
  }

  .input {
    font-size: 14px;
  }

  .expandIcon {
    top: 18px;
  }


  /* home destination select css */
  .homeInput {
    min-height: 58px;
  }

  .label~.homeExpandicon {
    top: 52px !important;
  }

  .homeLableIcon {
    font-size: 14px !important;
  }

  .homeInput {
    font-size: 16px !important;
  }

  .optionsContainer {
    gap: 16px;
  }

  .recentSearches {
    display: grid;
    gap: 12px;
  }

  .homeExpandicon {
    top: 18px;
  }

  .input::placeholder{
    font-size: 14px;
  }
}

@media (min-width: 768px) {

  .optionsContainer {
    gap: 20px;
    padding: 16px;
  }

  .recentSearches {
    display: grid;
    gap: 12px;
  }
}