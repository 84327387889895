.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 32px;
}

@media (min-width: 768px) {
  .container {
    gap: 72px;
  }
}